// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom';
import { isEmail } from 'validator';
import checkPassword from "../components/passwordCheck";
import { reset_code, resetPassword } from '../api/Users';
import '../styles/Login.scss';
import logo from "../assets/payment-icon.png";
import prevBtn from "../assets/btns-left.png";
import pwdMatch from "../assets/reset-match.png";
import pwdNotMatch from "../assets/reset-notMatch.png";



const Signup: React.FC = () => {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [countdown, setCountdown] = useState(0);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [verifyError, setVerifyError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [countdown]);

    const validateEmail = (email: string): boolean => {
        if (!isEmail(email)) {
            setEmailError('Please enter a valid email address');
            return false;
        }
        setEmailError('');
        return true;
    };

    const validatePassword = (password: string) => {
        const msg = checkPassword(password);
        if (msg !== 'OK') {
            setPasswordError(msg);
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };

    const validateConfirmPassword = (password: string, confirmPassword: string) => {
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
        } else {
            setConfirmPasswordError('');
        }
    };


    const handleToken = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (countdown === 0 && validateEmail(email) === true) {
            try {
                const response = await reset_code(email);
                if (response.code === 404) {
                    setEmailError("Email Not Found");
                } else if (response.code === 200) {
                    setEmailError("");
                    setCountdown(60);
                }
                else {
                    setVerifyError("Internal Server Error");
                }
            } catch (error) {
                console.error('Error during registration:', error);
            }
        }
    };



    const handleSignup = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateEmail(email) || !validatePassword(password) || confirmPasswordError !== "") {
            return;
        }
        const userData = {
            email: email,
            password: password,
            token: token
        };
        try {
            const response = await resetPassword(userData);
            console.log(response);
            if (response.code === 200) {
                navigate('/login');
                console.log('Registration successful:', response.data);
            } else if (response.code === 400) {
                setVerifyError("Invalid digit code");
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    }

    return (
        <div className='login-container'>
            <div>
                {/* <h1 className="text-4xl font-bold text-center">Back to log in</h1> */}
                <a className="underline-none hover:text-blue-500 flex" href="/login" style={{ transform: "translateY(-90px)" }}>
                    <img src={prevBtn} alt="back to log in" style={{ width: 20, height: 20, marginRight: 10 }} />
                    <span style={{ color: "#AAAAAA" }}>Back to log in</span>
                </a>
                <img src={logo} alt="logo" className='login-image' style={{ width: 400 }} />
            </div>
            <div className="flex w-1/2 items-center justify-center min-h-screen bg-white">
                <div className="login-form-container">
                    <h2 className="text-2xl font-bold mb-4">Password Reset</h2>
                    <form onSubmit={handleSignup} className='relative'>
                        <div className="mb-4">
                            <label className="block mb-1">Email</label>
                            <input
                                type="text"
                                className={`login-input ${emailError ? 'border-red-500' : ''}`}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={() => validateEmail(email)}
                                required
                            />
                            {emailError && <p className="text-red-500 text-sm my-1">{emailError}</p>}
                        </div >
                        <div className="mb-4">
                            <label className="block mb-1">Digit Code</label>
                            <div>
                                <input
                                    type="text"
                                    className="login-input-verify"
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                    required
                                />
                                <button
                                    className='w-1/3 h-10 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600'
                                    style={countdown > 0 ? { background: 'gray' } : {}}
                                    onClick={handleToken}
                                    disabled={countdown > 0}
                                >
                                    {countdown > 0 ? `resend in ${countdown}s` : 'Get Digit Code'}
                                </button>
                                {verifyError && <p className="text-red-500 text-sm my-1">{verifyError}</p>}
                            </div>
                        </div>
                        <div className="mb-6">
                            <label className="block mb-1">Password</label>
                            <input
                                type="text"
                                className={`login-input ${passwordError ? 'border-red-500' : ''}`}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={() => validatePassword(password)}
                                data-tooltip-content="Enter a strong password with at least 8 characters, including uppercase and lowercase letters, numbers, and symbols"
                                data-tooltip-id="passwordTooltip"
                                required
                            />
                            <ReactTooltip place="bottom" id='passwordTooltip' />
                            {passwordError && <p className="text-red-500 text-sm my-1">{passwordError}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-1 flex justify-between items-center">Confirm Password
                                {confirmPasswordError
                                    ? <div style={{ display: confirmPassword ? "flex" : "none", gap: 10, alignItems: "center" }}><img src={pwdNotMatch} alt="not match" style={{ width: 18 }} />Not match</div>
                                    : <div style={{ display: confirmPassword ? "flex" : "none", gap: 10, alignItems: "center" }}><img src={pwdMatch} alt="match" style={{ width: 18 }} />Match</div>
                                }
                            </label>
                            <input
                                type="password"
                                className={`login-input ${confirmPasswordError ? 'border-red-500' : ''}`}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onBlur={() => validateConfirmPassword(password, confirmPassword)}
                                data-tooltip-content="Re-enter your password to confirm"
                                data-tooltip-id="confirmPasswordTooltip"
                                required
                            />
                            <ReactTooltip place="bottom" id='confirmPasswordTooltip' />
                            {/* {confirmPasswordError && <p className="text-red-500 text-sm my-1">{confirmPasswordError}</p>} */}
                        </div>
                        <button
                            type="submit"
                            className="w-full h-10 bg-blue-500 text-white font-bold p-2 rounded-lg hover:bg-blue-600 mb-4"
                            disabled={token === "" || passwordError !== "" || emailError !== "" || confirmPasswordError !== ""}
                            style={{ background: token === "" || passwordError !== "" || emailError !== "" || confirmPasswordError !== "" ? "#AAAAAA" : "#287EFF" }}
                        >
                            Confirm
                        </button>
                        {confirmPasswordError && <p className="text-red-500 text-sm my-1">{confirmPasswordError}</p>}

                        {/* <p className="absolute right-0 items-end text-sm text-gray-500">Already have an account? Go to <a className="underline hover:text-blue-500" href="/login">Log in</a></p> */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
