import { Container, Stack, Image } from "react-bootstrap"

//样式
const titleStyle = {
    fontSize: 48,
    lineHeight: "72px",
    fontWeight: 700,
    color: "rgba(17, 17, 17, 1)",

}
const pStyle = {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.8)",
    textAlign: "center",

    width: 740

}
//名称样式
const nameTextStyle = {
    fontSize: 20,
    lineHeight: "30px",
    fontWeight: 700,
    color: "rgba(17, 17, 17, 1)",
    textTransform: "capitalize"

}
//年龄信息样式
const infoTextStyle = {
    fontSize: 14,
    lineHeight: "18px",
    maxWidth: 140,
    fontWeight: 400,
    color: "rgba(170, 170, 170, 1)",
    textTransform: "capitalize"

}
//评论区相关组件
const commentUnitStyle = {
    backgroundColor: "rgba(244, 247, 248, 1)",
    border: "1px solid rgba(40, 126, 255, 1)",
    borderRadius: 32,

    gap: "30px",
    minHeight: 250,
    width: 340,
    marginBottom: 30,
    breakInside: "avoid",
    paddingBottom: 30


}
const commentTextStyle = {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.6)",
    whiteSpace: "normal",
    width: 260,
    marginBottom: 28



}
//输入内容
const url = require("./img/circle.png");
const textGroup = {
    t1: <>Personal Tutor's commitment to personalized guidance is unparalleled. Their platform seamlessly connects students and job seekers with expert tutors, ensuring tailored support that leads to tangible results.</>,
    t2: <>I highly recommend ApplyU's Program Guide. It simplifies the search for master's programs by matching students with ideal options based on their profiles and goals. This tool has enhanced our advising process, making it an essential resource for guiding students towards their optimal educational pathways.</>,
    t4: <>I've been using ApplyU for my academic journey and it's been nothing short of a lifesaver. This platform provides resourceful and up-to-date overviews and requirements for various programs, all in one place. Before I discovered ApplyU, I used to spend hours searching for specific program details on the internet. Now, I can get all the information I need quickly and efficiently, saving me a ton of time and effort. </>,
    t5: <>As a Master's student at Imperial College, I found the ApplyU recommendation system instrumental in achieving my academic goals. Initially, the system pinpointed my work experience as a potential weakness for my chosen program. With this insight, I applied for a summer internship to deepen my practical skills. </>,
    t6: <>I've found ApplyU's program guide tool to be an invaluable asset in streamlining the advisory process. The detailed matching scores and insights provided by ApplyU save considerable time, giving me a solid foundation to scaffold my decision-making process. This tool has dramatically enhanced the way I guide students towards their best-fit programs, optimizing our sessions and improving outcomes.</>,
    t7: <>ApplyU streamlined my search for the perfect AI master’s program, aligning perfectly with my career goals. Highly recommended!</>,


}


//方框内头像部分
function CommentAvatar({ name, age, iconurl = url }) {
    return (
        <div style={{ marginBottom: 30, marginTop: 38 }}>
            <Stack direction="horizontal">
                <Image src={iconurl} width={69} height={69} style={{ margin: 10 }}></Image>
                <div style={{ marginLeft: 22, marginTop: 7 }}>
                    <Stack >
                        <div style={nameTextStyle}>{name}</div>
                        <div style={infoTextStyle}>{age}</div>
                    </Stack>
                </div>
            </Stack>

        </div>


    )
}
//评论方框
function CommentUnit({ name, age, commentText, iconurl }) {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={commentUnitStyle}>
            <div >
                <CommentAvatar name={name} age={age} iconurl={iconurl}></CommentAvatar>

                <div className="d-flex fontBlackBold text-align-left" style={commentTextStyle}>{commentText}</div>

            </div>
        </div>
    )

}
//评论区
function CommentGroups() {
    return (
        <div className=" " style={{ minWidth: 1000, maxWidth: 1080,  breakInside: "avoid" }}>
            <div className="mobile-container mobile-comment-columns">
                <CommentUnit iconurl={require("./icon/avatar1.png")} name="David" age={"University Administrator"} commentText={textGroup.t1}></CommentUnit>
                <CommentUnit iconurl={require("./icon/avatar2.png")} name="Jessica Wang" age={"Administrator at University of Nottingham"} commentText={textGroup.t2}></CommentUnit>
                <CommentUnit iconurl={require("./icon/avatar3.png")} name="XinTong Li" age={"Master Student at EPFL"} commentText={textGroup.t4}></CommentUnit>

                <div className="mobile-item-l"><CommentUnit iconurl={require("./icon/avatar4.png")} name="Lily Hu" age={"Master Student at University of Imperial College"} commentText={textGroup.t5}></CommentUnit>
                </div>
                <div className="mobile-item-l"><CommentUnit iconurl={require("./icon/avatar5.png")} name="Chris Wilson" age={"Application Essay Coach"} commentText={textGroup.t6}></CommentUnit>
                </div>
                <div className="mobile-item-l"><CommentUnit iconurl={require("./icon/avatar6.png")} name="George Li" age={"Intern AI engineer at Alibaba company"} commentText={textGroup.t7}></CommentUnit>
                </div>
                <div className="mobile-item-l"></div>
                {/* <CommentUnit name="Ning"  commentText={textGroup.t3}></CommentUnit> */}
                {/* <CommentUnit name="Anna Taylor"  commentText={textGroup.t7}></CommentUnit> */}
            </div>

        </div>
    )
}

export default function CommentArea() {



    const MAIN_WIDTH = window.MAIN_WIDTH
    return (

        <>
            <div className=" d-flex flex-column align-items-center all-center" style={{ zIndex: 100, backgroundColor: "transparent", maxWidth: MAIN_WIDTH, minHeight: 1200, rowGap: "50px", marginTop: 150, marginBottom: 100 }}>
                {//标题区
                }
                <div className="d-flex flex-column " style={{ rowGap: "50px",textAlign:"center",padding:"0 6vw" }}>
                    <div style={titleStyle}> This is what our user want to say</div>
                    {/* <div className="fontBlackBold" style={{ ...pStyle }}> Lorem ipsum dolor sit amet consectetur. Malesuada quam ultricies dolor eget. Quis tristique integer molestie gravida cras aliquam pellentesque et. Consectetur molestie odio gravida nullam integer vitae.</div> */}
                </div>
                {
                    //评论区
                }


                <CommentGroups></CommentGroups>
                {
                    //装饰
                }




            </div>
            <div style={{ position: "relative", zIndex: 2, width: "100%" }}>
                <div style={{ position: "absolute", top: -1400, left: -50 }}>
                    <Image src={require("./img/ApplyU_ceshi_Deco_large.png")} style={{ width: 900, height: 530 }} ></Image>
                </div>

                <div style={{ position: "absolute", width: "100%", top: -500, left: 800 }}>
                    <Image src={require("./img/ApplyU_ceshi_Deco_large.png")} style={{ width: 900, height: 530 }}></Image>
                </div>
            </div>

        </>


    )
}