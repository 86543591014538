// src/store/uploadCVSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UploadCVState {
    file: File | null;
    parsedData: string[];
}

const initialState: UploadCVState = {
    file: null,
    parsedData: [],
};

const uploadCVSlice = createSlice({
    name: 'uploadCV',
    initialState,
    reducers: {
        setCVFile(state, action: PayloadAction<File>) {
            state.file = action.payload;
        },
        setParsedData(state, action: PayloadAction<string[]>) {
            state.parsedData = action.payload;
        },
    },
});

export const { setCVFile, setParsedData } = uploadCVSlice.actions;
export default uploadCVSlice.reducer;
