import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, RouterProvider } from 'react-router-dom';

import './App.scss'

import { _router } from './_router';






const App: React.FC = () => {

    return (
        // <>
        //     <CVForm></CVForm>
        //     <PrivacyPolicy></PrivacyPolicy>
        //     <TermsOfService></TermsOfService>
        // </>
        //<Tutors />
        //<HomePage></HomePage>
        <React.StrictMode>
            <RouterProvider router={_router}></RouterProvider>
        </React.StrictMode>
        // <Router>
        //     <FormTest></FormTest>

        //     <Routes>
        //         <Route path="/login" element={<Login />} />
        //         <Route path="/signup" element={<Signup />} />
        //         <Route path="/reset" element={<Reset />} />
        //         <Route path="/tutor" element={<Tutors />} />
        //         <Route path="/viewprograms" element={<ViewPrograms />} />
        //         <Route path="/pricing" element={<PricingPage />} />
        //         <Route path="/payment" element={<PaymentPage />} />
        //         <Route path="/" element={<HomePage />} />
        //         <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        //         <Route path="/termsofservice" element={<TermsOfService />} />

        //         <Route
        //             path="*"
        //             element={
        //                 <DashboardLayout>
        //                     <Routes>
        //                         <Route path="/write" element={<Write />} />
        //                         <Route path="/program" element={<Program />} />
        //                     </Routes>
        //                 </DashboardLayout>
        //             }
        //         />
        //     </Routes>
        // </Router>
    );
};

export default App;