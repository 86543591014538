// src/components/Results.tsx
import React, { useEffect, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import lock_icon from '../assets/lock_icon.png';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from 'chart.js';
import { sub_fields, AnalysisDetail, ProjectAnalysis } from '../store/analysisSlice';
import '../styles/Results.scss';
import btnFold from '../assets/btns-fold.png';
import btnUnfold from '../assets/btns-unfold.png';
import { Link, Navigate } from 'react-router-dom';
import { UnlockPopup } from '../components/unlockPopup';
import { geteuid } from 'process';



ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);


interface ResultsProps {
    data: ProjectAnalysis[];
    isLocked: boolean;
    setIsLocked: (isLocked: boolean) => void;
    selectedReportId: string | null;
    membership: string;
}


const detailKeys: (keyof ProjectAnalysis)[] = ['academic', 'discipline', 'skills', 'project', 'work', 'career'];
const detailTitles: { [key in keyof ProjectAnalysis]?: string } = {
    academic: 'Academic Achievements',
    discipline: 'Discipline Relevance',
    skills: 'Skills Acquired',
    project: 'Project Experience',
    work: 'Work Experience',
    career: 'Career Preferences',
};

const isAnalysisDetail = (detail: any): detail is AnalysisDetail => {
    return detail && typeof detail === 'object' && 'score' in detail && 'sub_fields' in detail && issub_fields(detail.sub_fields);
};

const issub_fields = (sub_fields: any): sub_fields is sub_fields => {
    return typeof sub_fields === 'object' && Object.values(sub_fields).every(value => Array.isArray(value));
};

const Results: React.FC<ResultsProps> = ({ data, isLocked, setIsLocked, selectedReportId, membership }) => {
    const [expandedProject, setExpandedProject] = useState<number | null>(null);
    const [selectedDetail, setSelectedDetail] = useState<keyof ProjectAnalysis | null>(null);
    //const [displayData, setDisplayData] = useState<ProjectAnalysis[]>([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // useEffect(() => {
    //     console.log(data);
    //     console.log('program.tsx', data[0]);
    // }, []);




    const handleProjectClick = (projectId: number) => {
        if (expandedProject === projectId) {
            setExpandedProject(null);
            setSelectedDetail(null);
        } else {
            setExpandedProject(projectId);
            setSelectedDetail(null);
        }
    };

    const handleDetailClick = (key: keyof ProjectAnalysis | null) => {
        setSelectedDetail(key);
    };

    


    const radarData = (details: ProjectAnalysis) => ({
        labels: detailKeys.map(key => key),
        datasets: [
            {
                backgroundColor: 'rgba(40,126,255,0.3)',
                borderColor: 'rgba(40,126,255,1)',
                pointBackgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointHoverBackgroundColor: 'transparent',
                pointHoverBorderColor: 'transparent',
                data: detailKeys.map(key => {
                    const detail = details[key];
                    return isAnalysisDetail(detail) ? detail.score : 0;
                }),
            },
        ],
    });




    const radarOptions = (selectedDetail: keyof ProjectAnalysis | null) => ({
        scales: {
            r: {
                beginAtZero: true,
                pointLabels: {
                    font: {
                        size: 16,
                        weight: 500,
                    },
                    color: (context: any) => {
                        const labelIndex = detailKeys.findIndex(key => key?.toLowerCase() === context.label.toLowerCase());
                        return selectedDetail != null && labelIndex === detailKeys.indexOf(selectedDetail) ? '#287EFF' : '#AAAAAA';
                    },
                },
                ticks: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    });


    return (
        <div className='results' style={{ overflow: 'scroll' }}>
            <ul className={`results_projects`}>
                {data.map((project: ProjectAnalysis, index: number) => (
                    <li key={project.id} className={`results_project`}>
                        <button
                            onClick={() => handleProjectClick(project.id)}
                            className="results_project_button"
                            // disabled={index >= 3 && isLocked}
                        >
                            <div className="results_project_title">
                                <div className='results_project_title_id'>{index + 1}</div>
                                <img src={project.imgUrl} alt={project.school} />
                                <div>
                                    <p>{project.school}</p>
                                    <h3>{project.program}</h3>
                                </div>
                                <div className="results_project_fold">
                                    <p>Matching Score <span>{project.matching.score.toFixed(2)}</span></p>
                                    <img src={expandedProject === project.id ? btnUnfold : btnFold} alt="fold" />
                                </div>
                            </div>
                        </button>
                        {expandedProject === project.id && (
                            <div className="results_project_infos">
                                <div className="results_project_categories">
                                    <button
                                        onClick={() => handleDetailClick(null)}
                                        style={{ borderBottom: selectedDetail === null ? '2px solid #287EFF' : '2px solid transparent', color: selectedDetail === null ? '#111111' : '#AAAAAA' }}
                                    >
                                        Matching
                                    </button>
                                    {detailKeys.map((detail, index) => (
                                        <button
                                            key={index}
                                            onClick={() => handleDetailClick(detail)}
                                            style={{
                                                borderBottom: selectedDetail === detail ? '2px solid #287EFF' : '2px solid transparent',
                                                color: selectedDetail === detail ? '#111111' : '#AAAAAA',
                                            }}
                                        >
                                            {detail.charAt(0).toUpperCase() + detail.slice(1)}
                                        </button>
                                    ))}
                                </div>
                                <div className="results_project_info">
                                    {selectedDetail === null ? (
                                        <div className="results_project_info_left">
                                            <h3>Overview</h3>
                                            {(() => {
                                                const selectedDetailData = project.matching;
                                                return isAnalysisDetail(selectedDetailData) && (
                                                    Object.values(selectedDetailData.sub_fields || {}).map((subField, subIndex) => (
                                                        Array.isArray(subField) && (
                                                            <div key={subIndex}>
                                                                {subField.map((content, contentIndex) => (
                                                                    <p key={contentIndex}>{content}</p>
                                                                ))}
                                                            </div>
                                                        )
                                                    ))
                                                );
                                            })()}
                                        </div>
                                    ) : (
                                        <div className="results_project_info_left">
                                            <h3>{detailTitles[selectedDetail]}</h3>
                                            {(() => {
                                                const selectedDetailData = project[selectedDetail];
                                                return isAnalysisDetail(selectedDetailData) && (
                                                    Object.values(selectedDetailData.sub_fields || {}).map((subField, subIndex) => (
                                                        Array.isArray(subField) && (
                                                            <div key={subIndex}>
                                                                {subField.map((content, contentIndex) => (
                                                                    <p key={contentIndex}>{content}</p>
                                                                ))}
                                                            </div>
                                                        )
                                                    ))
                                                );
                                            })()}
                                        </div>
                                    )}

                                    <div className="result_project_info_right" style={{ height: '320px', width: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'translateY(-15px)' }}>
                                        <Radar
                                            data={radarData(project)}
                                            options={radarOptions(selectedDetail)}
                                            className="result_project_info_right_radar"
                                            style={{ height: '350px', width: '700px' }}
                                        />
                                    </div>
                                </div>
                            </div>


                            // <div className="mt-2 flex">
                            //     <div className="w-1/2 pl-4">
                            //         <button
                            //             onClick={() => handleDetailClick(null)}
                            //             className={`px-4 py-2 mr-2 rounded ${selectedDetail === null ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                            //         >
                            //             Matching
                            //         </button>
                            //         <div className="mt-4">
                            //             {detailKeys.map((detail, index) => (
                            //                 <button
                            //                     key={index}
                            //                     onClick={() => handleDetailClick(index)}
                            //                     className={`px-4 py-2 mr-2 rounded ${selectedDetail === index ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                            //                 >
                            //                     {detail as React.ReactNode}
                            //                 </button>
                            //             ))}
                            //         </div>
                            //         {selectedDetail !== null && (
                            //             <div className="mt-4">
                            //                 <h3 className="text-xl">Detail Analysis for {detailKeys[selectedDetail as number]}</h3>
                            //                 <p>{project.details[detailKeys[selectedDetail as number] as keyof typeof project.details].answer}</p>
                            //             </div>
                            //         )}
                            //     </div>
                            //     <div className="w-1/2">
                            //         <Radar
                            //             data={radarData(project.details)}
                            //             options={radarOptions(selectedDetail)}
                            //         />
                            //     </div>

                            // </div>
                        )}
                    </li>
                ))}
            </ul>
            {/* {isLocked && membership.toLowerCase() === "free" && (
                <div className='results_button_container'>
                    <Link to='/payment' target="_blank"><button className="results_unlock_btn">
                        <img src={lock_icon} alt="lock icon" className='w-21px h-27px' />
                        <span className='text-[24px] font-semibold'>Unlock all 10 programs with premium package</span>
                    </button></Link>
                </div>)}
            {isLocked && membership.toLowerCase() === "premium" && (
                <div className='results_button_container'>
                    <button className="results_unlock_btn" onClick={() => setIsPopupOpen(true)}>
                        <img src={lock_icon} alt="lock icon" className='w-21px h-27px' />
                        <span className='text-[24px] font-semibold'>Unlock all 10 programs with premium package</span>
                    </button>
                </div>)} */}

            {isPopupOpen && <UnlockPopup setIsPopupOpen={setIsPopupOpen} selectedReportId={selectedReportId}/>}
        </div>
    );
};

export default Results;
