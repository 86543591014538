
export type SubOption = {
    [key: string]: string;
};
export type jobOption = {
    key: string;
    value: SubOption;
};
export type Jobs = {
    [key: string]: jobOption;
};

const jobs = {
    "job_main_AgricultureNaturalResources": {
        "key": "Agriculture and Natural Resources",
        "value": {
            "job_sub_AN_AgriculturalWorkersFarmers": "Agricultural Workers and Farmers",
            "job_sub_AN_EnvironmentalWildlifeSpecialists": "Environmental and Wildlife Specialists",
            "job_sub_AN_ForestersFishermen": "Foresters and Fishermen"
        }
    },
    "job_main_ArtsEntertainmentRecreation": {
        "key": "Arts, Entertainment, and Recreation",
        "value": {
            "job_sub_AER_ArtistsPerformersEntertainers": "Artists, Performers, and Entertainers",
            "job_sub_AER_MuseumCulturalWorkers": "Museum and Cultural Workers",
            "job_sub_AER_SportsProfessionals": "Sports Professionals"
        }
    },
    "job_main_ConstructionExtraction": {
        "key": "Construction and Extraction",
        "value": {
            "job_sub_CE_ConstructionLaborersTradespeople": "Construction Laborers and Tradespeople",
            "job_sub_CE_ExtractionWorkers": "Extraction Workers"
        }
    },
    "job_main_CraftRelatedTrades": {
        "key": "Craft and Related Trades",
        "value": {
            "job_sub_CRT_ConstructionWorkersBuilders": "Construction Workers and Builders",
            "job_sub_CRT_HandicraftPrintingWorkers": "Handicraft and Printing Workers",
            "job_sub_CRT_MechanicsRepairTechnicians": "Mechanics and Repair Technicians"
        }
    },
    "job_main_EducationTraining": {
        "key": "Education and Training",
        "value": {
            "job_sub_ET_SchoolTeachers": "School Teachers",
            "job_sub_ET_UniversityHigherEducationInstructors": "University and Higher Education Instructors",
            "job_sub_ET_VocationalTrainingSpecialists": "Vocational and Training Specialists"
        }
    },
    "job_main_ElementaryOccupations": {
        "key": "Elementary Occupations",
        "value": {
            "job_sub_EO_CleaningHousekeepingStaff": "Cleaning and Housekeeping Staff in Offices, Hotels, etc.",
            "job_sub_EO_HelpersLaborers": "Helpers and Laborers in various industries",
            "job_sub_EO_StreetRelatedServiceWorkers": "Street and Related Service Workers"
        }
    },
    "job_main_FinanceInsurance": {
        "key": "Finance and Insurance",
        "value": {
            "job_sub_FI_BankingServicesProfessionals": "Banking Services Professionals",
            "job_sub_FI_InsuranceAgentsBrokers": "Insurance Agents and Brokers",
            "job_sub_FI_InvestmentRiskAnalysts": "Investment and Risk Analysts"
        }
    },
    "job_main_HealthSocialCare": {
        "key": "Health and Social Care",
        "value": {
            "job_sub_HSC_CaregiversPersonalCareProviders": "Caregivers and Personal Care Providers",
            "job_sub_HSC_HealthcareAssistantsHomeHealthAides": "Healthcare Assistants and Home Health Aides",
            "job_sub_HSC_SocialWorkersCounselors": "Social Workers and Counselors"
        }
    },
    "job_main_HospitalityTourism": {
        "key": "Hospitality and Tourism",
        "value": {
            "job_sub_HT_EventPlannersStaff": "Event Planners and Related Staff",
            "job_sub_HT_HotelAccommodationStaff": "Hotel and Accommodation Staff",
            "job_sub_HT_TourGuidesTravelAgents": "Tour Guides and Travel Agents"
        }
    },
    "job_main_InformationCommunication": {
        "key": "Information and Communication",
        "value": {
            "job_sub_IC_LibraryInformationAssociates": "Library and Information Associates",
            "job_sub_IC_MediaProfessionals": "Media Professionals",
            "job_sub_IC_TelecommunicationsSpecialists": "Telecommunications Specialists"
        }
    },
    "job_main_ManagementAdministration": {
        "key": "Management and Administration",
        "value": {
            "job_sub_MA_AdministrativeOfficeManagers": "Administrative and Office Managers",
            "job_sub_MA_ExecutivesSeniorOfficials": "Executives and Senior Officials",
            "job_sub_MA_ProjectProgramManagers": "Project and Program Managers"
        }
    },
    "job_main_PlantMachineOperatorsAssemblers": {
        "key": "Plant and Machine Operators and Assemblers",
        "value": {
            "job_sub_PMO_AssemblyLineWorkers": "Assembly Line Workers",
            "job_sub_PMO_ManufacturingMachineOperators": "Manufacturing Machine Operators",
            "job_sub_PMO_PrintingMachineOperators": "Printing Machine Operators"
        }
    },
    "job_main_ProfessionalTechnical": {
        "key": "Professional and Technical",
        "value": {
            "job_sub_PT_EducationProfessionals": "Education Professionals",
            "job_sub_PT_EngineeringProfessionals": "Engineering Professionals",
            "job_sub_PT_FinancialAccountingProfessionals": "Financial and Accounting Professionals",
            "job_sub_PT_HealthProfessionals": "Health Professionals",
            "job_sub_PT_ITSpecialists": "Information Technology Specialists",
            "job_sub_PT_LegalProfessionals": "Legal Professionals",
            "job_sub_PT_MarketingAdvertisingProfessionals": "Marketing and Advertising Professionals",
            "job_sub_PT_ScienceResearchProfessionals": "Science and Research Professionals"
        }
    },
    "job_main_PublicSectorSafety": {
        "key": "Public Sector and Safety",
        "value": {
            "job_sub_PSS_FirefightersRescueWorkers": "Firefighters and Rescue Workers",
            "job_sub_PSS_GovernmentServiceWorkers": "Government Service Workers",
            "job_sub_PSS_PoliceArmedForces": "Police and Armed Forces"
        }
    },
    "job_main_ServiceSales": {
        "key": "Service and Sales",
        "value": {
            "job_sub_SS_CustomerServiceRepresentatives": "Customer Service Representatives",
            "job_sub_SS_FoodBeverageServiceWorkers": "Food and Beverage Service Workers",
            "job_sub_SS_PersonalServiceWorkers": "Personal Service Workers",
            "job_sub_SS_SalesProfessionalsRepresentatives": "Sales Professionals and Representatives"
        }
    },
    "job_main_TechniciansAssociateProfessionals": {
        "key": "Technicians and Associate Professionals",
        "value": {
            "job_sub_TAP_BusinessAdministrationAssociates": "Business and Administration Associates",
            "job_sub_TAP_EngineeringTechnicians": "Engineering Technicians",
            "job_sub_TAP_LegalSocialCulturalAssociates": "Legal, Social, and Cultural Associates",
            "job_sub_TAP_MedicalPharmaceuticalTechnicians": "Medical and Pharmaceutical Technicians"
        }
    },
    "job_main_TransportationLogistics": {
        "key": "Transportation and Logistics",
        "value": {
            "job_sub_TL_AviationProfessionals": "Aviation Professionals",
            "job_sub_TL_DriversVehicleOperators": "Drivers and Vehicle Operators",
            "job_sub_TL_LogisticsWarehouseOperators": "Logistics and Warehouse Operators"
        }
    }
}

export default jobs;