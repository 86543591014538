function isLowletter(a: string) {
    if (a.match(/([a-z])+/)) {
        return true;
    } else {
        return false;
    }
}
function isUpperletter(a: string) {
    if (a.match(/([A-Z])+/)) {
        return true;
    } else {
        return false;
    }
}
function isNum(a: string) {
    if (a.match(/([0-9])+/)) {
        return true;
    } else {
        return false;
    }
}
function isSpecial(a: string) {
    if (a.match(/[^a-zA-Z0-9]+/)) {
        return true;
    } else {
        return false;
    }
}

export default function checkPassword(password: string) {
    const message = password.length < 8 ? "Minimum length is 8"
        : isLowletter(password) === false
            ? "Require lowercase letters"
            : isNum(password) === false
                ? "Require numbers"
                : isUpperletter(password) === false
                    ? "Require uppercase letters"
                    : isSpecial(password) === false
                        ? "Require special character"
                        : "OK"
    return message;
}