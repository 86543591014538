import { Stack } from "react-bootstrap";
import React from "react";

//字体样式
const titleStyle={
    fontSize: 48,
    lineHeight:"65px",
    fontWeight: 700,
    color: "rgba(17, 17, 17, 1)",
    textAlign:"center"

}
const pStyle={
    fontSize: 32,
    lineHeight:"44px",
    fontWeight: 700,
    color: "rgba(40, 126, 255, 1)",
    textAlign:"center"
}
const defaultText={
    title:"Your Passport to Get Your Dream Offer",
    ptext:"How to find your most matching program?"
}

export default function TextDivider({title=defaultText.title,ptext=defaultText.ptext}) {
    const MAIN_WIDTH=window.MAIN_WIDTH;


    return (

        <div className="d-flex justify-content-center"style={{maxWidth:MAIN_WIDTH,marginTop:"18vw",marginBottom:80}}>
            <Stack>
                <div style={titleStyle}>{title}</div>
                <div style={pStyle}>{ptext}</div>
            </Stack>


        </div>
    )



}