import { Stack, Image, Button, Row, Col } from "react-bootstrap";
import './styles/buttons.css'
import HandlePageClick from "./Link/PageClick";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

//字体样式
const titleStyle = {
    fontSize: 54,
    lineHeight: 1.3,
    fontWeight: 700,
    color: "rgba(17, 17, 17, 1)",



}
const pStyle = {
    fontSize: 16,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.8)"

}
const btnFontStyle = {
    fontSize: 16,
    fontWeight: 600,
    color: "rgba(255, 255, 255, 1)"
}
//自定义参数
const defaultContent = {
    imgUrl: require("./img/Website/Pictures/build/ApplyU_ceshi_Home_1.webp"),
    titleText: "Empower Your Application With AI",
    pText: "Access over 10,000 up-to-date (2024) master's programs <br/>with our AI-driven platform—offering clarity, transparency,<br/> and a personalized touch. <br/>Our AI model tailors program recommendations based on your career aspirations and personal preferences, setting a clear path to your future success.",
    btnText: "Sign Up For Free",
    imgWidth: "95%",
    imgHeight: "95%",
    imgTop: 0,
    textWidth: 530
}
const TextToHtml = text => ({ __html: text })


export default function CardBig({ marginTopAdjust = 0, imgUrl = defaultContent.imgUrl, titleText = defaultContent.titleText, pText = defaultContent.pText, btnText = defaultContent.btnText, imgHeight = defaultContent.imgHeight, imgWidth = defaultContent.imgWidth, imgTop = defaultContent.imgTop, textWidth = defaultContent.textWidth }) {
    const MAIN_WIDTH = 1400;

   

    return (


        <div className="all-center" style={{ width: "100%", maxWidth: MAIN_WIDTH, margin: 60, marginTop:"6vw" }}>

            <Row className="mobile-item" style={{gap:100}}>
                <Col lg="5" md="10" className="all-center">
                    {//段落部分
                    }
                    <div>
                        <Stack gap={5} >
                            <div style={{ ...titleStyle, maxWidth: textWidth,width:"100%" }}>
                                {titleText}
                            </div>

                            <div className="fontBlackBolder mobile-item" style={{ ...pStyle, maxWidth: textWidth }} dangerouslySetInnerHTML={TextToHtml(pText)}>

                            </div>
                            <Button onClick={() => HandlePageClick("cardBig_signUp")} className="blueButton d-flex"  style={{ ...btnFontStyle, width: 214, height: 52, borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                                {btnText}
                            </Button>

                        </Stack>
                    </div>
                </Col>
                <Col lg="5" md="10" className="all-center">
                    {//图片部分
                    }
                    <div className="d-flex justify-content-end mobile-item" style={{ maxWidth: 600, paddingLeft: 0 }}>
                        <Image src={imgUrl} style={{ position: "relative", top: imgTop, width: imgWidth, left: 20, height: imgHeight }}></Image>
                    </div>
                </Col>
            </Row>


            <br />


        </div>





    )
}