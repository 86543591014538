import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Report {
    guide_id: string;
    guide_name: string;
    guide_create_time: string;
}

interface ReportsState {
    items: Report[];
}

const initialState: ReportsState = {
    items: [], // 初始为空数组
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setReports(state, action: PayloadAction<Report[]>) {
            state.items = action.payload;
        },
    },
});

export const { setReports } = reportsSlice.actions;
export default reportsSlice.reducer;
