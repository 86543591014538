import React, { useEffect, useState } from 'react';
import tutorLogo from '../assets/program-user-logo.png';
import btnClose from '../assets/popup-close.png';
import btnTutor from '../assets/popup-tutor.png';
import bgInput1 from '../assets/popup-input1.png';
import bgInput2 from '../assets/popup-input2.png';
import '../styles/TutorPopup.scss';
import { sendInfo } from '../api/tutor';

interface TutorPopupProps {
    setIsQuitOpen: (value: boolean) => void;
    isQuitOpen: boolean;
    isTutorOpen: boolean;
    setIsTutorOpen: (value: boolean) => void;
}

const TutorPopup: React.FC<TutorPopupProps> = ({ isQuitOpen, setIsQuitOpen, isTutorOpen, setIsTutorOpen }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [inputValue, setInputValue] = useState('');

    // useEffect(() => {
    //     if (isOpen) {
    //         setIsQuitOpen(false);
    //         setIsTutorOpen(true);
    //     }
    // }, [isOpen, setIsQuitOpen]);

    const openPopup = () => {
        setIsOpen(true);
        console.log('TutorPopup openPopup');
    };

    const closePopup = () => {
        setIsOpen(false);
        setIsTutorOpen(false);
    };

    const handleSubmit = async () => {
        if (inputValue) {
            try {
                await sendInfo(inputValue,"",'chat');
                console.log('Info sent successfully');
                closePopup();
            } catch (error) {
                console.error('Failed to send info:', error);
            }
            setInputValue('');
        }
        closePopup();
    };

    return (
        <div className="tutor_popup">
            <img src={tutorLogo} alt="contact your personal tutor" className="tutor_popup_icon" onClick={openPopup} />
            {isOpen && (
                <div className="tutor_popup_container">
                    <div className="tutor_popup_content">
                        <button className="close-button" onClick={closePopup}>
                            <img src={btnClose} alt="close popup" />
                        </button>
                        <div className="tutor_popup_content_title">Contact Your Personal Tutor</div>
                        <div className="tutor_popup_content_input1">
                            <img src={btnTutor} alt="tutor icon" className="tutor_popup_content_input1_tutor" />
                            <div className="tutor_popup_hello">
                                <img src={bgInput1} alt="bgInput1" />
                                <span>Need Help with Your School Application?<br/>Please type your questions below, and our tutor will get back to you within one day with personalized guidance and advice!</span>
                            </div>
                        </div>
                        <div className="tutor_popup_content_input2">
                            <img src={bgInput2} alt="bgInput2" />
                            <textarea value={inputValue}
                                placeholder="I have problem with..."
                                onChange={(e) => setInputValue(e.target.value)} />
                        </div>
                        <div className="tutor_popup_content_btns">
                            <button onClick={closePopup} className="back-button">Back</button>
                            <button onClick={handleSubmit} className="submit-button">Submit</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TutorPopup;
