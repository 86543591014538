import axios from 'axios';

let API_URL = process.env.REACT_APP_API_URL;; // 修改为你的后端API地址

const AUTH_HEADERS = {
    //'Authorization': 'Bearer your-auth-key', // 替换为你的真实认证密钥
    'Content-Type': 'application/json',
};



const USE_MOCK = false;

export const submitData = async (payload: any): Promise<void> => {
    if (USE_MOCK) {
        console.log('Submitting data:', payload);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }

    try {
        const response = await axios.post(`${API_URL}/api/submit-guide-request/`, payload, {
            headers: AUTH_HEADERS,
            withCredentials:true
        });
        if (response.status !== 200) {
            throw new Error('Failed to submit data');
        }
    } catch (error) {
        console.error('Error submitting data:', error);
        throw error;
    }
};