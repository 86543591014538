// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom';
import { isEmail } from 'validator';
import checkPassword from "../components/passwordCheck";
import { register_code, signUp } from '../api/Users';
import '../styles/Login.scss';
import logo from "../assets/AppluU-login 1.png";

interface signupformprop{
    email: string
}

const SignupForm: React.FC<signupformprop> = ({email}) => {
    //const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [countdown, setCountdown] = useState(0);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [verifyError, setVerifyError] = useState('');
    const navigate = useNavigate();

    //设计验证码1只能发送一次
    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [countdown]);



    //验证邮箱和密码的格式
    // const validateEmail = (email: string): boolean => {
    //     if (!isEmail(email)) {
    //         setEmailError('Please enter a valid email address');
    //         return false;
    //     }
    //     setEmailError('');
    //     return true;
    // };
    const validatePassword = (password: string) => {
        const msg = checkPassword(password);
        if (msg !== 'OK') {
            setPasswordError(msg);
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };


    //调用发送注册验证码的API
    const handleToken = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (countdown === 0) {
            try {
                const response = await register_code(email);
                if (response.code === 409) {
                    setEmailError("Email already exists");
                } else if (response.code === 200) {
                    setEmailError("");
                    setCountdown(60);
                }
            } catch (error) {
                setVerifyError("Internal server error, please contact support for help");
                console.error('Error during registration:', error);
            }
        }
    };
    //调用注册的API
    const handleSignup = async (e: React.FormEvent) => {
        e.preventDefault();
        // 这里可以添加实际的registration逻辑, 调用后端 API
        const userData = {
            email: email,
            password: password,
            token: token
        };
        try {
            const response = await signUp(userData);
            console.log(response);
            if (response.code === 200) {
                navigate('/login');
                console.log('Registration successful:', response.data);
            } else if (response.code === 400) {
                setVerifyError("Invalid digit code");
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    }

    return (
        <div className="login-form-container">
            <h2 className="text-2xl font-bold mb-4">Sign Up</h2>
            <form onSubmit={handleSignup} className='relative'>
                <div className="mb-3">
                    <label className="block mb-1">Email</label>
                    <input
                        type="text"
                        className={`login-input ${emailError ? 'border-red-500' : ''}`}
                        value={email}
                        //onChange={(e) => setEmail(e.target.value)}
                        //onBlur={() => validateEmail(email)}
                        readOnly
                    />
                    {emailError && <p className="text-red-500 text-sm my-1">{emailError}</p>}
                </div >
                <div className="mb-3">
                    <label className="block mb-1">Digit Code</label>
                    <div>
                        <input
                            type="text"
                            className="login-input-verify"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                            required
                        />
                        <button
                            className='w-1/3 h-10 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 disabled:bg-[#AAAAAA]'
                            style={countdown > 0 ? { background: 'gray' } : {}}
                            onClick={handleToken}
                            disabled={countdown > 0 || email === ''}
                        >
                            {countdown > 0 ? `resend in ${countdown}s` : 'Get Digit Code'}
                        </button>
                        {verifyError && <p className="text-red-500 text-sm my-1">{verifyError}</p>}
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-1">Password</label>
                    <input
                        type="text"
                        className={`login-input ${passwordError ? 'border-red-500' : ''}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={() => validatePassword(password)}
                        data-tooltip-content="Enter a strong password with at least 8 characters, including uppercase and lowercase letters, numbers, and symbols"
                        data-tooltip-id="passwordTooltip"
                        required
                    />
                    <ReactTooltip place="bottom" id='passwordTooltip' />
                    {passwordError && <p className="text-red-500 text-sm my-1">{passwordError}</p>}
                </div>
                <button type="submit" className="w-full h-10 bg-blue-500 text-white font-bold p-2 rounded-lg hover:bg-blue-600 mb-4 disabled:bg-[#AAAAAA]" disabled={token === "" || passwordError !== "" || emailError !== ""}>
                    Sign Up
                </button>
                <div className='flex justify-end'>
                    <p className="relative items-end text-sm text-gray-500">Already have an account? Go to <a className="underline hover:text-blue-500" href="/login">Log in</a></p>
                </div>
            </form>
        </div>
    );
};

export default SignupForm;
