
//样式
const textStyle={
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    color: "rgba(170, 170, 170, 1)",
    textTransform:"capitalize",
    marginTop:60,
    marginBottom:60,
    height:25
    


}

export default function PageTitle({TitleText}){
    const maxWidth=window.MAIN_WIDTH
    return(
        <div className="d-flex align-items-center" style={{...textStyle,maxWidth:maxWidth}}>
        {TitleText}
        </div>
    )
}