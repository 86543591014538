import axios from 'axios';

const env = "prod";
let API_URL = process.env.REACT_APP_API_URL;



export const register_code = async (email: string) => {
  try {
    const response = await fetch(`${API_URL}/api/register-code/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "email": email, "code_type": "register" })
    });
    if (!response.ok) {
      throw new Error('Error: Not able to send verification code');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error sending verification code', error);
    throw error;
  }
}

export const reset_code = async (email: string) => {
  try {
    const response = await fetch(`${API_URL}/api/register-code/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "email": email, "code_type": "reset_password" })
    });
    // if (!response.ok) {
    //   throw new Error('Error: Not able to send verification code');
    // }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error sending verification code', error);
    throw error;
  }
}

export const signUp = async (userData: { email: string, password: string, token: string }) => {
  try {
    const response = await fetch(`${API_URL}/api/register-verify/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "email": userData.email, "password": userData.password, "token": userData.token }),
      //credentials:"include"
    });
    if (!response.ok) {
      throw new Error('Error: Not able to register');
    }
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error signing up', error);
    throw error;
  }

}


export const resetPassword = async (userData: { email: string, password: string, token: string }) => {
  try {
    const response = await fetch(`${API_URL}/api/reset-password/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "email": userData.email, "new_password": userData.password, "code": userData.token }),
      //credentials:"include"
    });
    // if (!response.ok) {
    //   throw new Error('Error: Not able to reset');
    // }
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error signing up', error);
    throw error;
  }

}

export const login = async (userData: { email: string; password: string }) => {
  const response = await fetch(`${API_URL}/api/login/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "email": userData.email, "password": userData.password }),
    credentials: "include"
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Login failed');
  }
  return await response.json();
};



export const check_login = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/check-login-status/`, {
      withCredentials: true
    })
    return response;
  } catch (error) {
    console.error("check status error:", error)
    throw error;
  }
}

export const logout = async () => {
  try {
    const response = await axios.post(`${API_URL}/api/logout/`,{}, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    })
    return response;
  } catch (error) {
    console.error("logout error:", error)
    throw error;
  }
}

export const addToWaitList = async (email: string, occupation:string) => {
  const payload = {
    "email": email,
    "occupation": occupation
  }
  try {
    const response = await axios.post(`${API_URL}/api/add-user-to-waitlist/`, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    return response;
  }catch (error) {
    console.error("add to waitlist error:", error)
    throw error;
  }
}

export const checkUserType = async (email: string) => {
  try{
    const payload = {
      "email": email
    }
    const response = await axios.post("/api/check-user-type/", payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    return response;
  }catch (error) {
    console.error("check user type error:", error);
    throw error;
  }
}