import { ProgramItem } from '../store/wishlistSlice';
import axios from 'axios';

export let mockWishlist: ProgramItem[] = [
    {
        wishlistId: "course_code_1",
        school: "University College London",
        uni_logo_path: "https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg",
        program: "Connected Electronic and Photonic Systems MRes",
        location: "Location 1",
        country: "Country 1",
        description: "Lorem ipsum dolor sit amet consectetur. Viverra sagittis vitae consequat quam nullam accumsan. Nunc ullamcorper justo suspendisse facilisis. Magna neque lectus egestas lobortis eget dictumst."
    },
    {
        wishlistId: "course_code_2",
        school: "Bayes Business School",
        uni_logo_path: "https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg",
        program: "Program Name 2",
        location: "Location 2",
        country: "Country 2",
        description: "Lorem ipsum dolor sit amet consectetur. Viverra sagittis vitae consequat quam nullam accumsan. Nunc ullamcorper justo suspendisse facilisis. Magna neque lectus egestas lobortis eget dictumst."
    },
    {
        wishlistId: "course_code_3",
        school: "The University of Adelaide",
        uni_logo_path: "https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg",
        program: "Program Name 3",
        location: "Location 3",
        country: "Country 3",
        description: "Lorem ipsum dolor sit amet consectetur. Viverra sagittis vitae consequat quam nullam accumsan. Nunc ullamcorper justo suspendisse facilisis. Magna neque lectus egestas lobortis eget dictumst."
    },
    {
        wishlistId: "course_code_4",
        school: "School Name 4",
        uni_logo_path: "https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg",
        program: "Program Name 4",
        location: "Location 4",
        country: "Country 4",
        description: "Lorem ipsum dolor sit amet consectetur. Viverra sagittis vitae consequat quam nullam accumsan. Nunc ullamcorper justo suspendisse facilisis. Magna neque lectus egestas lobortis eget dictumst."
    },
    {
        wishlistId: "course_code_5",
        school: "School Name 5",
        uni_logo_path: "https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg",
        program: "Program Name 5",
        location: "Location 5",
        country: "Country 5",
        description: "Lorem ipsum dolor sit amet consectetur. Viverra sagittis vitae consequat quam nullam accumsan. Nunc ullamcorper justo suspendisse facilisis. Magna neque lectus egestas lobortis eget dictumst."
    },
    {
        wishlistId: "course_code_6",
        school: "School Name 6",
        uni_logo_path: "https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg",
        program: "Program Name 6",
        location: "Location 6",
        country: "Country 6",
        description: "Lorem ipsum dolor sit amet consectetur. Viverra sagittis vitae consequat quam nullam accumsan. Nunc ullamcorper justo suspendisse facilisis. Magna neque lectus egestas lobortis eget dictumst."
    },

    // 添加更多虚拟数据
];

let API_URL = process.env.REACT_APP_API_URL;

const AUTH_HEADERS = {
    // 'Authorization': 'Bearer your-auth-key', // 替换为你的真实认证密钥
    'Content-Type': 'application/json',
};



const USE_MOCK = false; // 设置为false以使用真实API

export const getWishlist = async (): Promise<ProgramItem[]> => {
    if (USE_MOCK) {
        return new Promise((resolve) => {
            setTimeout(() => {
                // resolve(mockWishlist);
                resolve([...mockWishlist]);
            }, 500);
        });
    }
    try {
        // const payload = {
        //     'user_id': userId,
        // }
        const response = await axios.get(`${API_URL}/api/get-wishlist/`, {
            headers: AUTH_HEADERS,
            // params: payload,
            withCredentials:true
        });

        if (response.status === 200) {
            const result = response.data;
            //console.log(result)
            if (result.data.length === 0) {
                console.log('Wishlist is empty');
                return [];
            }
            const formatedData = result.data.map((item: any) => ({
                wishlistId: item.course_code,
                school: item.school_name,
                uni_logo_path: item.uni_logo_path,
                program: item.program_name,
                location: item.location,
                country: item.country,
            }));
            return formatedData;
        } else if (response.status === 401) {
            throw new Error('Invalid or expired token');
        } else if (response.status === 500) {
            throw new Error('Internal Server Error');
        } else {
            throw new Error('An unknown error occurred');
        }
    } catch (error) {
        console.error('Failed to fetch wishlist:', error);
        throw error;
    }
}



export const deleteWishlistItem = async ( courseCode: string): Promise<void> => {
    if (USE_MOCK) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const index = mockWishlist.findIndex(item => item.wishlistId === courseCode);
                if (index !== -1) {
                    // const updatedWishlist = [...mockWishlist.slice(0, index), ...mockWishlist.slice(index + 1)];
                    // mockWishlist.length = 0; // Clear the original array
                    // updatedWishlist.forEach(item => mockWishlist.push(item)); // Refill the original array with updated data
                    mockWishlist = [...mockWishlist.slice(0, index), ...mockWishlist.slice(index + 1)];
                }
                resolve();
            }, 500);
        });
    }
    try {
        const payload = {
            course_code: courseCode,
        };
        await axios.post(`${API_URL}/api/delete-wishlist-item/`, payload, {
            headers: AUTH_HEADERS,
            withCredentials:true
        });
    } catch (error) {
        console.error('Failed to delete wishlist item:', error);
        throw error;
    }
};
