
const ServerIP = process.env.REACT_APP_API_URL
export const NetworkApi = {
    checkLoginStatus: "/api/check-login-status",
    fetchWaitList: "/api/fetch-all-waitlist",
    approveUser: "/api/waitlist-approve-user/",
    refuseUser: "/api/waitlist-refuse-user/",

    //else api url
}



export function GetToServer(url = "", callbackFunc = () => { }) {

    var totalUrl = ServerIP + url
    fetch(totalUrl, {
        method: "GET",
        credentials: "include"
    })
        .then(
            (re) => re.json()
        )
        .then(
            (re) => callbackFunc(re)
        )
        .catch(
            (re) => callbackFunc(re)
        )



}
export function PostToServer(url = "", postbody, callbackFunc = () => { }) {

    var totalUrl = ServerIP + url
    fetch(totalUrl, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(postbody),
        headers: { 'Content-Type': 'application/json' },

    })
        .then(
            (re) => re.json()
        )
        .then(
            (re) => callbackFunc(re)
        )
        .catch(
            (re) => callbackFunc(re)
        )



}

// export async function checkLogin() {
//     const checkAPI = "/api/check-login-status"
//     var isLogIn = false

//     GetToServer(checkAPI, (re) => {
//         console.log(re)


//         isLogIn = (re.code == 200)

//         isLogIn = true




//     })

//     return isLogIn

// }




