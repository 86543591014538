// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {checkUserType} from '../api/Users'
import mainpage_logo from '../assets/mainpage_logo.png'
import decryptEmail from '../components/decodeEmail';
import SignupForm from '../components/Signupform';
import WaitlistForm from '../components/WaitlistForm';
import logo from "../assets/AppluU-login 1.png";
import '../styles/Login.scss';
import Loading from '../componentsNew/Loading';

const signuplist = ["waitlist_approved", "staff", "registered"]
const waitlistlist = ["", "anonymous", "blocked", "waitlist_waiting", "waitlist_refused"]

const Signup = () => {
    const [usertype, setUsertype] = useState('');
    const [email, setEmail] = useState('');
    const [showsignup,setShowsignup] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const encryptedEmail = urlParams.get('email');
        let encryptedKeyBase64 = process.env.REACT_APP_ENCRYPTED_KEY_BASE64;
        let password = process.env.REACT_APP_PASSWORD;

        if (encryptedEmail) {
            setLoading(true);
            const decryptedEmail = decryptEmail(encryptedEmail, encryptedKeyBase64, password);
            //console.log('Decrypted Email:', decryptedEmail); // Log final decrypted email
            setEmail(decryptedEmail);

            (async() => {
                try{
                    const response = await checkUserType(decryptedEmail);
                    if(response.status===200){
                        //增加用户逻辑
                        const usertype = response.data.user_view_type;
                        if(signuplist.includes(usertype)){
                            setShowsignup(true);
                        }else if(waitlistlist.includes(usertype)){
                            setShowsignup(false);
                        }
                    }
                }catch(error){
                    console.log(error);
                }finally{setLoading(false);}
    
            })();
               
        }
    }, []);

    return (
        <div className='login-container'>
            <img src={mainpage_logo} className='absolute top-[35px] left-[162px]' alt="logo" onClick={()=>navigate('/')} />
            <img src={logo} alt="logo" className='login-image' />
            <div className="flex w-1/2 items-center justify-center min-h-screen bg-white">
                {loading? <Loading />: !showsignup ? <WaitlistForm /> : <SignupForm email={email} />}
            </div>
            <div className='absolute bottom-12 text-[16px] font-sans font-normal leading-[19.07px] text-center'>
                By signing up, you agree to our <a className='font-semibold underline' href='/termsofservice' target='_blank'>Terms of Service</a> and <a className='font-semibold underline' href='/privacypolicy' target='_blank'>Privacy Policy</a>
            </div>
        </div>
    );
};

export default Signup;
