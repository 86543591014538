import React, { useEffect } from 'react';
import '../styles/StartPopup.scss';
import { Link } from 'react-router-dom';
import { set } from 'lodash';

interface StartPopupProps {
    startHandler: () => void;
    restReports: number;
    setIsStartPopupOpen: (isStartPopupOpen: boolean) => void;
}



export const StartPopup: React.FC<StartPopupProps> = ({ startHandler, restReports, setIsStartPopupOpen }) => {

    const returnHandler = () => {
        setIsStartPopupOpen(false);
    }

    return (
        <div className="start_popup">
            <div className="start_popup_container">
                {/* {restReports>0 && <div className="start_popup_content">
                    <div className="start_popup_content_title">
                        You reached the report limit today, the package will automated renew tomorrow.
                    </div>
                    <div className="start_popup_content_btns" style={{ justifyContent: "end", marginRight: "50px" }}>
                        <button onClick={returnHandler} className="back-button">Close</button>
                    </div>
                </div>} */}
                {restReports === 0  && <div className="start_popup_content">
                    <div className="start_popup_content_title">
                        Upgrade your plan to view reports
                    </div>
                    {/* <div className="start_popup_content_info">
                        Remaining reports: {restReports}
                    </div> */}
                    <div className="start_popup_content_btns" style={{ padding: "0 30px" }}>
                        <button onClick={returnHandler} className="back-button">Quit</button>
                        <Link to="/payment" target='_blank'><button className="submit-button">Upgrade</button></Link>
                    </div>
                </div>}
            </div>

        </div>
    );
};
