import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import majorIcon from "../assets/Major.png";
import careerIcon from "../assets/career.png";
import locationIcon from "../assets/Location.png";
import rankingIcon from "../assets/Ranking - 1.png";
import abroadIcon from "../assets/study-abroad.png";
import arrowLeft from "../assets/ArrowLeft.png";
import majors, { Majors } from "../assets/MajorData"; // Import majors data
import jobs, { Jobs } from "../assets/JobData"; //Import jobs data
import studyAbroad, { StudyAbroad } from '../assets/studyAbroad'; //import study abroad reasons
import '../styles/Question.scss';

type answers = {
    0: string[];
    1: string[];
    2: string[];
    4: string[];
}


interface QuestionsProps {
    answers: answers;
    handleAnswer: (idx: number, answer: string) => void;
    handleDelete: (idx: number, answer: string, event: React.MouseEvent<Element, MouseEvent>) => void;
    rankvalues: number[];
    handleSliderChange: (value: number[]) => void;
}

const Questions: React.FC<QuestionsProps> = ({ answers, handleAnswer, handleDelete, rankvalues, handleSliderChange }) => {
    const [clickIndex, setClickIndex] = useState<number | null>(null); // Track the index of the clicked question
    const [option, setOptions] = useState<number | null>(null); // Track the index of the clicked main option (first column)


    const iconList = [majorIcon, careerIcon, abroadIcon, rankingIcon, locationIcon];
    const majorOptions = Object.keys(majors) as Array<keyof Majors>;
    const jobOptions = Object.keys(jobs) as Array<keyof Jobs>;
    const countries = ["United Kingdom", "Australia", "New Zeland"];
    const min = 1;
    const max = 1000;

    const handleClick = (idx: number): void => {
        setClickIndex((prevIndex) => (prevIndex === idx ? null : idx));
    };

    const handleOption = (optionIndex: number): void => {
        setOptions(optionIndex);
    };



    return (
        <div className="page4-container" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-start'}}>
            <div className="left-panel">
                <div className="title">Personalization</div>
                {questions.map((question, index) => (
                    <div
                        onClick={() => handleClick(index)}
                        key={index}
                        className={`question-element ${clickIndex === index && (index===0||index==1) ? 'expanded' : ''}`}
                        style={{height: (index !== 3 && answers[index as keyof answers].length > 0) || index===3 ? "90px" : "68px"}}
                    >
                        <img className={`question-image ${clickIndex === index ? 'expanded' : ''}`} src={iconList[index]} alt="img" />
                        <h3 className="question-text">{question.text}</h3>
                        <img className={`custom-arrow ${clickIndex === index ? 'rotated' : ''}`} src={arrowLeft} alt="img" />
                        <div className='answer-list'>
                            <ul>
                            {index !== 3 && answers[index as keyof answers].map((answer: string) => (
                                <li onClick={(event) => handleDelete(index, answer, event)}>{answer}</li>
                            ))}
                            {index === 3 && <span>Choose Top: <a className='rank-answer'>{rankvalues[0]} - {rankvalues[1]}</a></span>}
                         </ul>
                        </div>
                    </div>
                ))}
            </div>
            {clickIndex === 0 && (
                <div className="right-panel">
                    <h1 className="title">Target Project</h1>
                    <div className="content">
                        <div className="main-content">
                            <h2 className="sub-header">Main</h2>
                            <ul className="target_major_main_list">
                                {Object.entries(majors).map(([key, major], majorIndex) => (
                                    <li onClick={() => handleOption(majorIndex)}
                                        key={key}>
                                        {major.key}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="sub-content">
                            <h2 className="sub-header">Sub</h2>
                            <ul className="target_major_sub_list">
                                {option !== null &&
                                    Object.entries(majors[majorOptions[option] as keyof typeof majors].value).map(([subKey, subValue], subIndex) => (
                                        <li onClick={() => handleAnswer(0, subValue as string)}
                                            key={subKey}>
                                            {subValue}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {clickIndex === 1 && (
                <div className="right-panel">
                    <h1 className="title">Recommended</h1>
                    <div className="content">
                        <div className="main-content">
                            <h2 className="sub-header">Industries</h2>
                            <ul className="target_major_main_list">
                                {Object.entries(jobs).map(([key, job], jobIndex) => (
                                    <li onClick={() => handleOption(jobIndex)}
                                        key={key}>
                                        {job.key}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="sub-content">
                            <h2 className="sub-header">Jobs</h2>
                            <ul className="target_major_sub_list">
                                {option !== null &&
                                    Object.entries(jobs[jobOptions[option] as keyof typeof jobs].value).map(([subKey, subValue], subIndex) => (
                                        <li onClick={() => handleAnswer(1, subValue as string)}
                                            key={subKey}>
                                            {subValue}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {clickIndex === 2 && (
                <div className="right-panel">
                    <h1 className="title">Why Study Abroad (Choose only one answer please)</h1>
                    <div className="content">
                        <div className="main-content">
                            <ul className="single-list">
                                {Object.entries(studyAbroad).map(([key, reason], reasonIndex) => (
                                    <li onClick={() => handleAnswer(2, reason.key as string)}
                                        key={key}>
                                        {reason.key}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {clickIndex === 4 && (
                <div className="right-panel">
                    <h1 className="title">Countries</h1>
                    <div className="content">
                        <div className="main-content">
                            <ul className="single-list">
                                {countries.map((country, index) => (
                                    <li onClick={() => handleAnswer(4, country as string)}
                                        key={`countries_${index}`}>
                                        {country}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {clickIndex === 3 && (
                <div className="slider-container">
                    <h1 className="title">University Ranking</h1>
                    <div className="range-wrapper">
                        <div className="slider">
                            <Range
                                values={rankvalues}
                                step={1}
                                min={min}
                                max={max}
                                onChange={(values) => handleSliderChange(values)}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="track"
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ index, props }: { index: number; props: React.HTMLAttributes<HTMLDivElement> }) => (
                                    <div {...props} className="thumb">
                                        <div className="thumb-label">{rankvalues[index]}</div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="range-labels">
                            <span>Top 1</span>
                            <span>Top 1000</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};


const questions = [
    {
        text: 'What\'s your target major?',
        options: {},
    },
    {
        text: 'What\'s your career plan?',
        options: {},
    },
    {
        text: 'Why do you want to study abroad?',
        options: {},
    },
    {
        text: 'University Ranking',
        options: {},
    },
    {
        text: 'Location',
        options: {},
    }
];

export default Questions;