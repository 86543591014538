import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import HeadNav from '../componentsNew/HeadNav';
import Loading from '../componentsNew/Loading';
import TutorFilter from '../components/TutorFilter';
import TutorCard from "../components/TutorCard"
import ConatactTutorPopup from "../components/ContactTutorPopUp";
import { getAlltutor } from '../api/tutor';
import "../styles/viewTutors.scss"

interface data {
    tutor_id: string;
    tutor_name: string;
    highest_education_level: string;
    major: string;
    school: string;
    specialized_field: string;
    specialized_country: string;
    english_proficiency: string;
    tutor_score: number;
    guidance_number: number;
    personal_introduction: string;
    tutor_avatar_url: string;
};

const Tutors: React.FC = () => {

    const [isTutorOpen, setIsTutorOpen] = useState(false);
    const [tutorList, setTutorList] = useState<data[]>([]);
    const [loading, setLoading] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [selectedTutor, setSelectedTutor] = useState<string>("");

    useEffect(() => {
        // Create an asynchronous function inside the useEffect
        const fetchTutors = async () => {
            try {
                setLoading(true);
                // Fetch your data here
                const allTutors = await getAlltutor();
                setTutorList(allTutors.data);
            } catch (error) {
                console.log(error);
            }finally{
                setLoading(false);
            }
        };
    
        // Call the asynchronous function
        fetchTutors();
    }, []);

    useEffect(() => {
        if (isTutorOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Clean up by removing the class when the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isTutorOpen]);


    return (
        <>
            <HeadNav />
            <div id="view-tutor-container">

                <div id="filter-section">
                    <TutorFilter isTutorOpen={isTutorOpen} setTutorList={setTutorList} setLoading={setLoading} reloading={reloading} setReloading={setReloading} />
                </div>
                <div id="tutor-detail-section">
                    {loading? <Loading/> : Array.isArray(tutorList)&&tutorList.length>0 ? (
                        tutorList.map((data, index) => (
                            <TutorCard key={index} isTutorOpen={isTutorOpen} setIsTutorOpen={setIsTutorOpen} setSelectedTutor={setSelectedTutor} data={data} />
                        ))
                    ) : (
                        <p className='text-xl text-center'>No tutors available</p>
                    )}
                </div>
                <div id="contact-popup">
                    <ConatactTutorPopup isTutorOpen={isTutorOpen} setIsTutorOpen={setIsTutorOpen} selectedTutor={selectedTutor}/>
                </div>
            </div>
        </>

    )
};

export default Tutors;