import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/PaymentResultPopup.scss';

interface PaymentProps {
    paymentStatus: boolean;
}

export const PaymentResultPopup: React.FC<PaymentProps> = ({ paymentStatus }) => {
    const [redirecting, setRedirecting] = useState(false);
    const [countdown, setCountdown] = useState(10); // 倒计时秒数

    useEffect(() => {
        setRedirecting(true);
    }, []);

    useEffect(() => {
        let timerId: NodeJS.Timeout | null = null;

        if (redirecting && countdown > 0) {
            timerId = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else if (countdown === 0) {
            handleRedirect();
        }

        return () => {
            if (timerId) clearInterval(timerId);
        };
    }, [redirecting, countdown]);

    const handleRedirect = () => {
        const destination = paymentStatus ? "/program" : "/pricing";
        window.location.href = destination;
    };

    const handleClick = () => {
        handleRedirect();
    };

    return (
        <div className="payment_result_popup">
            <div className="payment_result_popup_container">
                <div className="payment_result_popup_content">
                    <div className="payment_result_popup_content_title">
                        {paymentStatus ? "Payment Successful" : "Payment Failed"}
                    </div>
                    <div className="payment_result_popup_content_text">
                        {paymentStatus
                            ? "Thank you for upgrading to the VIP package! \n Your payment has been processed successfully. \n Enjoy your enhanced features and benefits."
                            : "We were unable to process your payment for the VIP package. Please check your payment details and try again. If the issue persists, contact customer support for assistance."
                        }
                    </div>
                    <div className="payment_result_popup_content_btns">
                        <button className="payment_result_popup_submit-button" onClick={handleClick} disabled={countdown === 0}>
                            {paymentStatus ? "Navigate to your dashboard" : "Return to pricing page"}
                        </button>
                        {<p>Redirecting in {countdown} seconds...</p>}
                    </div>

                </div>
            </div>
        </div>
    );
};
