import FootNav from "./FootNav";
import HeadNav from "./HeadNav";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from "./PageTitle";
import StatementParagragh from "./StatementParagragh";
import { Button } from "react-bootstrap";
import { useState } from "react";
import "./styles/buttons.css"



const paragragh = {
    DateText: null,
    TitleText: "Pricing & Plan",
    pText: ""
}

const Card1 = {
    TitleText: "Free",
    PText: "Get up-to-date 10000+ master programs covering 70+ majors.",
    PriceAmount: "¥0",
    DescriptionData: [
       
        { id: 0, tag: false, text: "Browse 10000+ master programs" },
        { id: 1, tag: false, text: "Organize your professional profile" },
        { id: 2, tag: false, text: "Personal tutor assistance 24/7" },
    ],




}
const Card2 = {
    TitleText: "Pilot",
    PText: "Optimize your application with one personalized program report. ",
    PriceAmount: <>¥19.9 <span className="font8Custom">for first report</span></>,
    DescriptionData: [
        { id: 0, tag: false, text: "Experience our service with one personalized program guide report at a special introductory price." },
        { id: 1, tag: false, text: "Each report includes 10 tailored program recommendations based on your profile and preferences." },
        { id: 2, tag: false, text: "Browse 10000+ master programs" },
        { id: 3, tag: false, text: "Organize your professional profile" },
        { id: 4, tag: false, text: "Personal tutor assistance 24/7" },

    ],




}
const Card3 = {
    TitleText: "Normal",
    PText: "Continued Excellence",
    PriceAmount: <>¥39 <span className="font8Custom">/report</span></>,
    DescriptionData: [
        { id: 0, tag: false, text: "Each report features 10 personalized program recommendations tailored to enhance your decision-making" },
        { id: 1, tag: false, text: "Browse 10000+ master programs" },
        { id: 2, tag: false, text: "Organize your professional profile" },
        { id: 3, tag: false, text: "Personal tutor assistance 24/7" },
    ],
}
const Card4 = {
    TitleText: "Prime",
    PText: "All-in-one Package",
    PriceAmount: <>¥149 <span className="font8Custom">/ 5 reports</span></>,
    DescriptionData: [
        { id: 0, tag: true, text: "Get 5 personalized program guide reports, each containing 10 individually tailored program recommendations" },
        { id: 1, tag: true, text: "Comprehensive program recommendations suitable for diverse targeted majors, career plans, and experiences." },
        { id: 2, tag: false, text: "Browse 10000+ master programs" },
        { id: 3, tag: false, text: "Organize your professional profile" },
        { id: 4, tag: false, text: "Personal tutor assistance 24/7" },

    ],
}

const TipData0 = {
    FeatureTag: true,
    TitleText: "Features",
    TipContentList: [
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet."]
}
const TipData1 = {
    FeatureTag: false,
    PriceAmount: "0",
    TitleText: "Free",
    TipContentList: [
        "10 / day",
        "10 / day",
        "—",
        "—",
        "—",
        "—"]
}
const TipData2 = {
    FeatureTag: false,
    PriceAmount: "9.99",
    TitleText: "Features",
    TipContentList: [
        "50 / day",
        "50 / day",
        "50 / day",
        "—",
        "—",
        "—"]
}
const TipData3 = {
    FeatureTag: false,
    PriceAmount: "19.99",
    TitleText: "Unlimited",
    TipContentList: [
        "Unlimited",
        "Unlimited",
        "Unlimited",
        "Unlimited",
        "Unlimited",
        "Unlimited",]
}



function PricingCard({ RecommendTag = false, TitleText = Card1.TitleText, PText = Card1.PText,  PriceAmount = Card1.PriceAmount, DescriptionData = Card1.DescriptionData }) {
    const navigate = useNavigate();

    const styleNormal = {
        width: 360,
        height: 800,
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
        //border:"1px solid rgba(0, 0, 0, 1)",
        borderRadius: 20,
        backgroundColor: "white"
    }

    const styleRecommend = {
        width: 360,
        height: 800,
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
        //border:"1px solid rgba(0, 0, 0, 1)",
        borderRadius: 20,
        backgroundColor: "rgba(238, 245, 255, 1)"
    }

    const RecommendBoxStyle = {
        textAlign: "center",
        position: "absolute",
        top: 15,
        right:10,
        width: 120,
        height: 28,
        backgroundColor: "rgba(40, 126, 255, 1)",
        borderRadius: 28,
        alignItems: "center",
        color: "white"



    }


    function DescriptionItem({ text = "", highlightTag = false }) {
        return (
            <div className="d-flex flex-row justify-content-start align-items-start gap-2" style={{ width: 280, textAlign: "left",marginBottom:10 }}>
                <i className="bi bi-check2-circle" style={{ fontSize: 20 }}></i>

                <div style={{ fontWeight: highlightTag ? "bold" : "normal" }}>{text}</div>
            </div>
        )

    }

    function DescriptionList({ DescriptionData }) {

        const dList = DescriptionData
        const list = dList.map(x =>
            <DescriptionItem key={x.id} text={x.text} highlightTag={x.tag}></DescriptionItem>
        )
        return list




    }

    return (
        <div className="d-flex" style={{ ...RecommendTag ? styleRecommend : styleNormal, position: "relative",padding:"2%" }}>
            <div className="all-center font8Custom" style={{ ...RecommendBoxStyle, display: RecommendTag ? "inherit" : "none" }}>
                Recommend
            </div>

            <div className="d-flex flex-column gap-5" style={{ width: 280, height: 480 }}>
                {
                    //段落描述
                }
                <div className="d-flex row gap-2 font2Custom" style={{height:"15%"}}>
                    <div>{TitleText}</div>
                    <div className="font4Custom">{PText}</div>
                </div>

                {
                    //定价及按钮
                }
                <div className="all-center row gap-1" style={{ width: "100%" }}>
                    <div className="font1Custom">{PriceAmount}</div>
                    <div><Button className="d-flex justify-content-center align-items-center" variant="outline-dark" style={{ width: 270, height: 50, borderRadius: 100 }} onClick={() => navigate('/payment')}><div className="all-center font8Custom hoverWhite" style={{ width: 270, height: 50, alignItems: "center" }}>Get Started</div></Button></div>
                </div>
                {
                    //描述list
                }
                <div className="all-center flex-column font4Custom " style={{ rowGap: 5 }}>
                    <DescriptionList DescriptionData={DescriptionData}></DescriptionList>

                </div>


            </div>
        </div>
    )

}

function PlanCard({ TipData = TipData0 }) {
    const FeatureTag = TipData.FeatureTag
    const styleFeature = {

    }
    const stylePlan = {
        backgroundColor: "rgba(244, 247, 248, 1)",
        borderRadius: 12

    }

    function TipList({ FeatureTag, TipList }) {
        const FeatureList = TipList.map((x, index) => <div key={index}>{x}</div>)
        const PlanTipList = TipList.map((x, index) => <div key={index} className="all-center"><i className="bi bi-check2-circle" style={{ fontSize: 20, display: x == "—" ? "none" : "inherit" }}></i><span className="ml-3">{x}</span></div>
        )
        if (FeatureTag) return FeatureList
        else return PlanTipList
    }

    return (
        <div className="all-center flex-column font4Custom " style={{ ...FeatureTag ? styleFeature : stylePlan, height: "100%", width: 265, textAlign: "center" }}>
            <div className="all-center flex-column mb-5" style={{ rowGap: 48 }}>
                <div className="row" style={{ rowGap: 25 }}>
                    <div className="font6Custom">{TipData.TitleText}</div>
                    <div style={{ visibility: FeatureTag ? "hidden" : "inherit" }}>£{TipData.PriceAmount} / mo</div>
                </div>

                <div style={{ visibility: FeatureTag ? "hidden" : "inherit" }}><Button className="d-flex justify-content-center align-items-center" variant="outline-dark" style={{ width: 180, height: 50, borderRadius: 100 }}><div className="all-center font8Custom hoverWhite" style={{ width: 180, height: 50, alignItems: "center" }}>Get Started</div></Button></div>

                <div className="row" style={{ rowGap: 55 }}>
                    <TipList FeatureTag={TipData.FeatureTag} TipList={TipData.TipContentList}></TipList>
                </div>
            </div>


        </div>
    )
}
function PlanCardList() {
    return (
        <div className="mb-20" style={{ width: 1200 }}>
            <div className="font2Custom mb-5" >Compare Plans</div>

            <div className="all-center  " style={{ height: 825, }}>
                <div className=" d-flex gap-4" style={{ height: 825, minWidth: 1200 }}>
                    <PlanCard TipData={TipData0} />
                    <PlanCard TipData={TipData1} />
                    <PlanCard TipData={TipData2} />
                    <PlanCard TipData={TipData3} />

                </div>

            </div>
        </div>
    )
}


//home和service1未使用模板
export default function PricingPage() {
    return (
        <>
            <div className='d-flex row justify-content-center'>

                <HeadNav />

                <div style={{ maxWidth: 1400 }} className="d-flex row justify-content-center" >
                    {//content
                    }

                    <PageTitle TitleText={"Pricing & Plan"}></PageTitle>
                    <StatementParagragh {...paragragh}></StatementParagragh>
                    <div className="d-flex flex-row gap-2" style={{ minWidth: 1300, marginBottom: 100 }}>
                        <PricingCard {...Card1}></PricingCard>
                        <PricingCard {...Card2}></PricingCard>
                        <PricingCard {...Card3}></PricingCard>
                        <PricingCard {...Card4} RecommendTag={true}></PricingCard>
                    </div>
                    {/* <PlanCardList></PlanCardList> */}
                </div>


                <FootNav />
            </div>
        </>
    )
}
