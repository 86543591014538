
const defaultData = {
    titleText: "Policies",
    paragraghs: [
        {
            id: 0,
            pTitle: "Information We Collect",
            pText: "We may collect personal information such as your name, email address, and contact details when you register an account or interact with our platform."
        },
        {
            id: 1,
            pTitle: "How We Use Your Information",
            pText: "We use your personal information to provide and improve our services, communicate with you, and personalize your experience on Apply U."
        },
        {
            id: 2,
            pTitle: "Data Security",
            pText: "Apply U employs industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction."
        },
        {
            id: 3,
            pTitle: "Cookies",
            pText: "We may use cookies and similar tracking technologies to enhance your browsing experience and colect information about how you use our platform"
        },
        {
            id: 4,
            pTitle: "Third-Party Services",
            pText: "Apply U may use third-party service providers to help us operate our platform and deliver services to you. These providers may have access to your persona information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose."
        },
        {
            id: 5,
            pTitle: "Data Retention",
            pText: "We wil retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law."
        },
        {
            id: 6,
            pTitle: "Your Rights",
            pText: "You have the right to access. update or delete your personal information. You may also opt-out of receiving certain communications from Apply U."
        },
    ]




}

//内容单个节点
function ParagraghNode({ pTitle, pText,index }) {
    return (
        <div className="row" id="node" >
            <div className="font3Custom" id="index" style={{ width: 20 }}>{index}</div>
            <div className="row" id="pNode" style={{ gap: 12, width: 950 }}>
                <div className="font3Custom" id="pTitle">{pTitle}</div>
                <div className="font4Custom fontBlackBold" id="pText">{pText}</div>
            </div>
        </div>
    )



}
//内容节点组
function ParagraghGroup({ pGroup }) {
    
    const group = pGroup.map(p => <ParagraghNode key={p.id} pTitle={p.pTitle} pText={p.pText} index={(p.id+1)+"."}></ParagraghNode>)
    return group
}


export default function TextList({ titleText = defaultData.titleText, pGroup=defaultData.paragraghs }) {
    const maxWidth = window.MAIN_WIDTH
    return (
        <div className="d-flex flex-column align-items-start" style={{ width: maxWidth, gap: 30,marginBottom:140 }}>
            {//整个组件
            }

            {//title
            }
            <div className="font2Custom" id="title">{titleText}</div>
            {//正文部分

            }
            <div className="row" id="paragragh" style={{ gap: 48, width: "100%", marginLeft: 0 }}>
                {
                    //内容节点
                }
            <ParagraghGroup pGroup={pGroup}></ParagraghGroup>


            </div>
        </div>
    )
}