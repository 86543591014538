import { Button, ButtonGroup } from "react-bootstrap"

//按钮样式
const btnStyle = {
    backgroundColor: "transparent",
    color: "black",
    height: 68,
    border: "none",
    width:"100%",
    borderTop:"1px solid rgba(234, 234, 234, 1)"


}

//按钮数据
const defaultBtnDate=[
    {
        id:0,
        btnText:"Information We Collect"
       
       },
       {
        id:1,
        btnText:"How We Use Your Information"
       
       },
       {
        id:2,
        btnText:"Data Security"
       
       },
       {
        id:3,
        btnText:"Cookies"
       
       },
       {
        id:4,
        btnText:"Third-Party Services"
       
       },
       {
        id:5,
        btnText:"Data Retention"
       
       },
       {
        id:6,
        btnText:"Your Rights"
       
       },
       

]
//单个按钮定义
function BtnCustom({btnText}) {

    return (
        <div >
            <Button className="customButton"  style={btnStyle}>
                <div className="font3Custom" style={{ textAlign: "left", marginLeft: 100 }}>{btnText}</div>
            </Button>

        </div>

    )
}

//按钮组map
function BtnList({btnGroup}){
const list=btnGroup.map(b=><BtnCustom key={b.id} btnText={b.btnText}></BtnCustom>)
return list


}

export default function ButtonList({BtnDateGroup=defaultBtnDate}) {
    const maxWidth = window.MAIN_WIDTH
    return (
        <div className="d-flex flex-column align-items-start" style={{ maxWidth: maxWidth, gap: 20,marginBottom:80 }}>
            <div className="font2Custom" id="title" style={{textAlign:"left"}}>Content Table</div>

            <div className="row justify-content-center" id="list" >

               <BtnList btnGroup={BtnDateGroup}></BtnList>
            </div>
        </div>
    )
}