import axios from 'axios';
import {tutors} from "../assets/tutorData"


let API_URL = process.env.REACT_APP_API_URL;



const AUTH_HEADERS = {
    //'Authorization': 'Bearer your-token',
    'Content-Type': 'application/json',
}

const userId = '123456';
const MOCK_STATUS = false;

export const sendInfo = async (info: string, email:string, info_type:string) => {
    try {
        const payload = {
            info_text: info,
            email: email,
            info_type: info_type,
        };

        if (MOCK_STATUS) {
            console.log('mock: Info sent successfully');
            return;
        }


        const response = await axios.post(`${API_URL}/api/send-info/`,payload, {
            headers: AUTH_HEADERS,
            withCredentials: true,
        });

        if (response.status === 200) {
            console.log('Info sent successfully');
        } else if (response.status === 401) {
            throw new Error('Invalid or expired token');
        } else if (response.status === 500) {
            throw new Error('Internal Server Error');
        } else {
            throw new Error('An unknown error occurred');
        }
    } catch (error) {
        console.error('Failed to send info:', error);
        throw error;
    }
}



const USE_MOCK = false;

export const getTutors = async (major: string[],country:string[]) => {
    if (USE_MOCK) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    []
                );
            }, 500);
        });
    }
    try {
        const payload = {
            major:major,
            specialized_country:country
        }
        console.log(payload);
        const response = await axios.post(`${API_URL}/api/fetch-tutors/`,payload,{
            headers: AUTH_HEADERS,
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error('Failed to fetch tutors:', error);
        throw error;
    }
};


export const getAlltutor = async () => {
    if (USE_MOCK) {
        return tutors
    }
    try{
        const response = await axios.get(`${API_URL}/api/fetch-all-tutors/`);
        if (response.status === 200) {
            const data = response.data;
            return data;
        }
    } catch (error) {
        console.log('Failed to fetch tutors:', error);
        throw error;
    }
}