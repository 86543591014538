// src/store/reducers.ts
import { combineReducers } from 'redux';
import analysisReducer from './analysisSlice';
import uploadCVReducer from './uploadCVSlice';
import wishlistReducer from './wishlistSlice';
import reportsReducer from './reportsSlice';
import authReducer from './authSlice';

const rootReducer = combineReducers({
    analysis: analysisReducer,
    uploadCV: uploadCVReducer,
    wishlist: wishlistReducer,
    reports: reportsReducer,
    auth: authReducer,
});

export default rootReducer;
