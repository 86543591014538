export type SubOption = {
    [key: string]: string;
  };
  
  export type MajorOption = {
    key: string;
    value: SubOption;
  };
  
  export type Majors = {
    [key: string]: MajorOption;
  };

const majors = 
    {   "major_AF": {
            "key": "Agriculture & Forestry",
            "value": {
                "sub_AF_AgriculturalEconomics": "Agricultural Economics",
                "sub_AF_AgriculturalEngineering": "Agricultural Engineering",
                "sub_AF_Agronomy": "Agronomy",
                "sub_AF_AnimalScience": "Animal Science",
                "sub_AF_Aquaculture": "Aquaculture",
                "sub_AF_Forestry": "Forestry",
                "sub_AF_Horticulture": "Horticulture",
                "sub_AF_PlantPathology": "Plant Pathology",
                "sub_AF_SoilScience": "Soil Science"
            }
        },
        "major_ASP": {
            "key": "Applied Sciences & Professions",
            "value": {
                "sub_ASP_AviationStudies": "Aviation Studies",
                "sub_ASP_ConstructionManagement": "Construction Management",
                "sub_ASP_EmergencyDisasterManagement": "Emergency & Disaster Management",
                "sub_ASP_FamilyConsumerScience": "Family & Consumer Science",
                "sub_ASP_FashionTextilesLuxuryGoods": "Fashion, Textiles and Luxury Goods",
                "sub_ASP_FoodScience": "Food Science",
                "sub_ASP_ForensicScience": "Forensic Science",
                "sub_ASP_LibraryScience": "Library Science",
                "sub_ASP_MilitaryScience": "Military Science",
                "sub_ASP_MuseumStudies": "Museum Studies",
                "sub_ASP_RealEstatePropertyManagement": "Real Estate & Property Management",
                "sub_ASP_SocialWork": "Social Work"
            }
        },
        "major_ADA": {
            "key": "Arts, Design & Architecture",
            "value": {
                "sub_ADA_Animation": "Animation",
                "sub_ADA_Architecture": "Architecture",
                "sub_ADA_ArtHistory": "Art History",
                "sub_ADA_ArtCraft": "Art and Craft",
                "sub_ADA_CeramicsSculpture": "Ceramics and Sculpture",
                "sub_ADA_Dance": "Dance",
                "sub_ADA_Design": "Design",
                "sub_ADA_Drama": "Drama",
                "sub_ADA_FashionDesign": "Fashion Design",
                "sub_ADA_FilmStudies": "Film Studies",
                "sub_ADA_FineArts": "Fine Arts",
                "sub_ADA_GraphicDesign": "Graphic Design",
                "sub_ADA_IndustrialDesign": "Industrial Design",
                "sub_ADA_InteriorDesign": "Interior Design",
                "sub_ADA_LandscapeArchitecture": "Landscape Architecture",
                "sub_ADA_Music": "Music",
                "sub_ADA_MusicComposition": "Music Composition",
                "sub_ADA_MusicPerformance": "Music Performance",
                "sub_ADA_PaintingDrawing": "Painting & Drawing",
                "sub_ADA_Photography": "Photography",
                "sub_ADA_UrbanPlanning": "Urban Planning",
                "sub_ADA_VisualArts": "Visual Arts"
            }
        },
        "major_BM": {
            "key": "Business & Management",
            "value": {
                "sub_BM_Accounting": "Accounting",
                "sub_BM_ActuarialScience": "Actuarial Science",
                "sub_BM_Advertising": "Advertising",
                "sub_BM_Agribusiness": "Agribusiness",
                "sub_BM_Auditing": "Auditing",
                "sub_BM_Banking": "Banking",
                "sub_BM_BusinessAdministration": "Business Administration",
                "sub_BM_BusinessIntelligence": "Business Intelligence",
                "sub_BM_Commerce": "Commerce",
                "sub_BM_ConstructionManagement": "Construction Management",
                "sub_BM_CorporateCommunication": "Corporate Communication",
                "sub_BM_CorporateSocialResponsibility": "Corporate Social Responsibility",
                "sub_BM_DigitalMarketing": "Digital Marketing",
                "sub_BM_DigitalMedia": "Digital Media",
                "sub_BM_EngineeringManagement": "Engineering Management",
                "sub_BM_Entrepreneurship": "Entrepreneurship",
                "sub_BM_ExecutiveMBA": "Executive MBA",
                "sub_BM_FashionManagement": "Fashion Management",
                "sub_BM_Finance": "Finance",
                "sub_BM_FinancialManagement": "Financial Management",
                "sub_BM_FinancialTechnology": "Financial Technology",
                "sub_BM_ForensicAccounting": "Forensic Accounting",
                "sub_BM_HumanResourceManagement": "Human Resource Management",
                "sub_BM_HumanResources": "Human Resources",
                "sub_BM_ITManagement": "IT Management",
                "sub_BM_InnovationManagement": "Innovation Management",
                "sub_BM_Insurance": "Insurance",
                "sub_BM_InternationalBusiness": "International Business",
                "sub_BM_Investment": "Investment",
                "sub_BM_Leadership": "Leadership",
                "sub_BM_ManagementStudies": "Management Studies",
                "sub_BM_ManagementCreativeIndustries": "Management of Creative Industries",
                "sub_BM_Marketing": "Marketing",
                "sub_BM_MarketingManagement": "Marketing Management",
                "sub_BM_MasterBusinessAdministration": "Master in Business Administration",
                "sub_BM_MasterManagement": "Master in Management",
                "sub_BM_OperationsManagement": "Operations Management",
                "sub_BM_OperationsQualityManagement": "Operations and Quality Management",
                "sub_BM_OrganizationalLeadership": "Organizational Leadership",
                "sub_BM_ProjectManagement": "Project Management",
                "sub_BM_PublicAdministration": "Public Administration",
                "sub_BM_RealEstate": "Real Estate",
                "sub_BM_RetailManagement": "Retail Management",
                "sub_BM_RiskManagement": "Risk Management",
                "sub_BM_StrategicManagement": "Strategic Management",
                "sub_BM_SupplyChainManagement": "Supply Chain Management",
                "sub_BM_SupplyChainManagementLogistics": "Supply Chain Management & Logistics",
                "sub_BM_Taxation": "Taxation",
                "sub_BM_TransportManagement": "Transport Management"
            }
        },
        "major_CS": {
            "key": "Computer Science & IT",
            "value": {
                "sub_CS_ArtificialIntelligence": "Artificial Intelligence",
                "sub_CS_CloudComputing": "Cloud Computing",
                "sub_CS_ComputerNetworking": "Computer Networking",
                "sub_CS_ComputerSciences": "Computer Sciences",
                "sub_CS_Cybersecurity": "Cybersecurity",
                "sub_CS_DataAnalytics": "Data Analytics",
                "sub_CS_DataScience": "Data Science",
                "sub_CS_DatabaseManagement": "Database Management",
                "sub_CS_GameDesign": "Game Design",
                "sub_CS_GeographicalInformationSystems": "Geographical Information Systems",
                "sub_CS_HealthInformatics": "Health Informatics",
                "sub_CS_HumanComputerInteraction": "Human-Computer Interaction",
                "sub_CS_InformationSystems": "Information Systems",
                "sub_CS_InformationTechnology": "Information Technology",
                "sub_CS_MachineLearning": "Machine Learning",
                "sub_CS_SoftwareEngineering": "Software Engineering"
            }
        },
        "major6_EDU": {
            "key": "Education & Training",
            "value": {
                "sub_EDU_AdultEducation": "Adult Education",
                "sub_EDU_ArtEducation": "Art Education",
                "sub_EDU_Coaching": "Coaching",
                "sub_EDU_CurriculumInstruction": "Curriculum and Instruction",
                "sub_EDU_EarlyChildhoodEducation": "Early Childhood Education",
                "sub_EDU_Education": "Education",
                "sub_EDU_EducationalLeadership": "Educational Leadership",
                "sub_EDU_EducationalPsychology": "Educational Psychology",
                "sub_EDU_EducationalResearch": "Educational Research",
                "sub_EDU_ElementaryEducation": "Elementary Education",
                "sub_EDU_HealthEducation": "Health Education",
                "sub_EDU_HigherEducation": "Higher Education",
                "sub_EDU_InstructionalDesign": "Instructional Design",
                "sub_EDU_LiteracyEducation": "Literacy Education",
                "sub_EDU_PhysicalEducation": "Physical Education",
                "sub_EDU_PrimaryEducation": "Primary Education",
                "sub_EDU_STEMEducation": "STEM Education",
                "sub_EDU_SchoolCounselling": "School Counselling",
                "sub_EDU_SecondaryEducation": "Secondary Education",
                "sub_EDU_SpecialEducation": "Special Education",
                "sub_EDU_Teaching": "Teaching",
                "sub_EDU_TeachingEnglishForeignLanguage": "Teaching English as a Foreign Language"
            }
        },
        "major7_ET": {
            "key": "Engineering & Technology",
            "value": {
                "sub_ET_AerospaceEngineering": "Aerospace Engineering",
                "sub_ET_AutomotiveEngineering": "Automotive Engineering",
                "sub_ET_BioBiomedicalEngineering": "Bio & Biomedical Engineering",
                "sub_ET_ChemicalEngineering": "Chemical Engineering",
                "sub_ET_CivilEngineeringConstruction": "Civil Engineering & Construction",
                "sub_ET_CommunicationsEngineering": "Communications Engineering",
                "sub_ET_ElectricalEngineering": "Electrical Engineering",
                "sub_ET_ElectronicsEmbeddedTechnology": "Electronics & Embedded Technology",
                "sub_ET_EnergyEngineering": "Energy Engineering",
                "sub_ET_EnvironmentalEngineering": "Environmental Engineering",
                "sub_ET_GeneralEngineeringTechnology": "General Engineering & Technology",
                "sub_ET_IndustrialSystemsEngineering": "Industrial & Systems Engineering",
                "sub_ET_MarineEngineering": "Marine Engineering",
                "sub_ET_MaterialsScience": "Materials Science",
                "sub_ET_MechanicalEngineering": "Mechanical Engineering",
                "sub_ET_Mechatronics": "Mechatronics",
                "sub_ET_MiningOilGas": "Mining, Oil & Gas",
                "sub_ET_NuclearEngineering": "Nuclear Engineering",
                "sub_ET_ProductionManufacturing": "Production and Manufacturing",
                "sub_ET_Robotics": "Robotics",
                "sub_ET_SoundEngineering": "Sound Engineering",
                "sub_ET_StructuralEngineering": "Structural Engineering",
                "sub_ET_SustainableEnergy": "Sustainable Energy",
                "sub_ET_TransportationEngineering": "Transportation Engineering"
            }
        },
        "major8_ESES": {
            "key": "Environmental Studies & Earth Sciences",
            "value": {
                "sub_ESES_BiodiversityConservation": "Biodiversity & Conservation",
                "sub_ESES_ClimateStudiesMeteorology": "Climate Studies & Meteorology",
                "sub_ESES_EarthSciences": "Earth Sciences",
                "sub_ESES_Ecology": "Ecology",
                "sub_ESES_EnvironmentalEconomics": "Environmental Economics",
                "sub_ESES_EnvironmentalManagement": "Environmental Management",
                "sub_ESES_EnvironmentalPolicy": "Environmental Policy",
                "sub_ESES_EnvironmentalScience": "Environmental Science",
                "sub_ESES_EnvironmentalStudies": "Environmental Studies",
                "sub_ESES_Geography": "Geography",
                "sub_ESES_Geology": "Geology",
                "sub_ESES_HydrologyWaterManagement": "Hydrology & Water Management",
                "sub_ESES_MarineBiology": "Marine Biology",
                "sub_ESES_Meteorology": "Meteorology",
                "sub_ESES_NaturalResourceManagement": "Natural Resource Management",
                "sub_ESES_Oceanography": "Oceanography",
                "sub_ESES_SoilScience": "Soil Science",
                "sub_ESES_SustainableDevelopment": "Sustainable Development",
                "sub_ESES_Toxicology": "Toxicology"
            }
        },
        "major9_HLS": {
            "key": "Hospitality, Leisure & Sports",
            "value": {
                "sub_HLS_CulinaryArts": "Culinary Arts",
                "sub_HLS_EventManagement": "Event Management",
                "sub_HLS_HospitalityMarketing": "Hospitality Marketing",
                "sub_HLS_SportExerciseScience": "Sport and Exercise Science",
                "sub_HLS_SportsManagement": "Sports Management",
                "sub_HLS_TourismManagement": "Tourism Management",
                "sub_HLS_TravelTourism": "Travel and Tourism"
            }
        },
        "major10_HU": {
            "key": "Humanities",
            "value": {
                "sub_HU_Anthropology": "Anthropology",
                "sub_HU_Archaeology": "Archaeology",
                "sub_HU_ArtHistory": "Art History",
                "sub_HU_ClassicalStudies": "Classical Studies",
                "sub_HU_Classics": "Classics",
                "sub_HU_CreativeWriting": "Creative Writing",
                "sub_HU_ESL": "ESL",
                "sub_HU_Ethics": "Ethics",
                "sub_HU_GenderStudies": "Gender Studies",
                "sub_HU_GeneralStudies": "General Studies",
                "sub_HU_History": "History",
                "sub_HU_IslamicStudies": "Islamic Studies",
                "sub_HU_LanguageStudies": "Language Studies",
                "sub_HU_Linguistics": "Linguistics",
                "sub_HU_Literature": "Literature",
                "sub_HU_ModernHistory": "Modern History",
                "sub_HU_Philosophy": "Philosophy",
                "sub_HU_ReligiousStudies": "Religious Studies",
                "sub_HU_TheologyReligiousStudies": "Theology and Religious Studies"
            }
        },
        "major11_JM": {
            "key": "Journalism & Media",
            "value": {
                "sub_JM_Advertising": "Advertising",
                "sub_JM_DigitalMedia": "Digital Media",
                "sub_JM_FilmProduction": "Film Production",
                "sub_JM_Journalism": "Journalism",
                "sub_JM_MediaStudies": "Media Studies",
                "sub_JM_MultimediaDesign": "Multimedia Design",
                "sub_JM_Photojournalism": "Photojournalism",
                "sub_JM_PublicRelations": "Public Relations",
                "sub_JM_RadioBroadcasting": "Radio Broadcasting",
                "sub_JM_TelevisionProduction": "Television Production"
            }
        },
        "major12_LAW": {
            "key": "Law",
            "value": {
                "sub_LAW_BusinessLaw": "Business Law",
                "sub_LAW_CivilPrivateLaw": "Civil & Private Law",
                "sub_LAW_CriminalJustice": "Criminal Justice",
                "sub_LAW_CriminalLaw": "Criminal Law",
                "sub_LAW_EuropeanLaw": "European Law",
                "sub_LAW_InternationalLaw": "International Law",
                "sub_LAW_LegalStudies": "Legal Studies",
                "sub_LAW_MasterLawsLLM": "Master of Laws (LLM)",
                "sub_LAW_PatentIntellectualPropertyLaw": "Patent & Intellectual Property Law",
                "sub_LAW_PublicLaw": "Public Law"
            }
        },
        "major13_MH": {
            "key": "Medicine & Health",
            "value": {
                "sub_MED_BiomedicalSciences": "Biomedical Sciences",
                "sub_MED_ClinicalPsychology": "Clinical Psychology",
                "sub_MED_Dentistry": "Dentistry",
                "sub_MED_HealthAdministration": "Health Administration",
                "sub_MED_Medicine": "Medicine",
                "sub_MED_Nursing": "Nursing",
                "sub_MED_NutritionDietetics": "Nutrition & Dietetics",
                "sub_MED_OccupationalTherapy": "Occupational Therapy",
                "sub_MED_Pharmacy": "Pharmacy",
                "sub_MED_PhysicalTherapy": "Physical Therapy",
                "sub_MED_Psychiatry": "Psychiatry",
                "sub_MED_PublicHealth": "Public Health",
                "sub_MED_SpeechPathology": "Speech Pathology",
                "sub_MED_VeterinaryMedicine": "Veterinary Medicine"
            }
        },
        "major14_NSM": {
            "key": "Natural Sciences & Mathematics",
            "value": {
                "sub_SCI_Astrophysics": "Astrophysics",
                "sub_SCI_Biochemistry": "Biochemistry",
                "sub_SCI_Biology": "Biology",
                "sub_SCI_Biotechnology": "Biotechnology",
                "sub_SCI_Chemistry": "Chemistry",
                "sub_SCI_EnvironmentalScience": "Environmental Science",
                "sub_SCI_Genetics": "Genetics",
                "sub_SCI_Mathematics": "Mathematics",
                "sub_SCI_Microbiology": "Microbiology",
                "sub_SCI_MolecularSciences": "Molecular Sciences",
                "sub_SCI_Neuroscience": "Neuroscience",
                "sub_SCI_Oceanography": "Oceanography",
                "sub_SCI_Pharmacology": "Pharmacology",
                "sub_SCI_Physics": "Physics",
                "sub_SCI_Statistics": "Statistics"
            }
        },
        "major15_SS": {
            "key": "Social Sciences",
            "value": {
                "sub_SOC_Anthropology": "Anthropology",
                "sub_SOC_CommunicationStudies": "Communication Studies",
                "sub_SOC_CommunityDevelopment": "Community Development",
                "sub_SOC_Criminology": "Criminology",
                "sub_SOC_CulturalStudies": "Cultural Studies",
                "sub_SOC_DevelopmentalPsychology": "Developmental Psychology",
                "sub_SOC_Economics": "Economics",
                "sub_SOC_Geography": "Geography",
                "sub_SOC_HumanGeography": "Human Geography",
                "sub_SOC_InternationalRelations": "International Relations",
                "sub_SOC_PoliticalScience": "Political Science",
                "sub_SOC_Psychology": "Psychology",
                "sub_SOC_PublicPolicy": "Public Policy",
                "sub_SOC_SocialWork": "Social Work",
                "sub_SOC_Sociology": "Sociology"
            }
        }
        }


export default majors;