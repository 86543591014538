import React, { useState } from 'react';
import "../styles/PlanDetails.scss";
interface PlanDetailsProps {
    onSelectPlan: (plan: 'Pilot' | 'Normal' | 'Prime', price: number) => void;
    handlePlanConfirm: () => void;
}

export const PlanDetails: React.FC<PlanDetailsProps> = ({ onSelectPlan, handlePlanConfirm }) => {
    const [selectedPlan, setSelectedPlan] = useState<'Pilot' | 'Normal' | 'Prime'>('Prime');
    const [price, setPrice] = useState<number>(149);
    // const [orderStatus, setOrderStatus] = useState<boolean>(false);
    // const [showCheckOrderStatus, setShowCheckOrderStatus] = useState<boolean>(false);

    const planContents: { [key in 'Pilot' | 'Normal' | 'Prime']: string[] } = {
        'Pilot': [
            'Get one personalized program guide report (each includes 10 recommended programs)',
            'Browse 10000+ master programs',
            'Organize your professional profile',
            'Personal tutor assistance 24/7',
        ],
        'Normal': [
            'Get one personalized program guide report (each includes 10 recommended programs)',
            'Browse 10000+ master programs',
            'Organize your professional profile',
            'Personal tutor assistance 24/7',
        ],
        'Prime': [
            'Get five personalized program guide reports, each containing 10 individually tailored program recommendations, allowing for nuanced adjustments to your targeted major, career plans, and experiences.',
            'Browse 10000+ master programs',
            'Organize your professional profile',
            'Personal tutor assistance 24/7',
        ]
    };

    const infoContents: { [key in 'Pilot' | 'Normal' | 'Prime']: string[] } = {
        'Pilot': [
            'Experience our service with one personalized program guide report at a special introductory price.'
        ],
        'Normal': [
            'Optimize your application with one personalized program report.'
        ],
        'Prime': [
            'Enhance your choices with five personalized reports in the Primer plan.'
        ],
    };

    const handlePlanChange = (plan: 'Pilot' | 'Normal' | 'Prime', price: number) => {
        setSelectedPlan(plan);
        setPrice(price);
        onSelectPlan(plan, price);
    };

    return (
        <div className="plan-details">
            <div className="plan-details_choices">
                <button onClick={() => handlePlanChange('Pilot', 19.9)}
                    className={selectedPlan === 'Pilot' ? 'plan-details_choice_selected' : 'plan-details_choice'}
                >
                    <div className="plan-details_choice_title">
                        Pilot
                    </div>
                    <div className="plan-details_choice_price">
                        19.9 <span>CNY</span>
                    </div>
                </button>
                <button onClick={() => handlePlanChange('Normal', 39)}
                    className={selectedPlan === 'Normal' ? 'plan-details_choice_selected' : 'plan-details_choice'}
                >
                    <div className="plan-details_choice_title">
                        Normal
                    </div>
                    <div className="plan-details_choice_price">
                        39 <span>CNY</span>
                    </div>
                </button>
                <button onClick={() => handlePlanChange('Prime', 149)}
                    className={selectedPlan === 'Prime' ? 'plan-details_choice_selected' : 'plan-details_choice'}
                >
                    <div className="plan-details_choice_title">
                        Prime <span>Save 24%</span>
                    </div>
                    <div className="plan-details_choice_price">
                        149 <span>CNY</span>
                    </div>
                </button>

            </div>
            <div className="plan-details_intro">
                {infoContents[selectedPlan]}
            </div>
            <div className="plan-details_intros">
                {planContents[selectedPlan].map((content, index) => (
                    <div key={index} className="plan-details_intros_list">
                        <div><svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 22 22" fill="none">
                            <path d="M6 11.1081L9.94872 15L17 7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></div>
                        <div>{content}</div>
                    </div>
                ))}
            </div>
            <div className="plan-details_price">
                <div>
                    <span>Total</span>     <span>{price}</span> CNY
                </div>
                <button onClick={handlePlanConfirm} className="submit-button">Confirm</button>
            </div>
        </div >
    );
};