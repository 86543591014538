// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../store/authSlice';
import { check_login } from '../api/Users'
import { RootState, AppDispatch } from '../store/store';
import { isEmail } from 'validator';
import logo from "../assets/AppluU-login 1.png";
import mainpage_logo from '../assets/mainpage_logo.png'
import '../styles/Login.scss';

const useAppDispatch = () => useDispatch<AppDispatch>();


const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [checked, setChecked] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const error = useSelector((state: RootState) => state.auth.error);
    const location = useLocation();

    const from = location.state?.from || "/";
    


    useEffect(() => {
        // Create an asynchronous function inside the useEffect
        const verifyLogin = async () => {
            try {
                const response = await check_login();
                if (response.data.code === 200) {
                    navigate(from, {replace: true});
                }
            } catch (error) {
                console.log(error);
            }
        };

        // Call the asynchronous function
        verifyLogin();
    }, []);


    const validateEmail = (email: string): boolean => {
        if (!isEmail(email)) {
            setEmailError('Please enter a valid email address');
            return false;
        }
        setEmailError('');
        return true;
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await dispatch(loginUser({ email, password })).unwrap();
            if (result) {
                navigate(from, {replace: true}); // 登录成功后跳转homepage
                console.log("login Successfully")
            }
        } catch (error) {
            console.log('Invalid username or password');
        }
    };

    return (
        <div className='login-container'>
            <img src={mainpage_logo} className='absolute top-[35px] left-[162px] hover:cursor-pointer' alt="logo" onClick={()=>navigate('/')}/>
            <img src={logo} alt="logo" className='login-image' />
            <div className="flex w-1/2 items-center justify-center min-h-screen bg-white">
                <div className="login-form-container">
                    <h2 className="text-2xl font-bold mb-4 ">Login</h2>
                    <form onSubmit={handleLogin} className='relative'>
                        <div className="mb-3">
                            <label className="block mb-1">Username</label>
                            <input
                                type="text"
                                className="login-input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={() => validateEmail(email)}
                                required
                            />
                            {emailError && <p className="text-red-500 text-sm my-1">{emailError}</p>}
                        </div>
                        <div className="mb-3">
                            <label className="block mb-1">Password</label>
                            <input
                                type="password"
                                className="login-input"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="flex items-center gap-x-4 mb-3">
                            <input
                                type="checkbox"
                                className="login-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                checked={checked}
                                onClick={()=>setChecked(!checked)}
                                required
                            />
                            <span className='text-md'>I agree with <a className='font-semibold underline' href='/termsofservice' target='_blank'>Terms of Service</a> and <a className='font-semibold underline' href='/privacypolicy' target='_blank'>Privacy Policy</a></span>
                        </div>
                        <button type="submit" className="w-full h-10 bg-blue-500 text-white font-bold p-2 rounded-lg hover:bg-blue-600 mb-4 disabled:bg-[#AAAAAA]" disabled={email === "" || password === ""||checked===false} >
                            Login
                        </button>
                        {error && <p className="text-red-500">{error}</p>}
                        <div className='flex width-full justify-between items-center text-sm text-gray-500'>
                            <p className="relative left-0 text-sm text-gray-500"> <a className="underline hover:text-blue-500" href="/reset">Reset Password</a></p>
                            <p className="relative  text-sm text-gray-500">Not a user? <a className="underline hover:text-blue-500" href="/signup">Join waitlist</a></p>
                        </div>
                    </form>
                </div>
            </div>


        </div>
    );
};

export default Login;
