import React, { useEffect } from 'react';
import '../styles/SubmitPopup.scss';

interface SubmitPopupProps {
    setStep: (step: number) => void;
    setIsSubmited: (isSubmited: boolean) => void;
}

const SubmitPopup: React.FC<SubmitPopupProps> = ({ setStep, setIsSubmited }) => {

    return (
        <div className="submit_popup">
            {(
                <div className="submit_popup_container">
                    <div className="submit_popup_content">
                        <div className="submit_popup_content_title">Submit successfully!</div>
                        <div className="submit_popup_content_info">
                             Your report is being generated and will be ready in about 5 minutes. Check your email for a notification.
                        </div>
                        <div className="submit_popup_content_btns">
                            {/* <button onClick={handleSubmit} className="back-button">Quit</button> */}
                            <button onClick={() => { setStep(1); setIsSubmited(false); }} className="submit-button">Continue</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubmitPopup;
