import { Button, Stack, Image, Row, Col } from "react-bootstrap";

//字体样式
const titleStyle = {
    fontSize: 20,
    lineHeight: "27px",
    fontWeight: 700,
    color: "rgba(17, 17, 17, 1)",
    marginTop: 20,
    marginBottom: 0
}
//按钮样式
const stepStyle = {

    color: "rgba(17, 17, 17, 1)",

    fontSize: "32px",
    fontWeight: 700,


}
//可选参数
const defaultProp = {
    btnText: "STEP 1",
    titleText: "Application Preparation",
    pText: "Lorem ipsum dolor sit amet consectetur. Malesuada quam ultricies dolor eget. Quis tristique integer molestie gravida cras aliquam pellentesque et. ",
    imgUrl: require("./img/ApplyU- AP-1.png"),
    iconUrl: require("./img/CV - Basic.png"),
    imgWidth: 400,
    imgHeight: 230,
    //以下参数为修正图片大小不一致带来的版式不一致问题
    textBoxWidth: 440,
    imgMarginTopAdjust: 0,
    imgLayWidth: 0

}



export default function ServiceCard({ marginTopAdjust = 0, btnText = defaultProp.btnText, titleText = defaultProp.titleText, pText = defaultProp.pText, imgUrl = defaultProp.imgUrl, iconUrl = defaultProp.iconUrl, reverseTag = defaultProp.reverseTag, imgWidth = defaultProp.imgWidth, imgHeight = defaultProp.imgHeight, textBoxWidth = defaultProp.textBoxWidth, imgMarginTopAdjust = 0, imgLayWidth = 0 }) {





    const imgOrderIndex = reverseTag ? "order-1" : "order-3";
    const iwidth = imgLayWidth + imgWidth
    const textBoxWidthAdjust = textBoxWidth + 50
    const MAIN_WIDTH = 1300;

    const marginTopCal = marginTopAdjust


    return (
        <div style={{ maxWidth: MAIN_WIDTH, marginTop: marginTopCal, marginBottom: "6vw" }}>
            <div className="d-flex flex-row justify-content-between mobile-container" style={{ marginTop: 0, marginBottom: 60, maxWidth: MAIN_WIDTH }}>

                <Row className="gap-5 d-flex justify-content-between" >
                    <Col lg="4" md="10">
                        {
                            //图文按钮部分
                        }
                        <div className="d-flex align-items-center mobile-item" style={{ marginTop: 20, marginLeft: 0, marginRight: 0, maxWidth: textBoxWidthAdjust }}>

                            <div className="d-flex row gap-2 mobile-item">
                                <div className="d-flex align-items-center gap-3" style={stepStyle}><Image src={iconUrl} style={{ width: 64, height: 64 }}></Image>{btnText}</div>
                                <div className="align-items-center" style={titleStyle}>{titleText}</div>
                                <div className="font4Custom" style={{ maxWidth: textBoxWidth, height: "auto" }}>{pText}</div>

                            </div>



                        </div>
                    </Col>
                    <Col lg="7" md="10" className="d-flex justify-content-end" >
                        {//图片部分

                        }
                        <div className=" d-flex align-items-center mobile-item" style={{  marginTop: imgMarginTopAdjust, marginLeft: 0, justifyContent: "center" }}>
                            <Image src={imgUrl}  fluid></Image>
                        </div>
                    </Col>
                </Row>



            </div>

        </div>

    )

}