import { Report } from '../store/reportsSlice';
import axios from 'axios';

export const mockReports: Report[] = [
    { guide_id: 'Report 1', guide_name: 'Guide Name 1', guide_create_time: '2024-06-01' },
    { guide_id: 'Report 2', guide_name: 'Guide Name 2', guide_create_time: '2024-06-02' },
    { guide_id: 'Report 3', guide_name: 'Guide Name 3', guide_create_time: '2024-06-03' },
    { guide_id: 'Report 4', guide_name: 'Guide Name 4', guide_create_time: '2024-06-04' },
    { guide_id: 'Report 5', guide_name: 'Guide Name 5', guide_create_time: '2024-06-05' },
    { guide_id: 'Report 6', guide_name: 'Guide Name 6', guide_create_time: '2024-06-06' },
    { guide_id: 'Report 7', guide_name: 'Guide Name 7', guide_create_time: '2024-06-07' },
    { guide_id: 'Report 8', guide_name: 'Guide Name 8', guide_create_time: '2024-06-08' },
    { guide_id: 'Report 9', guide_name: 'Guide Name 9', guide_create_time: '2024-06-09' },
    { guide_id: 'Report 10', guide_name: 'Guide Name 10', guide_create_time: '2024-06-10' },
    { guide_id: 'Report 11', guide_name: 'Guide Name 11', guide_create_time: '2024-06-11' },
    { guide_id: 'Report 12', guide_name: 'Guide Name 12', guide_create_time: '2024-06-12' },
    { guide_id: 'Report 13', guide_name: 'Guide Name 13', guide_create_time: '2024-06-13' },
    { guide_id: 'Report 14', guide_name: 'Guide Name 14', guide_create_time: '2024-06-14' },
    { guide_id: 'Report 15', guide_name: 'Guide Name 15', guide_create_time: '2024-06-15' },
    { guide_id: 'Report 16', guide_name: 'Guide Name 16', guide_create_time: '2024-06-16' },
    { guide_id: 'Report 17', guide_name: 'Guide Name 17', guide_create_time: '2024-06-17' },
    { guide_id: 'Report 18', guide_name: 'Guide Name 18', guide_create_time: '2024-06-18' },
    { guide_id: 'Report 19', guide_name: 'Guide Name 19', guide_create_time: '2024-06-19' },
    { guide_id: 'Report 20', guide_name: 'Guide Name 20', guide_create_time: '2024-06-20' },
    { guide_id: 'Report 21', guide_name: 'Guide Name 21', guide_create_time: '2024-06-21' },
    { guide_id: 'Report 22', guide_name: 'Guide Name 22', guide_create_time: '2024-06-22' },
];


let API_URL = process.env.REACT_APP_API_URL; // 修改为你的后端API地址

const AUTH_HEADERS = {
    // 'Authorization': 'Bearer your-auth-key', // 替换为你的真实认证密钥
    'Content-Type': 'application/json',
};


const USE_MOCK = false;

export const getReports = async (): Promise<Report[]> => {
    // 模拟API调用，返回虚拟数据
    if (USE_MOCK) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    mockReports
                );
            }, 500);
        });
    }
    try {
        // const payload = {
        //     'user_id': userId,
        // }
        const response = await axios.get(`${API_URL}/api/fetch-guide-reports/`, {
            headers: AUTH_HEADERS,
            withCredentials:true
        });

        if (response.status === 200) {
            const data = response.data.data;
            if (data.length === 0) {
                console.log('Report is empty');
                return [];
            }
            const formatedData = data.map((item: any) => ({
                guide_id: item.guide_id,
                guide_name: item.guide_name,
                guide_create_time: item.guide_create_time,
            }));
            return formatedData;
        } else {
            throw new Error('An unknown error occurred');
        }
    } catch (error:any) {
        if(error.response.status === 404){
            console.log('No Report')
            return [];
        }else{
            console.log('Failed to fetch previous report:', error);
            throw error; 
        }
    }
};

export const getReportLimit = async () =>{
    try{
        const response:any = await axios.get(`${API_URL}/api/get-user-report-limit/`,{
            headers: AUTH_HEADERS,
            withCredentials:true
        })
        if(response.status === 200){
            return response.data
        }
    }catch(error:any){
        throw error; 
    }
}

