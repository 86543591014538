import React from 'react';
import tutorPic from '../assets/Tutor_Pic.png';
import '../styles/TutorCard.scss';

interface ProfileCardProps {
    data: {
        tutor_id: string;
        tutor_name: string;
        highest_education_level: string;
        major: string;
        school: string;
        specialized_field: string;
        specialized_country: string;
        english_proficiency: string;
        tutor_score: number;
        guidance_number: number;
        personal_introduction: string;
        tutor_avatar_url:string;
    },
    isTutorOpen: boolean;
    setIsTutorOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedTutor: React.Dispatch<React.SetStateAction<string>>;
}



const TutorCard: React.FC<ProfileCardProps> = ({data,setIsTutorOpen,setSelectedTutor}) => {

    const handleClick = () => {
        setSelectedTutor(data.tutor_id);
        //console.log(data.tutor_id)
        setIsTutorOpen(true);
    }

    return (
        <div className="tutor-card">
            <div className="tutor-image-container">
                <img src={data.tutor_avatar_url} alt="Tutor" className="tutor-image" />
                <button className="tutor-contact-button" onClick={handleClick}>Contact with Tutor</button>
            </div>
            <div className="tutor-info">
                <div className='tutor-row1'>
                    <h2 className="tutor-name w-1/3">{data.tutor_name}</h2>
                    <div className='w-1/3'>
                        <p className="tutor-score">Overall Score</p>
                        <span className='highlighted-answer'>{data.tutor_score} / 10</span>
                    </div>
                    <div className='w-1/3'>
                        <p className="tutor-guidance">Guidance Number</p>
                        <span className='highlighted-answer'>{data.guidance_number} Students</span>
                    </div>
                </div>
                <div className='tutor-row2'>
                    <div className='w-1/3'>
                        <p className="tutor-detail">Highest Edu Level</p>
                        <span className='answer'>{data.highest_education_level}</span>
                    </div>
                    <div className='w-1/3'>
                        <p className="tutor-detail">School</p>
                        <span  className='answer'>{data.school}</span>
                    </div>
                    <div className='w-1/3'>
                        <p className="tutor-detail">Major</p>
                        <span  className='answer'>{data.major}</span>
                    </div>
                </div>
                <div className="tutor-row3">
                    <div className='w-1/3'>
                        <p className="tutor-detail">Specialized Field</p>
                        <span  className='answer'>{data.specialized_field}</span>
                    </div>
                    <div className='w-1/3'>
                        <p className="tutor-detail">Specialized Country</p>
                        <span  className='answer'>{data.specialized_country}</span>
                    </div>
                    <div className='w-1/3'>
                        <p className="tutor-detail">English Proficiency</p>
                        <span  className='answer'>{data.english_proficiency}</span>
                    </div>
                </div>
                <div>
                    <p className='intro-title'>Introduction</p>
                    <p className="tutor-description">{data.personal_introduction}</p>
                </div>
            </div>
        </div>
    );
};

export default TutorCard;
