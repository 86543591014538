
export type SubOption = {
    [key: string]: string;
  };
export type detailReason = {
    key: string;
    value: SubOption;
  };
export type StudyAbroad = {
    [key: string]: detailReason;
  };

const studyAbroad = {
    "why_study_phd": {
        "key": "Apply for a PhD",
        "value": {
            "why_phd_local": "Apply for a Local PhD",
            "why_phd_aroundworld": "Apply for a PhD Around the World",
            "why_phd_homecountry": "Apply for a PhD in Home Country"
        }
    },
    "why_study_findjob": {
        "key": "Find a Job",
        "value": {
            "why_findjob_homecountry": "Find a Job in Home Country",
            "why_findjob_local": "Find a Local Job",
            "why_findjob_international": "Find an International Job"
        }
    },
    "why_study_improvement": {
        "key": "Improvement",
        "value": {
            "why_improvement_highmajor": "High Ranking Major",
            "why_improvement_highuni": "High Ranking University"
        }
    }
}

export default studyAbroad;