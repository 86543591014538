import { identity } from "lodash";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { GetToServer, NetworkApi, PostToServer } from "../Link/Network";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";

const defaultData = [{ "id": "-1", "email": "xxxx@example.com", "occupation": "null", "created_time": "null", "other_info": "null", "status": "null" }]




export default function WaitListPage() {
    const [data, SetData] = useState(defaultData)
    const [handlestate, SetHandlestate] = useState({ "fetch": false })
    const linkto = useNavigate()



    function handleClick() {
        setHandleState("fetch", true)
        handleFetch()
    }
    function setHandleState(key, value) {
        const state = { ...handlestate }
        if (!key in state) { state = { ...state, key: value } }
        state[key] = value
        SetHandlestate(state)
        console.log(state)


    }

    function handleFetch() {

        GetToServer(NetworkApi.fetchWaitList, (re) => {
            setHandleState("fetch", false)
            console.log(re.code)
            const datalist = re.data
            if (datalist) { SetData(datalist) }
            if (re.code === 302) { linkto("/login") }

        })
    }
    function handleApprove(email, id = null) {
        const postbody = { "email": email }
        setHandleState("approve" + id, true)
        PostToServer(NetworkApi.approveUser, postbody, (re) => {
            alert(re.msg)
            handleFetch()
            setHandleState("approve" + id, false)
        })

    }
    function handleRefuse(email, id = null) {
        const postbody = { "email": email }
        setHandleState("refuse" + id, true)
        PostToServer(NetworkApi.refuseUser, postbody, (re) => {
            alert(re.msg)
            handleFetch()
            setHandleState("refuse" + id, true)

        })

    }

    const TRowUnit = ({ data, id }) => {



        return (
            <tr>
                <td>{data["id"]}</td>
                <td>{data["email"]}</td>
                <td>{data["occupation"]}</td>
                <td>{data["created_time"]}</td>
                <td>{data["other_info"]}</td>
                <td>{data["status"]}</td>
                <td>
                    <div className="d-flex flex-wrap justify-content-center gap-3">
                        {handlestate["approve" + id] || handlestate["refuse" + id] ?
                            <Loading></Loading>
                            :
                            <>
                                <Button onClick={() => handleApprove(data["email"], id)} variant="success" style={{ width: 120, height: 50 }}>允许</Button>
                                <Button onClick={() => handleRefuse(data["email"], id)} variant="danger" style={{ width: 120, height: 50 }}>拒绝</Button>
                            </>
                        }


                    </div>

                </td>
            </tr>
        )

    }
    const TRow = ({ data }) => {

        const trowlist = data.map(list => <TRowUnit data={list} id={list["id"]} key={list["id"]}></TRowUnit>)


        return (<>{trowlist}</>

        )
    }
    return (
        <div className="all-center" style={{ backgroundColor: "rgba(245,245,245,1)" }}>
            <div className="d-flex flex-column gap-3" style={{ width: "80vw", height: "100vh", paddingBottom: "10vh" }} >
                <div style={{ height: 50 }}></div>
                <div className="font1Custom">WaitList</div>
                <div className="d-flex justify-content-end font2Custom">
                    {(handlestate["fetch"]) ? (<Loading></Loading>) : (<Button onClick={() => handleClick()} variant="outline-primary">更新列表</Button>)}
                </div>

                {(handlestate["fetch"]) ?
                   <></>
                    :
                    <div style={{ overflow: "scroll", boxShadow: "0 0 10px 2px rgba(0,0,0,.2)" }}>
                        <Table striped bordered hover style={{ textAlign: "center", verticalAlign: "bottom", }}>
                            <thead style={{ textWrap: "nowrap" }}>
                                <tr>
                                    <th>ID #</th>
                                    <th>User email</th>
                                    <th>Occupation</th>
                                    <th>Created time</th>
                                    <th>Other Info</th>
                                    <th>Status</th>
                                    <th>operations</th>
                                </tr>
                            </thead>
                            <tbody >

                                <TRow data={data}></TRow>

                            </tbody>
                        </Table>
                    </div>
                }



            </div>
        </div>
    )
}