import React, { useState, Dispatch } from 'react';
import majorIcon from "../assets/Major.png";
import locationIcon from "../assets/Location.png";
import arrowLeft from "../assets/ArrowLeft.png";
import majors from "../assets/MajorData"; // Import majors data
import { getTutors } from "../api/tutor"
import '../styles/TutorFilter.scss';

type answers = {
    0: string[];
    1: string[];
}

const countries = ["USA", "Canada", "Austrilia", "Europe", "UK", "HK", "Singapore"];
const iconList = [majorIcon, locationIcon];

interface TutorFilterProps {
    isTutorOpen: boolean
    setTutorList: React.Dispatch<React.SetStateAction<any[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    reloading: boolean;
    setReloading: React.Dispatch<React.SetStateAction<boolean>>;

}

const TutorFilter: React.FC<TutorFilterProps> = ({ isTutorOpen, setTutorList, setLoading, reloading, setReloading}) => {
    const [clickIndex, setClickIndex] = useState<number | null>(null); // Track the index of the clicked question
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]); // Track selected filters
    const [answers, setAnswers] = useState<answers>(
        {
            0: [],
            1: [],
        });

    //const navigate = useNavigate();

    const handleClick = (idx: number): void => {
        setClickIndex((prevIndex) => (prevIndex === idx ? null : idx));
    };

    const handleDelete = (answer: string, event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent triggering the parent expand event
        const idx = Object.entries(answers).findIndex(([, choices]) => choices.includes(answer));
        setAnswers((prevAnswers: any) => {
            const updatedAnswers = prevAnswers[idx].filter((a: any) => a !== answer);
            return { ...prevAnswers, [idx]: updatedAnswers };
        });
        setSelectedFilters((prevFilters) => prevFilters.filter((f: any) => f !== answer));
        console.log(idx);
    };

    const handleClearFilters = () => {
        setSelectedFilters([]);
        setReloading(!reloading)
        setAnswers({
            0: [],
            1: [],
        })
    };

    const handleAnswer = (idx: number, answer: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setAnswers((prevAnswers: any) => {
            // Check for duplicate answer
            if (prevAnswers[idx].includes(answer)) {
                //alert('This choice is already in the list.');
                return prevAnswers; // Return the same state if duplicate
            }
            // Add the new answer if not a duplicate
            return { ...prevAnswers, [idx]: [...prevAnswers[idx], answer] };
        });
        setSelectedFilters((prevFilters) => {
            if (prevFilters.includes(answer)) {
                alert('This choice is already in the list.');
                return prevFilters; // Return the same state if duplicate
            }
            return [...prevFilters, answer];
        });
    };



    const handleSubmit = async () => {
        try {
            // 这里的返回类型为 Tutor 数组，确保类型安全
            setLoading(true)
            const response:any = await getTutors(answers[0], answers[1]);
            console.log(response);
            if (response.status === 200) {
                const result = response.data;
                if (result.data && Array.isArray(result.data) && result.data.length !== 0) {
                    setTutorList(result.data); // 这里直接将数组传递给 setTutorList
                }else if(response.data.includes('You need to enable JavaScript to run this app')){
                    alert('Please login and retry');
                    setTutorList([]);
                }else {   
                    setTutorList([]); // 确保在没有数据时设置为空数组
                }}
            // } else if (response.status === 302 ||response.status === 304) {
            //     alert('Please login first')
            //     setTutorList([])
            // }
        } catch (error: any) {
            if (error.response.status === 304||error.response.status === 405) {
                alert('session expired, please login again')
            }else if(error.response.status === 404){
                setTutorList([])
            }
            else{
                alert('Server error, please contact for help')
            }
            setTutorList([]); // 错误情况下设置为空数组
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="tutor-filter-container">
            <div className="tutor-filter-header">
                Selected Filter
                {selectedFilters.length > 0 && (
                    <button className="tutor-clear-filters" onClick={handleClearFilters}>Clear Filter</button>)}
            </div>
            <div className="tutor-filter-selected">
                {selectedFilters.map((filter, index) => (
                    <ul className="tutor-filter-selected-item" key={index} onClick={(event) => handleDelete(filter, event)}>
                        <li>{filter}</li>
                    </ul>))}
            </div>
            {questions.map((question, index) => (
                <div
                    onClick={() => handleClick(index)}
                    key={index}
                    className={`tutor-question-element ${clickIndex === index ? 'expanded' : ''}`}
                >
                    <img className={`tutor-question-image ${clickIndex === index ? 'expanded' : ''}`} src={iconList[index]} alt="img" />
                    <h3 className="tutor-question-text">{question.text}</h3>
                    <img className={`tutor-custom-arrow ${clickIndex === index ? 'rotated' : ''}`} src={arrowLeft} alt="img" />
                    <div className='tutor-list-holder'>
                        {index === 0 && clickIndex === 0 && (
                            <ul className="tutor-target_major_main_list">
                                {Object.entries(majors).map(([key, major]) => (
                                    <li onClick={(event) => handleAnswer(0, major.key, event)} key={key}>
                                        {major.key}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {clickIndex === 1 && index === 1 && (
                            <ul className="tutor-target_major_main_list">
                                {countries.map((country, index) => (
                                    <li onClick={(event) => handleAnswer(1, country as string, event)}
                                        key={`countries_${index}`}>
                                        {country}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            ))}
            <button className='tutor-apply_filter_btn' disabled={selectedFilters.length === 0 || isTutorOpen} onClick={handleSubmit}>Apply Filter</button>
        </div>
    );
};


const questions = [
    {
        text: 'Major',
    },
    {
        text: 'Specialised Country',
    }
];

export default TutorFilter;