import React, { useState, useEffect, Suspense } from 'react';
import Cookies from 'js-cookie';
import HeadNav from '../componentsNew/HeadNav';
import Loading from '../componentsNew/Loading'
import Filter from '../components/ProgramFilter';
import ProgramDetail from '../components/ProgramDetail';
import data1 from '../assets/program_data/1-connected-electronic-and-photonic-systems-mres.json'
import data2 from '../assets/program_data/1-msc-gender,-media-and-culture.json'
import data3 from '../assets/program_data/1-nursing-science.json'
import data4 from '../assets/program_data/1-translation.json'
import "../styles/ViewProgram.scss"
import { loadEnvFile } from 'process';

interface data {
  uni_name: string;
  course_code: string;
  course_name: string;
  course_mode: string;
  course_duration: string;
  course_qualification: string;
  university_ranking: string;
  major_main: string;
  major_sub: string;
  career_main: string;
  career_sub: string;
  course_overview: string;
  course_entry_requirements: string[];
  course_career: string[];
  course_learning: string[];
  course_country: string;
  reference_link: string;
  updated_date: string;
  icon_url: string;
  is_selected: boolean;
}


const ViewPrograms: React.FC = () => {


  const [programs, setPrograms] = useState<data[]>([data1, data2, data3, data4]);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <HeadNav />
      <div id="view-programs-container">
        <div id="filter-section">
          <Filter setPrograms={setPrograms} setLoading={setLoading}/>
        </div>
        <div id="program-detail-section">
          {loading ? (<Loading />): Array.isArray(programs) && programs.length > 0 ? (
            programs.map((data) => (
              <ProgramDetail data={data} />
            ))
          ) : (
            <p className='text-xl text-center'>No programs available</p>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewPrograms;