import React, { useEffect } from 'react';
import '../styles/StartSubmitPopup.scss';
import { Link } from 'react-router-dom';
import { set } from 'lodash';

interface StartSubmitPopupProps {
    handleSubmit: () => void;
    restReports: number;
    membership: string;
    setIsStartSubmitPopupOpen: (isStartPopupOpen: boolean) => void;
}



export const StartSubmitPopup: React.FC<StartSubmitPopupProps> = ({ handleSubmit, restReports, membership, setIsStartSubmitPopupOpen }) => {

    const returnHandler = () => {
        setIsStartSubmitPopupOpen(false);
    }

    return (
        <div className="start_popup">
            <div className="start_popup_container">
                <div className="start_popup_content">
                    <div className="start_popup_content_title">
                        Confirm your submission
                    </div>
                    <div className="start_popup_content_btns" style={{ padding: "0 30px" }}>
                        <button onClick={returnHandler} className="back-button">Quit and not save</button>
                        <button onClick={handleSubmit} className="submit-button">Continue</button>
                    </div>
                </div>
            </div>

        </div>
    );
};
