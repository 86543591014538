import React, { useState } from 'react';
import wishlist from "../assets/Wishlist_large.png";
import logo from '../assets/subjects_icons/Natural Sciences & Mathematics.png';
import wishlist_fill from '../assets/ApplyU_ceshi_Icon_bookmark-blue.png';
import { addToWishList } from '../api/program';
import '../styles/programDetail.scss';
import { error } from 'console';

interface ProgramDetailProps {
    data: data;
}

type data = {
    uni_name: string;
    course_code: string;
    course_name: string;
    course_mode: string;
    course_duration: string;
    course_qualification: string;
    university_ranking: string;
    major_main: string;
    major_sub: string;
    career_main: string;
    career_sub: string;
    course_overview: string;
    course_entry_requirements: string[];
    course_career: string[];
    course_learning: string[];
    course_country: string;
    reference_link: string;
    updated_date: string;
    icon_url: string;
    is_selected: boolean;
}

const ProgramDetail: React.FC<ProgramDetailProps> = ({ data }) => {
    const [expanded, setExpanded] = useState(false);
    const [picked, setPicked] = useState(data.is_selected);


    const handleClick = async () => {
        if (picked === false) {
            try {
                // Add code to handle adding to wishlist here
                const response = await addToWishList(data.course_code);
                console.log(response)
                if (response.code === 200) {
                    setPicked(true);
                }
            } catch (error: any) {
                console.error(error)
                if (error.response.status === 400) {
                    alert("Program is already added to wishlist");
                }
                else if (error.response.status === 302){
                    alert("Please login and retry");
                }else{
                    alert("Unknown error, please contact support for help");
                }
            }
        }
    }


    const toggleExpand = () => {
        setExpanded(!expanded);
    };


    return (
        <div className="program-card">
            <img src={picked ? wishlist_fill : wishlist} alt="Wishlist Icon" className="wishlist-icon" onClick={handleClick} />
            <div className="header">
                <img src={data.icon_url === '' ? logo : data.icon_url} alt="School Logo" className="logo" />
                <div className="school-info">
                    <h2 className="school-name">{data.uni_name}</h2>
                    <p className="location">{data.course_country}</p>
                </div>
            </div>
            <div className='program-title-line'>
            <h1 className="program-title">{data.course_name}</h1>
            <a href={data.reference_link} className="program-link" target="_blank">Program Link</a>
            </div>
            <div className="tags">
                <span className="tag">University Ranking: {data.university_ranking}</span>
                <span className="tag">{data.course_mode}</span>
            </div>
            <button className="expand-toggle" onClick={toggleExpand}>
                {expanded ? 'See less' : 'See more'}
            </button>
            {expanded && (
                <>
                    <section className="overview">
                        <h2>Overview</h2>
                        <p>
                            {data.course_overview}
                        </p>
                    </section>
                    <div className="details">
                        <section className="learning">
                            <h2>Learning & Academics</h2>
                            <ul>
                                {data.course_learning.map((item, index) => (
                                    <li>{item}</li>
                                ))}
                            </ul>
                        </section>
                        <section className="career">
                            <h2>Career</h2>
                            <ul>
                                {data.course_career.map((item, index) => (
                                    <li>{item}</li>
                                ))}
                            </ul>
                        </section>
                    </div>
                    <section className="admission">
                        <h2>Admission</h2>
                        <ol>
                            {data.course_entry_requirements.map((item, index) => (
                                <li>{item}</li>
                            ))}
                        </ol>
                    </section>
                </>
            )}
        </div>
    );
};

export default ProgramDetail;