import axios from 'axios';

const env = "test";
let API_URL = process.env.REACT_APP_API_URL;


const AUTH_HEADERS = {

    'Content-Type': 'application/json',
}

// interface program{
//     course_code: string;
//     course_name: string;
//     course_mode: string;
//     course_duration: string;
//     course_qualification: string;
//     university_ranking: string;
//     major_main: string;
//     major_sub: string;
//     career_main: string;
//     career_sub: string;
//     course_overview: string;
//     course_entry_requirements: string[];
//     course_career: string[];
//     course_learning: string[];
//     course_country: string;
//     reference_link: string;
//     updated_date: string;
// }

export const getPrograms = async (major:string[],region:string[],ranking:number[],career:string[])  => {
    try{
        const body = {
            major:major,
            region:region,
            university_ranking:ranking,
            career:career
        }
        const response = await axios.post(`${API_URL}/api/fetch-programs/`,body,{
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials:true
        });
        return response.data;
    }catch(error){
        console.error('Failed to fetch programs:', error);
        throw error;
    }
}

export const addToWishList = async (wishlistId:string) => {
    try{
        const body = {
            wishlist_item_id:wishlistId
        }
        const response:any = await axios.post(`${API_URL}/api/add-wishlist-item/`,body,{
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials:true
        });
        return response.data;
    }catch(error){
        throw error;
    }
}