import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { login } from '../api/Users'; // 假设这些是你的 API 调用

interface AuthState {
    isAuthenticated: boolean;
    error: string | null;
}

const initialState: AuthState = {
    isAuthenticated: false, // 初始认证状态默认为未认证
    error: null,
};

// 用户登录的异步操作
export const loginUser = createAsyncThunk<boolean, { email: string; password: string }, { rejectValue: string }>(
    'auth/loginUser',
    async (credentials, { rejectWithValue }) => {
        try {
            const response = await login(credentials); // 调用实际的登录 API
            if (response.code === 200) {
                // 登录成功
                return true;
            } else {
                throw new Error('Invalid username or password');
            }
        } catch (error: any) {
            return rejectWithValue(error.message || 'Invalid username or password');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated(state) {
            state.isAuthenticated = true;
        },
        clearAuth(state) {
            state.isAuthenticated = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state) => {
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.error = action.payload || 'Login failed';
            })
    },
});

export const { setAuthenticated, clearAuth } = authSlice.actions;
export default authSlice.reducer;
