import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { getWishlist, deleteWishlistItem } from '../api/wishlist';
import { setWishlist } from '../store/wishlistSlice';
import '../styles/Wishlist.scss';
import { ProgramItem } from '../store/wishlistSlice';


const RightWishlist: React.FC<{ userId: string }> = ({ userId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const wishlist = useSelector((state: RootState) => state.wishlist.items);

    useEffect(() => {
        const fetchWishlist = async () => {
            try {
                const result = await getWishlist();
                dispatch(setWishlist(result as any[]));
            } catch (error) {
                console.error('Error fetching wishlist', error);
            }
        };
        fetchWishlist();
    }, [userId, dispatch]);

    const handleDeleteClick = async (courseCode: string) => {
        try {
            await deleteWishlistItem(courseCode);
            const updatedWishlist = wishlist.filter(item => item.wishlistId !== courseCode);
            dispatch(setWishlist(updatedWishlist));
        } catch (error) {
            console.error('Error deleting wishlist item', error);
        }
    };

    return (
        <div>
            {wishlist.length === 0 ? (
                <></>
            ) : (
                <div className="wishlist" style={{ maxHeight: 'calc(100vh - 96px - 125px)', overflow: 'scroll', paddingRight: '5px' }}>
                    <div className="wishlist_items" style={{ paddingRight: '5px' }}>
                        {wishlist.map((item: ProgramItem, index: number) => (
                            <div key={index} className='wishlist_item relative' >
                                <button className='absolute right-5 top-3' onClick={() => handleDeleteClick(item.wishlistId)}>
                                    &times;
                                </button>
                                <div className="wishlist_item_left">
                                    <div className="wishlist_item_title">
                                        <img src={item["uni_logo_path"]} />
                                        <div style={{ fontSize: "14px", fontWeight: "500", justifyContent: "center" }}>{item["school"]}</div>

                                    </div>
                                    <div className="wishlist_item_program">
                                        {item["program"]}
                                    </div>
                                    <div className="wishlist_item_locations">
                                        <span>{item["location"]}</span>
                                        <span>{item["country"]}</span>
                                    </div>
                                </div>

                                {/* <div className="wishlist_item_right">
                                    {item["description"]}
                                </div> */}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div >
    );
};




export default RightWishlist;
