import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { isEmail } from 'validator';
import { addToWaitList } from "../api/Users";
import '../styles/Login.scss';


const WaitlistForm = () => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [occupation, setOccupation] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const validateEmail = (email: string): boolean => {
        if (!isEmail(email)) {
            setEmailError('Please enter a valid email address');
            return false;
        }
        setEmailError('');
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // 调用Waitlist API
        try {
            const response = await addToWaitList(email, occupation);
            if (response.status === 200) {
                alert("You have been added to waitlist successfully!")
            }
        } catch (error: any) {
            setError(error.response.data.msg);
        }
    };

    return (
        <div className="login-form-container">
            <h2 className="text-2xl font-bold mb-4">Join Our Waitlist</h2>
            <form onSubmit={handleSubmit} className='relative'>
                <div className="mb-3">
                    <label className="block mb-1">Email</label>
                    <input
                        type="text"
                        className={`login-input ${emailError ? 'border-red-500' : ''}`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => validateEmail(email)}
                        required
                    />
                    {emailError && <p className="text-red-500 text-sm my-1">{emailError}</p>}
                </div >
                <div className="mb-6">
                    <label className="block mb-1">Occupation</label>
                    <select value={occupation} onChange={(e) => setOccupation(e.target.value)} className="login-input w-full">
                        <option value="" selected disabled hidden></option>
                        <option value="Student">Student</option>
                        <option value="Professional">Professional</option>
                        <option value="Others">Others</option>
                    </select>
                </div>
                <button type="submit" className="w-full h-10 bg-blue-500 text-white font-bold p-2 rounded-lg hover:bg-blue-600 mb-4 disabled:bg-[#AAAAAA]" disabled={email === "" || occupation == "" || emailError !== ""}>
                    Join waitlist!
                </button>
                {error && <p className="text-red-500">{error}</p>}
                <div className="flex justify-end items-center">
                    <p className="relative items-end text-sm text-gray-500">Already have an account? Go to <a className="underline hover:text-blue-500" href="/login">Log in</a></p>
                </div>
            </form>
        </div>
    )
}

export default WaitlistForm;