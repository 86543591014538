import React,{useState,useEffect} from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';
// import "../styles/Navbar.scss"
import logo from '../assets/ApplyU_logo.png';
import {logout} from '../api/Users'
import write_logo from '../assets/navbar_write.png';
import write_logo_selected from '../assets/navbar_write_selected.png';
import program_logo from '../assets/navbar_program.png';
import program_logo_selected from '../assets/navbar_program_selected.png';
import user_logo from '../assets/navbar_user.png';
import diamond from "../assets/diamond.png";
import premiun_icon from "../assets/premium_diamond.png";
import exist_icon from "../assets/exit_icon.png";
import setting_logo from '../assets/navbar_setting.png';
import { getReportLimit } from '../api/reports';


const Navbar: React.FC  = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = React.useState(false); //This is for sign out

    //hover the user icon and show the sign out
    const handleMouseEnter = () => {
        setIsOpen(true);
    };
    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const [membership,setMembership] = React.useState('free');
    
    useEffect(() => {
        const remainingReports = async () => {
            try{
                const response = await getReportLimit();
                if(response.data.report_total_limit > 0 && response.code===200){
                    setMembership('premium')
                }
            }catch(error:any){
               return;
        }
    }

        remainingReports();
    },[])

    //handle Sign Out
    const navigate = useNavigate();
    const handleSignOut = async () => {
        try{
            const response = await logout();
            if(response.status === 200){
                alert("Sign Out Successfully");
                navigate('/login');
            }
        }catch(error){
            console.log(error);
            alert(error)
        }
    }

    return (
        <>
            <div className="navbar w-[110px] h-screen bg-[#fafafa] shadow-[0_0_4px_0_rgb(0,0,0,0.25)] flex flex-col justify-between items-center py-8 space-y-20">
                <nav className="navbar_select flex flex-col items-center space-y-2 text-[14px]">
                    <div className="navbar_select_logo">
                        <Link to="/"><img src={logo} alt="Logo" className="w-12 h-12 mb-[30px]"/></Link>
                    </div>
                    {/* <Link to="/write" className={`w-[66px] h-[66px] flex flex-col justify-center items-center ${location.pathname === '/write' ? 'text-[#287EFF] bg-[#EEF5FF] rounded-[13px]' : 'text-black'}`}>
                    <img src={location.pathname === '/write' ? write_logo_selected: write_logo} alt="Write-logo" />
                    <span className="">Write</span>
                </Link> */}
                    <Link to="/program" className={`w-[66px] h-[66px] flex flex-col justify-center items-center ${location.pathname === '/program' ? 'text-[#287EFF] bg-[#EEF5FF] rounded-[13px]' : 'text-black'}`}>
                        <img src={location.pathname === '/program' ? program_logo_selected : program_logo} alt="Program-logo" />
                        <span className="text-xs">Program</span>
                    </Link>
                </nav>
                <div className="navbar_profile flex flex-col items-center space-y-2">
                    <Link to='/payment'><div className="navbar_profile_user w-[35px] h-[28px] flex justify-center item-center">
                        <img src={membership === 'premium' ? premiun_icon : diamond} alt="premium icon" />
                    </div></Link>
                    <div className="navbar_profile_user w-[110px] h-[66px] flex justify-center item-center"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <img className='hover:cursor-pointer w-[66px] h-[66px]' src={user_logo} alt="User-logo" />
                    </div>
                    {/* <div className="navbar_profile_setting w-[66px] h-[66px] flex justify-center item-center">
                    <img src={setting_logo} alt="Setting-logo" />
                </div> */}
                </div>
            </div>
            {isOpen &&
                <div className='absolute left-[96px] bottom-[40px] flex flex-row space-x-3 items-center justify-center w-[150px] h-[51px] bg-[#FAFAFA] rounded-lg shadow-[0_0_4px_0_rgb(0,0,0,0.25)] hover:underline hover:cursor-pointer'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleSignOut}>
                    <img className='w-[24px] h-[24px]' src={exist_icon} alt="exit icon" />
                    <span className='font-sans text-[14px] font-normal leading-[14.97px] tracking-[-0.01em] text-center'>Sign Out</span>
                </div>}
        </>
    );
};

export default Navbar;
