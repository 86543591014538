
// src/store/analysisSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface sub_fields {
  [key: string]: string[];
}

export interface AnalysisDetail {
  // question: string;
  // answer: string;
  score: number;
  sub_fields: sub_fields;
}

export interface ProjectAnalysis {
  id: number;
  school: string;
  imgUrl: string;
  programId: string;
  program: string;
  overallMatching: number;
  matching: AnalysisDetail;
  academic: AnalysisDetail;
  discipline: AnalysisDetail;
  skills: AnalysisDetail;
  project: AnalysisDetail;
  work: AnalysisDetail;
  career: AnalysisDetail;
}

interface AnalysisState {
  results: ProjectAnalysis[];
}

const initialState: AnalysisState = {
  results: [],
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setAnalysisResults(state, action: PayloadAction<ProjectAnalysis[]>) {
      state.results = action.payload;
    },
  },
});

export const { setAnalysisResults } = analysisSlice.actions;
export default analysisSlice.reducer;
