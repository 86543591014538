import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { getReports } from '../api/reports';
import { setReports } from '../store/reportsSlice';
import { Report } from '../store/reportsSlice';
import Loading from '../componentsNew/Loading';
import reportIcon from '../assets/report-icon.png'

interface ReportsProps {
    onReportClick: (reportId: string) => void;
}

const Reports: React.FC<ReportsProps> = ({ onReportClick }) => {
    const [loading,setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const reports = useSelector((state: RootState) => state.reports.items);

    useEffect(() => {
        const fetchReports = async () => {
            try {
                setLoading(true);
                const result = await getReports();
                dispatch(setReports(result as any[]));
            } catch (error) {
                //console.error('Error fetching reports', error);
            }finally{
                setLoading(false);
            }
        };

        fetchReports();
    }, [dispatch]);

    return (
        <div>
            {loading ? <Loading/>:
            reports.length === 0 ? (
                <div className="page1_left_guide">
                    <div className="page1_left_guide_title">
                        <span>3 steps to get your program guide:</span>
                        {/* <span style={{ fontWeight: '400' }}>* You can get 3 reports each day.</span> */}
                    </div>
                    <div className="page1_left_guide_steps">
                        <div className="page1_left_guide_step">
                            <div className="page1_left_guide_step_num">1</div>
                            <div className="page1_left_guide_step_text">Summarize your experience</div>
                        </div>
                        <div className="page1_left_guide_step">
                            <div className="page1_left_guide_step_num">2</div>
                            <div className="page1_left_guide_step_text">Choose your dream schools (optional)</div>
                        </div>
                        <div className="page1_left_guide_step">
                            <div className="page1_left_guide_step_num">3</div>
                            <div className="page1_left_guide_step_text">Personalize your future path</div>
                        </div>
                    </div>
                </div>) : (
                <div className="page1_left_reports">
                    {reports.map((report: Report) => (
                        <div className="page1_left_report" key={report.guide_id} onClick={() => onReportClick(report.guide_id)}>
                            <div className="page1_left_report_icon">
                                <img src={reportIcon} alt="report" />
                            </div>
                            <div className="page1_left_report_name">
                                {report.guide_id}
                            </div>
                            <div className="page1_left_report_time">
                                Created <br/>{report.guide_create_time}
                            </div>
                        </div>
                    ))}
                </div>
            )
            }
        </div>
    );
};

export default Reports;
