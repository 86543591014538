import { useNavigate } from "react-router-dom";
import { GetToServer } from "./Network";
import { useHistory } from "react-router-dom";

export default function HandlePageClick(id) {
    switch (id) {

        case 'headnav_programSearch': LinkToPage("/viewprograms"); break;
        case 'headnav_personalTutor': LinkToPage("/tutor"); break;
        case 'headnav_pricing': LinkToPage("/pricing"); break;
        case 'headnav_login': LinkToPage("/login"); break;
        case 'headnav_startApply': LinkToPage("/program"); break;

        case 'cardBig_signUp': LinkToPage("/signup"); break;

        case "footnav_programguide": LinkToPage("/program"); break;
        case "footnav_findprogram": LinkToPage("/viewprograms"); break;
        case "footnav_findtutor": LinkToPage("/tutor"); break;
        case "footnav_Pricing": LinkToPage("/pricing"); break;
        case "footnav_help&contactus": LinkToPage("/"); break;

        case "footnav_application": LinkToPage(id); break;
        case "footnav_study": LinkToPage(id); break;
        case "footnav_career": LinkToPage(id); break;
        case "footnav_dashboard": LinkToPage(id); break;
        case "footnav_resources1": LinkToPage(id); break;
        case "footnav_resources2": LinkToPage(id); break;
        case "footnav_pricing": LinkToPage(id); break;
        case "footnav_about": LinkToPage(id); break;
        case "footnav_contact": LinkToPage(id); break;

        case "footnav_privacy": LinkToPage("/privacypolicy"); break;
        case "footnav_termsOfService": LinkToPage("/termsofservice"); break;
        case "footnav_linkedin": LinkToPage("https://www.linkedin.com/company/applyu-ai/about/"); break;
        case "footnav_youtube": LinkToPage(id); break;



        default: console.log("no match")
            break;
    }

}

function LinkToPage(pageurl) {
    const mobilematch = window.matchMedia("(max-width:1000px)").matches
   
    function linkto(url) {
        window.location.href = url
    }
    if (mobilematch) {
        alert("please operate on wider interface")
    }
    else { linkto(pageurl) }

}