import React, { ReactNode } from 'react';
import Navbar from '../components/Navbar';

interface DashboardLayoutProps {
    children: ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {



    return (
        <div className="flex" style={{ width: '100vw' }}>
            <Navbar />
            <div className="flex-1">
                {children}
            </div>
        </div>
    );
};

export default DashboardLayout;