import React, { useEffect } from 'react';
import '../styles/StartSubmitPopup.scss';
import { unlockReport } from '../api/results'
import { Navigate } from 'react-router-dom';
import { set } from 'lodash';

interface unlockPopup {
    setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedReportId: string|null;
}



export const UnlockPopup: React.FC<unlockPopup> = ({setIsPopupOpen,selectedReportId}) => {

    const returnHandler = () => {
        setIsPopupOpen(false);
    }

    const handleUnlockClick = async (reportid:string|null) => {
        console.log(reportid)
        try{
            const response = await unlockReport(reportid);
            if(response.code === 200){
                Navigate({to: '/program'});
            }else{
                throw new Error(response.msg);
            }
        }catch(error){
            alert(error)
        }

    }


    return (
        <div className="start_popup">
            <div className="start_popup_container">
                <div className="start_popup_content">
                    <div className="start_popup_content_title">
                        Confirm Unlock This Report
                    </div>
                    <div className="start_popup_content_btns" style={{ padding: "0 40px" }}>
                        <button onClick={returnHandler} className="back-button">Cancel</button>
                        <button onClick={()=>handleUnlockClick(selectedReportId)} className="submit-button">Continue</button>
                    </div>
                </div>
            </div>

        </div>
    );
};