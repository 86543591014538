import { Navbar, Nav, Container, NavDropdown, Button, Stack, Collapse, Fade, Overlay, Popover } from "react-bootstrap";
import { check_login } from '../api/Users'
import './styles/Fonts.css'
import './styles/buttons.css'
import './styles/layout.css'

import { useEffect, useState, useTransition } from "react";
import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css'
import HandlePageClick from "./Link/PageClick";
import 'bootstrap/dist/css/bootstrap.min.css';


import { Link, useNavigate } from 'react-router-dom';

//test
function handleClick() { }


const flyoutContentFont = {
  fontWeight: 600, fontSize: 16
}
const flyoutTitleFont = {

  fontWeight: 600, fontSize: 16, color: "rgba(170, 170, 170, 1)",
  // textTransform: "uppercase"
}


//定义flyoutBtn内容
const BtnServicesAppPre = {
  contentText: {
    title: "Application Preparation",
    text1: "Write with AI",
    text2: "Personalized Program Guide",
    text3: ""
  }

}
const BtnServicesStudySup = {
  contentText: {
    title: "Study Support",
    text1: "Ready...",
    text2: "",
    text3: ""
  }

}
const BtnServicesCareerPlan = {
  contentText: {
    title: "Career Planner",
    text1: "Ready...",
    text2: "",
    text3: ""
  }

}
const BtnResourcesProSearch = {
  contentText: {
    title: <><div>Find Your Program:<br />Explore Over</div></>,
    text1: "10,000 Master's Programs",
    text2: "",
    text3: ""
  }
}
const BtnResourcesPersonTutor = {
  contentText: {
    title: "Personal Tutor",
    text1: "Get Application Help from",
    text2: "Top University Experts",
    text3: ""
  }
}
//flybtn组件
function FlyoutBtn({ contentText, open, as: Component, to, handleClick }) {
  return (<>
    <Button onClick={() => handleClick()} className="flyoutButton d-flex align-items-start" style={{ backgroundColor: "rgba(250, 250, 250, 1)" }}>
      <Stack gap={3} style={{ textAlign: "left", marginLeft: 20, marginTop: 10 }}>
        <div style={flyoutContentFont}>{contentText.title}</div>
        <div style={flyoutTitleFont}>
          <div >{contentText.text1}</div>
          <div>{contentText.text2}</div>
          <div>{contentText.text3}</div>
        </div>

      </Stack>

    </Button>
  </>

  );

}
const iconDown = "bi bi-chevron-down"
const iconUp = "bi bi-chevron-up"
// 点击services弹出按钮组
// todo: change the link to the correct one
function ServicesButtons() {
  return (
    <>
      <FlyoutBtn handleClick={() => HandlePageClick("")} contentText={BtnServicesAppPre.contentText}></FlyoutBtn>
      <FlyoutBtn as={Link} to="/viewprograms" contentText={BtnServicesStudySup.contentText}></FlyoutBtn>
      <FlyoutBtn as={Link} to="/viewprograms" contentText={BtnServicesCareerPlan.contentText}></FlyoutBtn>
    </>

  )
}

//点击弹出Resources按钮组
function ResourcesButtons() {
  return (
    <>
      <FlyoutBtn handleClick={() => HandlePageClick("headnav_programSearch")} contentText={BtnResourcesProSearch.contentText}></FlyoutBtn>
      <FlyoutBtn handleClick={() => HandlePageClick("headnav_personalTutor")} contentText={BtnResourcesPersonTutor.contentText}></FlyoutBtn>
    </>

  )
}
function FlyboxShadow(open) {
  if (open) {
    return { boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.22)" }
  }
  return null



}




//导航栏主组件
export default function HeadNav() {

  const [isLogIn, setIsLogIn] = useState(false);
  const currentPath = window.location.pathname;
  const checkList = ['/', '/tutor', '/privacypolicy', '/termsofservice']


  useEffect(() => {
    // Create an asynchronous function inside the useEffect
    const verifyLogin = async () => {
      try {
        const response = await check_login();
        if (response.data.code === 200) {
          setIsLogIn(true);
        }
      } catch (error) {
        console.log(error);
        setIsLogIn(false);
      }
    };

    // Call the asynchronous function
    if (checkList.includes(currentPath)) {
      verifyLogin();
    } else {
      setIsLogIn(true);
    }
  }, []);


  //获取全局变量
  const MAIN_WIDTH = 1300
  const FLYOUT_COLOR = "rgba(250, 250, 250, 1)"

  //定义flyoutbox状态
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  //0为services，1为Resources
  //点击监听
  function HandleEnter(index) {
    if (index == 0) { setTabIndex(0) };
    if (index == 1) { setTabIndex(1) };

    //if (index == tabIndex) setOpen(!open);
    if (index >= 0)
      setOpen(true);
    else setOpen(false);

  }


  return (
    <div onMouseLeave={() => HandleEnter(-1)} style={{ position: "sticky", top: 0, zIndex: 1000, width: "100%" }}>
      {//navbar
      }
      <Navbar className=" justify-content-center" style={{ paddingLeft:30,width: "100%", backgroundColor: "white", borderBottom: "3px solid rgba(234, 234, 234, 1)", borderColor: "rgba(234, 234, 234, 1)" }}>
        <div className="justify-content-center " style={{ width: "100%",maxWidth:MAIN_WIDTH }}>
          <Nav className="gap-4 mobile-container" style={{ alignItems: "center", width: "95%", height: 93 }}>
            {//title
            }
            <Navbar.Brand as={Link} to="/" style={{ minWidth: 80 }}><img src={require("./img/ApplyU-logo.png")} width={140} height={32} /></Navbar.Brand>
            {//nav button group
            }
            <Nav className="justify-content-between gap-4 NavTitleFont mobile-item-l" >
              {/* <Button className="transparentButton NavTitleFont" id="basic-nav-dropdown" onMouseEnter={() => HandleEnter(0)} aria-controls="flyoutBox" aria-expanded={open}>Services <i className={open & tabIndex == 0 ? iconUp : iconDown}></i></Button> */}
              <Button className="transparentButton NavTitleFont" id="basic-nav-dropdown" onMouseEnter={() => HandleEnter(1)}>Resources<i className={open & tabIndex == 1 ? iconUp : iconDown}></i></Button>

              <Nav.Link onClick={() => HandlePageClick("headnav_pricing")}>Pricing & Plan</Nav.Link>
            </Nav>
            {//log unit
            }
            <Nav className="justify-content-end flex-grow-1 gap-4 col " style={{ alignItems: "center", height: 50, whiteSpace: "nowrap" }}>
              <Nav.Link as={Link} to="/login" className="fontBlackBold mobile-item-l" style={{ textAlign: "center", color: "black", width: 80, fontWeight: 700, visibility: isLogIn ? "hidden" : 'visible' }}>Log in</Nav.Link>
              <Button onClick={() => HandlePageClick("headnav_startApply")} className="blueButton d-flex justify-content-center align-items-center" style={{ textAlign: "center", borderRadius: 100, maxWidth: 180, minWidth: 80, width: "100%", height: 50, color: "white", fontSize: 16, fontWeight: 500 }}><div> <span className="mobile-item-s">Start</span> <span className="mobile-item-l">My</span> ApplyU</div></Button>
            </Nav>
          </Nav>
        </div>
      </Navbar>

      {//flyoutBox
      }

      <Collapse in={open} dimension={"height"} >

        <div className=" justify-content-center mobile-container" style={{ boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.22)", position: "absolute", width: "100%", backgroundColor: FLYOUT_COLOR, zIndex: 1000 }} >

          <div className=" justify-content-center mobile-item-l" style={{ boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.22)", position: "absolute", width: "100%", backgroundColor: FLYOUT_COLOR, zIndex: 1000 }} >


            <div className="d-flex justify-content-center align-items-top " style={{ width: "100%", height: 200, display: open ? "inline" : "none" }}>
              <div  >
                <div className="d-flex justify-content-start gap-5 column" style={{ width: MAIN_WIDTH, marginTop: 20, textWrap: "nowrap" }}>
                  <ResourcesButtons />
                </div>
              </div>
            </div>



          </div>

        </div>



      </Collapse>



    </div>


  )
}