import { Form, Button, ListGroup } from "react-bootstrap"
import "./styles/Fonts.css"
import "./styles/buttons.css"
import "./styles/layout.css"
import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css'
import { useState, useEffect, useRef, React, useContext } from "react"
import jsonData from "./json/combined_names_en_categorized.json"
import 'bootstrap/dist/css/bootstrap.min.css';
import { createContext } from 'react';
import Loading from "./Loading"
//整个表单-FormGroup
//单个表单单元-FormUnitName
import ScrollSpy from 'react-scrollspy-navigation';


const inputBoxStyle = { border: "2px solid rgba(170, 170, 170, .6)" }
const inputBox1 = { width: 525 }
const inputBox2 = { width: 250 }
const inputBox3 = { minHeight: 80, width: 800, }
const inputBox4 = { width: 800 }
const API_URL = process.env.REACT_APP_API_URL;

export default function CVForm({ onSubmit }) {
    //0:未fill，-1：fill提交中
    const [fillinStatus, setFillinStatus] = useState(0)

    const [menuIndex, setMenuIndex] = useState(0)
    const [formNum, setFormNum] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1])


    //获取表格数据后调用该函数
    function handleAutoFillFormClick() {


        const file = document.querySelector("#cvparsefile")

        var form = new FormData()
        if (!file.files[0]) { alert("please choose CV"); return; }
        form.append("resumeFile", file.files[0])
        const url = `${API_URL}/api/submit-resume-parse/`
        // FillStatusContext=createContext(-1)
        setFillinStatus(-1)
        // const url = "http://127.0.0.1:8000/api/submit-resume-parse/"

        fetch(url, {
            method: "POST",
            // headers:{"Content-Type":"multipart/form-data"},
            body: form,
            credentials: "include"

        })
            .then(
                (re) => re.json()
            )
            .then(
                (re) => {
                    console.log(re)
                    var fd = re.data
                    console.log(JSON.stringify(fd))
                    AutoFillForm(fd)
                }

            )
            .catch((error) => {
                console.error(error)
                AutoFillForm()

            }


            )
        // AutoFillForm()

    }

    function AutoFillForm(data = null) {


        var formdata = { "education_section": { "0": { "School": "Aalto University (Finland)", "Degree": "1", "Degree_Type": "2", "Graduation_Date": "2024-02", "GPA": "d", "Avg_score": "2", "Score_scale": "4" }, "1": { "School": "Aalto University (Finland)", "Degree": "1", "Degree_Type": "2", "Graduation_Date": "2024-02", "GPA": "d", "Avg_score": "2", "Score_scale": "4" } }, "module_section": { "0": { "Name": "sssss" }, "1": { "Name": "sssss" } }, "work_section": { "0": { "Company": "sss", "Type": "1", "Position": "xx1", "Location": "", "Start_date": "", "End_date": "", "Description": "ddddd" } }, "project_section": { "0": { "Project_name": "ddd", "Project_type": "2", "Start_date": "", "End_date": "", "Description": "11111" } }, "skill_section": { "0": { "Skill_name": "s" } }, "certification_section": { "0": { "certificate_name": "ghfhfhf", "certificate_authority": "1422424", "certificate_date": "2024-04" } }, "award_section": { "0": { "award_name": "111", "awarder": "111", "date": "2024-03" } }, "publication_section": { "0": { "publication_name": "424", "publication_url": "14141", "date": "2024-03" } }, "volunteer_section": { "0": { "Organization": "333", "Position": "41414", "Location": "414141", "Start_date": "2024-07", "End_date": "2024-02", "Description": "ss" } } }
        if (data) { formdata = data }
        else { setFillinStatus(0); alert("cv parse failure"); return }
        function getName(ID) {
            switch (ID) {
                default: return "default"
                case "education_section": return "0"
                case "module_section": return "1"
                case "work_section": return "2"
                case "project_section": return "3"
                case "skill_section": return "4"
                case "certification_section": return "5"
                case "award_section": return "6"
                case "publication_section": return "7"
                case "volunteer_section": return "8"
            }
        }
        //设置数量      
        var NewformNum = [1, 1, 1, 1, 1, 1, 1, 1]
        for (var typeKey in formdata) {
            //section
            var typeID = typeKey
            for (var indexKey in formdata[typeKey]) {
                //"index"
                var indexID = indexKey
                //设置sectoin num
                var index = Number(getName(typeID))
                var num = Number(indexID) + 1
                NewformNum[index] = num
            }
        }
        setFormNum(NewformNum)

        var count = 0

        for (var typeKey in formdata) {
            //section
            var typeID = typeKey
            for (var indexKey in formdata[typeKey]) {
                //"index"
                var indexID = indexKey
                //设置sectoin num
                var index = Number(getName(typeID))
                var num = Number(indexID) + 1
                NewformNum[index] = num

                setFormNum(NewformNum)

                for (var key in formdata[typeKey][indexKey]) {
                    //key:value
                    var name = getName(typeID) + indexID + key
                    var value = formdata[typeKey][indexID][key]
                    // console.log(name + ":" + value)

                    count = count + 1;


                    (function (x, y) {

                        setTimeout(function () {
                            SetFormUnitValue(x, y);
                            SetStorage(x, y);

                        }, 200 * count);
                    })(name, value)
                }

            }
        }
        //取消loading状态
        (function (x) {
            setTimeout(function () {
                setFillinStatus(0);
                alert("Autofill finished")
            }, 200 * x)
        })(count)
    }

    function SetFormUnitValue(id = null, value) {
        var formunit = document.getElementsByName(id)

        if (formunit[0]) { formunit[0].focus(); formunit[0].value = value; }
        return

    }

    function SchoolMatch(name) {
        //获取列表
        var list = getDataList()

        //正则匹配
        var str = name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        let reg = new RegExp(str)

        for (let x in list) {
            //返回值
            if (reg.test(list[x])) return list[x]
        }

        //未匹配
        return ""


    }
    function onLeaveSchoolInput(id, value) {
        console.log(`${id}`)
        var prevalue = document.getElementsByName(`${id}`)[0].value
        var nextvalue = SchoolMatch(value)
        var dom = document.getElementsByName(`${id}`)[0]


        if ((dom) && (prevalue != "")) {
            dom.value = nextvalue
            if (nextvalue == "") dom.placeholder = "school not found"
            else SetStorage(id, nextvalue)
        }



    }

    function SetStorage(name, value, id = 0) {
        var key = name
        localStorage.setItem(key, value)
    }

    function GetStorage(name, id = 0) {
        var key = name
        return localStorage.getItem(key)
    }
    function getDataList() {
        var dataList = []
        var Data = jsonData
        for (var i in Data) {
            dataList.push(Data[i])
        }
        return dataList
    }
    //单独
    //具体子单元内容呈现:id和标题会调整
    //0Education
    function FormUnitEducation({ id }) {


        const name = "Education"
        const title = name + " - " + (id + 1)
        const controlId = name + id
        const typeID = "0"
        const namePrefix = typeID + id


        //获取学校名单
        // const jsonUrl = require("./json/combined_names_en_categorized.json")
        const schoolList = getDataList()


        function InputDataList() {
            const optionList = schoolList.map(x => <option value={x} key={x}></option>)
            return (
                <datalist id="school_list">
                    {optionList}
                </datalist>

            )
        }




        return (
            <div id="u0" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>

                <Form.Group className="mb-3" controlId={controlId}>
                    <Form.Label>School</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "School")} onChange={e => SetStorage(namePrefix + "School", e.target.value, id)} onLoad={(e) => onLeaveSchoolInput((namePrefix + "School"), e.target.value)} onBlur={(e) => onLeaveSchoolInput((namePrefix + "School"), e.target.value)} required type="search" list="school_list" name={namePrefix + "School"} />
                    <InputDataList></InputDataList>
                </Form.Group>
                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "0"}>
                        <Form.Label>Degree</Form.Label>
                        <Form.Control required defaultValue={GetStorage(namePrefix + "Degree")} onChange={e => SetStorage(namePrefix + "Degree", e.target.value, id)} type="text" name={namePrefix + "Degree"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "1"}>
                        <Form.Label>Degree Type</Form.Label>

                        <Form.Select required defaultValue={GetStorage(namePrefix + "Degree_Type")} onChange={e => SetStorage(e.target.name, e.target.value, id)} name={namePrefix + "Degree_Type"} style={{ ...inputBoxStyle, ...inputBox2 }}>

                            <option value="Bachelor's Degree">Bachelor's Degree</option>
                            <option value="Master's Degree">Master's Degree</option>
                            <option value="Doctoral Degree">Doctoral Degree</option>
                            <option value="Professional Degree">Professional Degree</option>
                            <option value="Diploma">Diploma</option>
                            <option value="Certificate">Certificate</option>



                        </Form.Select>
                    </Form.Group>



                    <Form.Group className="mb-3" controlId={controlId + "2"}>
                        <Form.Label>Graduation Date (MM-YY)<span className="font11Custom ml-3">optional</span></Form.Label>
                        <Form.Control type="month" defaultValue={GetStorage(namePrefix + "Graduation_Date", id)} onChange={e => SetStorage(e.target.name, e.target.value, id)} name={namePrefix + "Graduation_Date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>
                </div>


                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "3"}>
                        <Form.Label>GPA<span className="font11Custom ml-3">optional</span></Form.Label>
                        <Form.Control name={namePrefix + "GPA"} defaultValue={GetStorage(namePrefix + "GPA")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "4"}>
                        <Form.Label>Avg Score (100 points score)</Form.Label>
                        <Form.Control required name={namePrefix + "Avg_score"} defaultValue={GetStorage(namePrefix + "Avg_score")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="number" style={{ ...inputBoxStyle, ...inputBox2 }} min={0} max={100}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "5"}>
                        <Form.Label>Score Scale</Form.Label>
                        <Form.Select required name={namePrefix + "Score_scale"} defaultValue={GetStorage(namePrefix + "Score_scale")} onChange={e => SetStorage(e.target.name, e.target.value, id)} style={{ ...inputBoxStyle, ...inputBox2 }}>

                            <option value="1">4.0 Scale</option>
                            <option value="2">5.0 Scale</option>
                            <option value="3">10.0 Scale</option>
                            <option value="4">100-Point Scale</option>
                            <option value="5">100-points (UK scale)</option>
                            <option value="6">Letter Scale (A, B, C, D, F)</option>
                            



                        </Form.Select>
                    </Form.Group>
                </div>




            </div>
        )
    }
    //1Modules
    function FormUnitModules({ id }) {

        const name = "Module"
        const title = name + " - " + (id + 1)
        const controlId = name + id

        const typeID = "1"
        const namePrefix = typeID + id
        return (
            <div id="u1" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>
                <Form.Group className="mb-3" controlId={controlId + "0"}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "Name")} onChange={e => SetStorage(e.target.name, e.target.value, id)} required type="text" name={namePrefix + "Name"} style={{ ...inputBoxStyle, ...inputBox4 }} />
                </Form.Group>




            </div>
        )
    }
    //2Work
    function FormUnitWork({ id }) {

        const name = "Experience"
        const title = name + " - " + (id + 1)
        const controlId = name + id
        const typeID = "2"
        const namePrefix = typeID + id
        return (
            <div id="u2" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>

                <Form.Group className="mb-3" controlId={controlId + "0"}>
                    <Form.Label>Company</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "Company")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" name={namePrefix + "Company"} style={{ ...inputBoxStyle, ...inputBox1 }} />
                </Form.Group>

                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "1"}>
                        <Form.Label>Type<span className="font11Custom"> optional</span></Form.Label>
                        <Form.Select name={namePrefix + "Type"} defaultValue={GetStorage(namePrefix + "Type")} onChange={e => SetStorage(e.target.name, e.target.value, id)} style={{ ...inputBoxStyle, ...inputBox2 }}>

                            <option value="Full-time">Full-time</option>
                            <option value="Part-time">Part-time</option>
                            <option value="Temporary">Temporary</option>
                            <option value="Contract">Contract</option>
                            <option value="Internship">Internship</option>
                            <option value="Volunteer">Volunteer</option>



                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "2"}>
                        <Form.Label>Position<span className="font11Custom"> optional</span></Form.Label>

                        <Form.Control type="text" defaultValue={GetStorage(namePrefix + "Position")} onChange={e => SetStorage(e.target.name, e.target.value, id)} name={namePrefix + "Position"} style={{ ...inputBoxStyle, ...inputBox2 }} />

                    </Form.Group>



                    <Form.Group className="mb-3" controlId={controlId + "3"}>
                        <Form.Label>Location<span className="font11Custom"> optional</span></Form.Label>
                        <Form.Control type="text" defaultValue={GetStorage(namePrefix + "Location")} onChange={e => SetStorage(e.target.name, e.target.value, id)} name={namePrefix + "Location"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>
                </div>


                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "4"}>
                        <Form.Label>Start Date (MM-YY)<span className="font11Custom"> optional</span></Form.Label>
                        <Form.Control type="month" defaultValue={GetStorage(namePrefix + "Start_date")} onChange={e => SetStorage(e.target.name, e.target.value, id)} name={namePrefix + "Start_date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "5"}>
                        <Form.Label>End Date (MM-YY)<span className="font11Custom"> optional</span></Form.Label>
                        <Form.Control type="month" defaultValue={GetStorage(namePrefix + "End_date")} onChange={e => SetStorage(e.target.name, e.target.value, id)} name={namePrefix + "End_date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>


                </div>
                <Form.Group className="mb-3" controlId={controlId + "6"}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "Description")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" as="textarea" name={namePrefix + "Description"} style={{ ...inputBoxStyle, ...inputBox3 }} />
                </Form.Group>



            </div>
        )
    }
    //3Projects
    function FormUnitProjects({ id }) {

        const name = "Experience"
        const title = name + " - " + (id + 1)
        const controlId = name + id
        const typeID = "3"
        const namePrefix = typeID + id
        return (
            <div id="u3" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>

                <Form.Group className="mb-3" controlId={controlId + "01"}>
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "Project_name")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" name={namePrefix + "Project_name"} style={{ ...inputBoxStyle, ...inputBox1 }} />
                </Form.Group>

                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "11"}>
                        <Form.Label>Project Type<span className="font11Custom"> optional</span></Form.Label>
                        <Form.Select defaultValue={GetStorage(namePrefix + "Project_type")} onChange={e => SetStorage(e.target.name, e.target.value, id)} name={namePrefix + "Project_type"} style={{ ...inputBoxStyle, ...inputBox2 }}>

                            <option value="Research">Research</option>
                            <option value="Development">Development</option>
                            <option value="Design">Design</option>
                            <option value="Implementation">Implementation</option>
                            <option value="Consulting">Consulting</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Community Service">Community Service</option>
                            <option value="Event Planning">Event Planning</option>
                            <option value="Internship">Internship</option>




                        </Form.Select>
                    </Form.Group>


                </div>


                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "21"}>
                        <Form.Label>Start Date (MM-YY)<span className="font11Custom"> optional</span></Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "Start_date")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="month" name={namePrefix + "Start_date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "31"}>
                        <Form.Label>End Date (MM-YY)<span className="font11Custom"> optional</span></Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "End_date")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="month" name={namePrefix + "End_date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>


                </div>
                <Form.Group className="mb-3" controlId={controlId + "41"}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "Description")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" as="textarea" name={namePrefix + "Description"} style={{ ...inputBoxStyle, ...inputBox3 }} />
                </Form.Group>



            </div>
        )
    }
    //4Skills
    function FormUnitSkills({ id }) {

        const name = "Skill"
        const title = name + " - " + (id + 1)
        const controlId = name + id
        const typeID = "4"
        const namePrefix = typeID + id
        return (
            <div id="u4" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>
                <Form.Group className="mb-3" controlId={controlId + "0"}>
                    <Form.Label>Skill Name</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "Name")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="text" as="textarea" name={namePrefix + "Skill_name"} style={{ ...inputBoxStyle, ...inputBox3 }} />
                </Form.Group>




            </div>
        )
    }
    //5Certifications
    function FormUnitCertifications({ id }) {

        const name = "certification"
        const title = name + " - " + id
        const controlId = name + id
        const typeID = "5"
        const namePrefix = typeID + id
        return (
            <div id="u5" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>
                <Form.Group className="mb-3" controlId={controlId + "0"}>
                    <Form.Label>Certification Name</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "certificate_name")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="text" name={namePrefix + "certificate_name"} style={{ ...inputBoxStyle, ...inputBox4 }} />
                </Form.Group>
                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "1"}>
                        <Form.Label>Certification Authority</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "certificate_authority")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="text" name={namePrefix + "certificate_authority"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>




                    <Form.Group className="mb-3" controlId={controlId + "2"}>
                        <Form.Label>Certification Date (MM-YY)</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "certificate_date")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="month" name={namePrefix + "certificate_date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>
                </div>






            </div>
        )
    }
    //6Awards
    function FormUnitAwards({ id }) {

        const name = "Award"
        const title = name + " - " + (id + 1)
        const controlId = name + id
        const typeID = "6"
        const namePrefix = typeID + id
        return (
            <div id="u6" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>
                <Form.Group className="mb-3" controlId={controlId + "0"}>
                    <Form.Label>Award Name</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "award_name")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="text" name={namePrefix + "award_name"} style={{ ...inputBoxStyle, ...inputBox4 }} />
                </Form.Group>
                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "1"}>
                        <Form.Label>Awarder</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "awarder")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="text" name={namePrefix + "awarder"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "2"}>
                        <Form.Label>Date (MM-YY)</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "date")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="month" name={namePrefix + "date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>
                </div>


            </div>
        )
    }
    //7Publications
    function FormUnitPublications({ id }) {

        const name = "Publication"
        const title = name + " - " + (id + 1)
        const controlId = name + id
        const typeID = "7"
        const namePrefix = typeID + id
        return (
            <div id="u7" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>
                <Form.Group className="mb-3" controlId={controlId + "0"}>
                    <Form.Label>Publication Name</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "publication_name")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="text" name={namePrefix + "publication_name"} style={{ ...inputBoxStyle, ...inputBox4 }} />
                </Form.Group>
                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "1"}>
                        <Form.Label>Publication URL</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "publication_url")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="text" name={namePrefix + "publication_url"} style={{ ...inputBoxStyle, ...inputBox1 }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "2"}>
                        <Form.Label>Date (MM-YY)</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "date")} onChange={e => SetStorage(e.target.name, e.target.value, id)}  type="month" name={namePrefix + "date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>
                </div>


            </div>
        )
    }
    //8Volunteer
    function FormUnitVolunteer({ id }) {

        const name = "Volunteer"
        const title = name + " - " + (id + 1)
        const controlId = name + id
        const typeID = "8"
        const namePrefix = typeID + id
        return (
            <div id="u8" className="mb-2">
                <div className="font8Custom mb-2">
                    {title}
                </div>

                <Form.Group className="mb-3" controlId={controlId + "0"}>
                    <Form.Label>Organization</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "Organization")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" name={namePrefix + "Organization"} style={{ ...inputBoxStyle, ...inputBox1 }} />
                </Form.Group>

                <div className="d-flex justify-content-start gap-4">


                    <Form.Group className="mb-3" controlId={controlId + "1"}>
                        <Form.Label>Position</Form.Label>

                        <Form.Control defaultValue={GetStorage(namePrefix + "Position")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" name={namePrefix + "Position"} style={{ ...inputBoxStyle, ...inputBox2 }} />

                    </Form.Group>



                    <Form.Group className="mb-3" controlId={controlId + "2"}>
                        <Form.Label>Location</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "Location")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" name={namePrefix + "Location"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>
                </div>


                <div className="d-flex justify-content-start gap-4">
                    <Form.Group className="mb-3" controlId={controlId + "3"}>
                        <Form.Label>Start Date (MM-YY)</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "Start_date")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="month" name={namePrefix + "Start_date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={controlId + "4"}>
                        <Form.Label>End Date (MM-YY)</Form.Label>
                        <Form.Control defaultValue={GetStorage(namePrefix + "End_date")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="month" name={namePrefix + "End_date"} style={{ ...inputBoxStyle, ...inputBox2 }} />
                    </Form.Group>


                </div>
                <Form.Group className="mb-3" controlId={controlId + "5"}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control defaultValue={GetStorage(namePrefix + "Description")} onChange={e => SetStorage(e.target.name, e.target.value, id)} type="text" as="textarea" name={namePrefix + "Description"} style={{ ...inputBoxStyle, ...inputBox3 }} />
                </Form.Group>



            </div>
        )
    }

    //链接
    //表单某单元管理：外部的增删（标题、id）typeID定义表单类型(0-8)，listID定义同一类型表单的多个副本
    function FormUnit({ typeID }) {

        const initNum = formNum[typeID]
        const list = []
        for (var i = 0; i < initNum; i++) {
            list.push(i)
        }

        const [unitIDList, setUnitIDList] = useState(list)
        const unitLength = unitIDList.length

        //id分发各个类型的表单
        function TypeIDToUnit({ typeID, listID }) {
            //0-8分别对应education-volunteer
            switch (typeID) {
                default: return (<></>)
                case 0: return (<FormUnitEducation id={listID}></FormUnitEducation>)
                case 1: return (<FormUnitModules id={listID}></FormUnitModules>)
                case 2: return (<FormUnitWork id={listID}></FormUnitWork>)
                case 3: return (<FormUnitProjects id={listID}></FormUnitProjects>)
                case 4: return (<FormUnitSkills id={listID}></FormUnitSkills>)
                case 5: return (<FormUnitCertifications id={listID}></FormUnitCertifications>)
                case 6: return (<FormUnitAwards id={listID}></FormUnitAwards>)
                case 7: return (<FormUnitPublications id={listID}></FormUnitPublications>)
                case 8: return (<FormUnitVolunteer id={listID}></FormUnitVolunteer>)
            }

        }
        //返回标题
        function TypeIDToTitle() {
            switch (typeID) {
                default: return ""
                case 0: return "Education"
                case 1: return "Modules"
                case 2: return "Work"
                case 3: return "Projects"
                case 4: return "Skills"
                case 5: return "Certifications"
                case 6: return "Awards"
                case 7: return "Publications"
                case 8: return "Volunteer"
            }

        }
        //返回add描述字符
        function TypeIDToText() {

            switch (typeID) {
                default: return ""
                case 0: return "Add education experience"
                case 1: return "Add module"
                case 2: return "Add work experience"
                case 3: return "Add project experience"
                case 4: return "Add skills"
                case 5: return "Add a certification"
                case 6: return "Add an award"
                case 7: return "Add education experience"
                case 8: return "Add Volunteer experience"
            }

        }

        //渲染数组
        const unitList = unitIDList.map(x => <Unit id={x} key={x}></Unit>)

        //监听事件
        //add监听
        function handleAdd() {
            var num = unitIDList.length
            setUnitIDList([...unitIDList, num])
            var array = formNum
            array[typeID] = array[typeID] + 1
            setFormNum(array)
        }
        //delete监听
        function handleDelete() {
            var num = unitIDList.length
            if (num > 1) {
                setUnitIDList(unitIDList.filter(x => x !== num - 1));

                var array = formNum
                array[typeID] = array[typeID] - 1
                setFormNum(array)
            }



        }



        function Unit({ id }) {
            return (
                <div className="mt-4 ml-10">
                    <TypeIDToUnit typeID={typeID} listID={id}></TypeIDToUnit>
                    {
                        //添加按钮
                    }

                </div>
            )


        }

        return (

            <div id={typeID} className="mb-5" >
                <div className="font6Custom " style={{ position: "sticky", top: 0, backgroundColor: "white", width: "100%", height: 40 }}><span className="ml-10"></span>{TypeIDToTitle()}</div>
                <div >
                    {unitList}

                </div>
                <div className="d-flex justify-content-between  mb-3 ml-7 mr-10">
                    {
                        //添加按钮节点
                    }
                    <Button className="transparentButton " onClick={() => handleAdd()}>

                        <span className="font11Custom">
                            <i className="bi bi-plus-circle  mr-3" style={{ fontSize: 20 }}></i>
                            {TypeIDToText()}
                        </span>
                    </Button>

                    {
                        //删除按钮节点
                    }
                    <Button onClick={() => handleDelete()} className=" transparentButton" style={{ width: 60, marginRight: 0, display: unitLength > 1 ? "inline" : "none" }}>
                        <i className="bi bi-dash-circle" style={{ color: "rgba(256,100,100,1)", fontSize: 24 }}></i>
                    </Button>
                </div>
            </div>

        )

    }

    //监听滚动
    function setIndexInObserver(x, entry, totalheight) {
        // if (menuIndex != x) setMenuIndex(x)
        console.log(x + ":" + JSON.stringify(entry.intersectionRect))
        if (entry.intersectionRect.height <= 30) setMenuIndex(x)
    }

    const scrollToSection = (index) => {
        const element = document.getElementById(index.toString());
        if (element) {
            const topOffset = 0; // Adjust this value to offset the top scroll position
            const elementPosition = element.getBoundingClientRect().top + document.getElementById("scrollArea").scrollTop;
            const offsetPosition = elementPosition - topOffset;

            document.getElementById("scrollArea").scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
        //setMenuIndex(index);
    };

    useEffect(() => {


        // let options = {
        //     root: document.getElementById("scrollArea"),
        //     rootMargin: "110px",
        //     threshold: [.90, .91, .92, .93, .94, .95, .96, .97, .98, .99,]
        // };
        // let totalheight = options.root.clientHeight
        // let observer1 = new IntersectionObserver(function (e) {
        //     if (e[0].isIntersecting & menuIndex !== 0) setIndexInObserver(0, e[0], totalheight)
        // }, options)
        // let observer2 = new IntersectionObserver(function (e) {
        //     if (e[0].isIntersecting & menuIndex !== 1) setIndexInObserver(1, e[0], totalheight)
        // }, options)
        // let observer3 = new IntersectionObserver(function (e) {
        //     if (e[0].isIntersecting & menuIndex !== 2) setIndexInObserver(2, e[0], totalheight)
        // }, options)
        // let observer4 = new IntersectionObserver(function (e) {
        //     if (e[0].isIntersecting & menuIndex !== 3) setIndexInObserver(3, e[0], totalheight)
        // }, options)
        // let observer5 = new IntersectionObserver(function (e) {
        //     if (e[0].isIntersecting & menuIndex !== 4) setIndexInObserver(4, e[0], totalheight)
        // }, options)
        // let observer6 = new IntersectionObserver(function (e) {
        //     if (e[0].isIntersecting & menuIndex !== 5) setIndexInObserver(5, e[0], totalheight)
        // }, options)
        // let observer7 = new IntersectionObserver(function (e) {
        //     if (e[0].isIntersecting & menuIndex !== 6) setIndexInObserver(6, e[0], totalheight)
        // }, options)
        // let observer8 = new IntersectionObserver(function (e) {
        //     if (e[0].isIntersecting & menuIndex !== 7) {
        //         setIndexInObserver(7, e[0], totalheight)
        //     }
        // }, options)
        // let observer9 = new IntersectionObserver(function (e) {

        //     if (e[0].isIntersecting & menuIndex !== 8) {
        //         setIndexInObserver(8, e[0], totalheight)
        //     }
        // }, options)
        // observer1.observe(document.getElementById("0"))
        // observer2.observe(document.getElementById("1"))
        // observer3.observe(document.getElementById("2"))
        // observer4.observe(document.getElementById("3"))
        // observer5.observe(document.getElementById("4"))
        // observer6.observe(document.getElementById("5"))
        // observer7.observe(document.getElementById("6"))
        // observer8.observe(document.getElementById("7"))
        // observer9.observe(document.getElementById("8"))

        return () => {

            // observer1.disconnect()
            // observer2.disconnect()
            // observer3.disconnect()
            // observer4.disconnect()
            // observer5.disconnect()
            // observer6.disconnect()
            // observer7.disconnect()
            // observer8.disconnect()
            // observer9.disconnect()
        }
    })


    const defaltListStyle = {
        width: 200,
        height: 32,
        border: "none",
        alignItems: "center",
        textAlign: "center",
        color: "black",
        textDecoration: "none"
    }
    const scrollOnStyle = {
        width: 200,
        height: 32,
        fontSize: 16,
        fontWeight: 700,
        color: "rgba(40, 126, 255, 1)",
        backgroundColor: "rgba(238, 245, 255, 1)",
        textDecoration: "none",
        borderRadius: 8,
    }

    const leftStyle = "d-flex ml-20 scrollMenu align-items-center"

    function onChangeActiveId(id) {
        setMenuIndex(id);
    }

    //主返回
    return (

        <div className="d-flex row" style={{ position: "relative", height: 'calc(100vh - 96px - 25px)' }}>
            {/* <div className="d-flex border-top-0 font11Custom border-start-0  border-end-0 border-2 align-items-center " style={{ width: "100%", height: 100, color: "rgba(170, 170, 170, 1)" }}>
                <div className="d-flex mt-3 justify-content-between mt-4 ml-10 mr-10" style={{ width: "100%" }}>
                    <div>Quit</div>
                    <div>Auto Fill-in with CV</div>
                    <div style={{ position: "absolute", top: 150, left: 1080, zIndex: 100 }}>*optional</div>
                </div>
            </div> */}
            {
                //上传文件
            }
            <div className="d-flex flex-column align-items-start" style={{ position: 'absolute', top: 20, left: 900, zIndex: 200, gap: 5 }}>
                <div className="d-flex align-items-center gap-3 ml-9" ><span>Resume:</span>
                    {/* <label for="cvparsefile">选择文件</label> */}
                    <input type="file" accept=".doc,.docx,.pdf" name="resumeFile" id="cvparsefile" style={{ opacity: 1 }}></input>
                </div>
                <div>
                    {/* <FillStatusContext.Provider>
                        {value == 0 ? <button onClick={() => handleAutoFillFormClick()} className="pageBtns_cvFillin" >Auto Fill-in with CV</button > : <Loading></Loading>}

                    </FillStatusContext.Provider> */}
                    {/* <button onClick={() => handleAutoFillFormClick()} className="pageBtns_cvFillin" >Auto Fill-in with CV</button > */}
                    {fillinStatus == 0 ? <button onClick={() => handleAutoFillFormClick()} className="pageBtns_cvFillin" style={{ textAlign: "left" }}>Auto Fill-in with CV</button > : <Loading></Loading>}

                </div>

            </div>

            <div className="d-flex mt-5 font6Custom" style={{ height: 'calc(100vh - 96px - 25px)', position: 'sticky', paddingTop: 20 }}>
                <div className="d-flex flex-column  border-top-0 border-start-0  border-bottom-0 border-2  " style={{ width: 300, minWidth: 300, height: 580 }}>
                    <div className="mb-3 ml-10"><div className="ml-5">Experience Summary</div></div>
                    <ScrollSpy activeClass="nav-active" onChangeActiveId={onChangeActiveId}>
                        <div variant="flush" id="menu" className="d-flex row font4Custom align-items-center" style={{ width: "100%", height: 400 }}>
                            <a href="#0" onClick={(e) => { e.preventDefault(); scrollToSection(0); }} className={leftStyle} style={menuIndex == 0 ? scrollOnStyle : defaltListStyle}>Education (required)</a>
                            <a href="#1" onClick={(e) => { e.preventDefault(); scrollToSection(1); }} className={leftStyle} style={menuIndex == 1 ? scrollOnStyle : defaltListStyle}>Modules (required)</a>
                            <a href="#2" onClick={(e) => { e.preventDefault(); scrollToSection(2); }} className={leftStyle} style={menuIndex == 2 ? scrollOnStyle : defaltListStyle}>Work</a>
                            <a href="#3" onClick={(e) => { e.preventDefault(); scrollToSection(3); }} className={leftStyle} style={menuIndex == 3 ? scrollOnStyle : defaltListStyle}>Projects</a>
                            <a href="#4" onClick={(e) => { e.preventDefault(); scrollToSection(4); }} className={leftStyle} style={menuIndex == 4 ? scrollOnStyle : defaltListStyle}>Skills</a>
                            <a href="#5" onClick={(e) => { e.preventDefault(); scrollToSection(5); }} className={leftStyle} style={menuIndex == 5 ? scrollOnStyle : defaltListStyle}>Certifications</a>
                            <a href="#6" onClick={(e) => { e.preventDefault(); scrollToSection(6); }} className={leftStyle} style={menuIndex == 6 ? scrollOnStyle : defaltListStyle}>Awards</a>
                            <a href="#7" onClick={(e) => { e.preventDefault(); scrollToSection(7); }} className={leftStyle} style={menuIndex == 7 ? scrollOnStyle : defaltListStyle}>Publications</a>
                            <a href="#8" onClick={(e) => { e.preventDefault(); scrollToSection(8); }} className={leftStyle} style={menuIndex == 8 ? scrollOnStyle : defaltListStyle}>Volunteer</a>
                        </div>
                    </ScrollSpy>
                </div>
                <div className="d-flex justify-content-center ml-10" style={{ width: 820, height: 650 }}>
                    <div className="d-flex flex-column align-items-start" style={{ width: "100%", height: "100%" }}>

                        <div id="scrollArea" style={{ position: "relative", overflow: "scroll", overflowY: "auto", width: 920 }}>
                            <div className="mb-5 ml-1">
                                {//整体
                                    //整体呈现
                                }
                                <div className="d-flex font4Custom ">
                                    <Form autoComplete="false" id="myCVForm" onSubmit={onSubmit} >


                                        <div style={{}}><FormUnit typeID={0} ></FormUnit></div>
                                        <div style={{}}><FormUnit typeID={1}></FormUnit></div>
                                        <div style={{}}><FormUnit typeID={2}></FormUnit></div>
                                        <div style={{}}><FormUnit typeID={3}></FormUnit></div>
                                        <div style={{}}><FormUnit typeID={4}></FormUnit></div>
                                        <div style={{}}><FormUnit typeID={5}></FormUnit></div >
                                        <div style={{}}><FormUnit typeID={6}></FormUnit></div >
                                        <div style={{}}><FormUnit typeID={7}></FormUnit></div >
                                        <div style={{}}><FormUnit typeID={8}></FormUnit></div >
                                        <div style={{ height: 0 }}></div>



                                        <Button className="ml-10" variant="primary" type="submit" style={{ visibility: "hidden" }}>
                                            Submit
                                        </Button>
                                    </Form>
                                </div>
                                <div style={{ height: 20 }}> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}