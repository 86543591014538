import { Container, Nav, Navbar, Stack } from "react-bootstrap";
import {Link} from "react-router-dom";
import "./styles/Fonts.css"
import HandlePageClick from "./Link/PageClick";




const text1Style = {
    fontSize: 16,
    fontWeight: 600
}
const text2Style = {
    fontSize: 14,
    fontWeight: 400

}

//声明按钮初始化值
const btnGroup1 = {
    link1:{
            id:"programguide",
            text: "Program guide",
        },
        link2:{
            id:"",
            text: "",
        },
        link3:{
            id:"",
            text: "",
        },
        link4:{
            id:"",
            text: "",
        }
    
  
}

const btnGroup2 = {
    
    link1:{
        id:"findprogram",
        text: "Find a program",
    },
    link2:{
        id:"findtutor",
        text: "Find a tutor",
    },
    link3:{
        id:"",
        text: "",
    },
    link4:{
        id:"",
        text: "",
    }
    // link1:{
    //     id:"dashboard",
    //     text: "Dashboard",
    // },
    // link2:{
    //     id:"resources1",
    //     text: "Resources 1",
    // },
    // link3:{
    //     id:"resources2",
    //     text: "Resources 2",
    // }
  


}

const btnGroup3 = {

    link1: {
        id: "Pricing",
        text: "Pricing & Plan",
    },
     link2:{
        id:"",
        text: "",
    },
    link3:{
        id:"",
        text: "",
    },
    link4:{
        id:"",
        text: "",
    }
    // link1:{
    //     id:"pricing",
    //     text: "Pricing",
    // },
    // link2:{
    //     id:"",
    //     text: "",
    // },
    // link3:{
    //     id:"",
    //     text: "",
    // }
   


}
const btnGroup4 = {
    
    link1:{
        id:"help&contactus",
        text: "Help & Contact us",
    },
    link2:{
        id:"",
        text: "",
    },
    link3:{
        id:"",
        text: "",
    },
    link4:{
        id:"",
        text: "",
    }

   


}





function MainBtnGroup({ btnGroup }) {
    return (
        <>
            <Stack gap={4} style={{textAlign:"left"}}>


                <button style={{textAlign:"left"}} onClick={()=>{HandlePageClick("footnav_"+btnGroup.link1.id)}}>{btnGroup.link1.text}</button>
                <button style={{textAlign:"left"}} onClick={()=>{HandlePageClick("footnav_"+btnGroup.link2.id)}}>{btnGroup.link2.text}</button>
                {/* <button style={{textAlign:"left"}} onClick={()=>{HandlePageClick("footnav_"+btnGroup.link3.id)}}>{btnGroup.link3.text}</button>
                <button style={{textAlign:"left"}} onClick={()=>{HandlePageClick("footnav_"+btnGroup.link4.id)}}>{btnGroup.link4.text}</button> */}

            </Stack>
            {//排版占位

            }
            <div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div />

        </>
    )


}
export default function FootNav() {
   
    

    const MAIN_WIDTH = 1200

    return (
        <div id="FootNav" className="mobile-container" style={{marginTop:100,marginBottom:30}} >
            <Navbar expand="lg" className="justify-content-center mobile-item-l">
                <Container className="justify-content-center ">
                    <Nav style={{ width: MAIN_WIDTH }}>
                        <Stack direction="vertical" gap={4}>
                            <Navbar.Brand><img src={require("./img/ApplyU - logo - 36_36.png")} width={40} height={40} /></Navbar.Brand>
                            <Nav style={{...text1Style}}>
                                <Stack direction="horizontal" gap={2}>

                                    {//按钮组位置
                                    }
                                    <MainBtnGroup btnGroup={btnGroup1} ></MainBtnGroup>
                                    <MainBtnGroup btnGroup={btnGroup2} ></MainBtnGroup>
                                    <MainBtnGroup btnGroup={btnGroup3} ></MainBtnGroup>
                                    <MainBtnGroup btnGroup={btnGroup4} ></MainBtnGroup>



                                </Stack>
                            </Nav>
                            <div></div>
                        </Stack>
                    </Nav>
                </Container>
            </Navbar>
            <Navbar expand="lg" className="border-top border-light border-3 justify-content-center" >
                <Container style={{ height: 100 }} className="justify-content-center mobile-item-l">
                    <Nav style={{ ...text2Style, width: MAIN_WIDTH }} className="d-flex justify-content-between fontBlackBold">

                        <Stack direction="horizontal" gap={5}>
                            <button style={{textAlign:"left"}} onClick={()=>HandlePageClick("footnav_privacy")}>Privacy Policy</button>
                            <button style={{textAlign:"left"}} onClick={()=>HandlePageClick("footnav_termsOfService")}>Terms of Service</button>
                        </Stack>

                        <Stack direction="horizontal" gap={5}>
                            <div className="font9Custom">Connect:</div>
                            <Link style={{textAlign:"left"}} to={"https://www.linkedin.com/company/applyu-ai/about/"} target="_blank"><img src={require("./img/Website/Icons/ApplyU_ceshi_Icon_Linkedin.webp")} width={16}></img></Link>
                            {/* <button style={{textAlign:"left"}} onClick={()=>HandlePageClick("footnav_youtube")}>YouTube</button> */}
                        </Stack>



                    </Nav>
                </Container>
            </Navbar>
        </div>
    )
}