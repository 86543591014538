import FootNav from "./FootNav";
import HeadNav from "./HeadNav";
import PageTitle from "./PageTitle";
import TextList from "./TextList";
import StatementParagragh from "./StatementParagragh";


//statement静态数据
const StatementParagraghProps={
    dataText:"Updated: May 19, 2024",
    titleText:"Terms of Service",
    pText:"<p>Welcome to Apply U! By using our website and services (“Apply U”), you (“You”) agree to comply with and be bound by the following terms and conditions of use (“Terms”). We encourage you to read these terms carefully and contact us with any questions. Your continued use of our website signifies your acceptance of these terms. Thank you for being part of our community.</p><p>In addition to these terms, we publish a <span style=font-weight:700>Privacy Policy</span> that describes how we process, collect, use, and protect your information.</p>"
}

//内容段落
const ParagraghContent={
    titleText:"Terms",
    paragraphGroups:[
        {
            id:0,
            pTitle:"Acceptance of Terms",
            pText:"By accessing or using Apply U, you agree to these Terms of Service and all applicable laws and regulations."
        },
        {
            id:1,
            pTitle:"Use License",
            pText:"Apply U grants you a limited license to access and use the content and services provided on our platform for personal, non-commercial use."
        },
        {
            id:2,
            pTitle:"User Account",
            pText:"You may be required to create an account to access certain features of Apply U. You are responsible for maintaining the confidentiality of your account information and for al activities that occur under your account."
        },
        {
            id:3,
            pTitle:"Content",
            pText:"Users may contribute content to Apply U, such as essays, articles, and comments. By submitting content, you grant Apply U a worldwide, royalty-free, perpetual, irrevocable, and non-exclusive license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content."
        },
        {
            id:4,
            pTitle:"Intellectual Property",
            pText:"All content and materials on Apply U, including text, graphics, logos, and images, are the property of Apply U or its licensors and are protected by copyright and other intellectual property laws."
        },
        {
            id:5,
            pTitle:"Limitations",
            pText:"Apply U reserves the right to modify, suspend, or terminate access to the platform at any time without prior notice."
        },
        {
            id:6,
            pTitle:"Disclaimer",
            pText:"Apply U .............."
        },
    ]
}


//home和service1未使用模板
export default function TermsOfService() {
    return (
        <>
            <div className='d-flex row justify-content-center'>

                <HeadNav />

                <div style={{ maxWidth: 1200 }} className="d-flex row justify-content-center" >
                    {//content
                    }
                    <PageTitle TitleText={"Terms of Service"}></PageTitle>
                    <StatementParagragh DateText={StatementParagraghProps.dataText} TitleText={StatementParagraghProps.titleText} pText={StatementParagraghProps.pText} ></StatementParagragh>
                    <TextList titleText={ParagraghContent.titleText} pGroup={ParagraghContent.paragraphGroups}></TextList>

                </div>


                <FootNav />
            </div>
        </>
    )
}