// src/store/wishlistSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProgramItem {
    wishlistId: string;
    school: string;
    uni_logo_path: string;
    program: string;
    location: string;
    country: string;
    description: string;
}

interface WishlistState {
    items: ProgramItem[];
}

const initialState: WishlistState = {
    items: [],
};

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        setWishlist(state, action: PayloadAction<ProgramItem[]>) {
            state.items = action.payload;
        },
    },
});

export const { setWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
