import React, { useEffect } from 'react';
import '../styles/PaymentQuitPopup.scss';
import { Link } from 'react-router-dom';

interface QuitPopupProps {
    exit: () => void;
}

export const PaymentQuitPopup: React.FC<QuitPopupProps> = ({ exit }) => {

    return (
        <div className="payment_popup">
            <div className="payment_popup_container">
                <div className="payment_popup_content">
                    <div className="payment_popup_content_title">
                        Are you sure you want to quit this payment?
                    </div>
                    <div className="payment_popup_content_btns">
                        <Link to="/pricing" ><button className="back-button">Quit</button></Link>
                        <button onClick={exit} className="submit-button">Continue</button>
                    </div>
                </div>
            </div>

        </div>
    );
};
