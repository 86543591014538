import React, { useEffect } from 'react';
import '../styles/CheckOrderPopup.scss';

interface CheckOrderPopupProps {
    close: () => void;
}

export const CheckOrderPopup: React.FC<CheckOrderPopupProps> = ({ close }) => {

    return (
        <div className="check_order_popup">
            <div className="check_order_popup_container">
                <div className="check_order_popup_content">
                    <div className="check_order_popup_content_title">
                        Notice: Introductory Offer Limitation
                    </div>
                    <div className="check_order_popup_content_title">
                        通知：首次购买优惠限制
                    </div>
                    <div className="check_order_popup_content_text">
                        This special price is only available to first-time buyers. If you've purchased from us before, please check our other packages. 此特别价格仅适用于首次购买的客户。如果您之前已经购买过，请查看我们的其他套餐。
                    </div>
                    <div className="check_order_popup_content_text">
                        Need help? Contact our support team. Thank you for your understanding! 需要帮助？请联系我们的支持团队。感谢您的理解
                    </div>
                    <div className="check_order_popup_content_btns">
                        <button onClick={close} className="submit-button">Continue</button>
                    </div>
                </div>
            </div>

        </div>
    );
};
