import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { useNavigate } from 'react-router-dom';
import majorIcon from "../assets/Major.png";
import careerIcon from "../assets/career.png";
import locationIcon from "../assets/Location.png";
import rankingIcon from "../assets/Ranking - 1.png";
import abroadIcon from "../assets/study-abroad.png";
import arrowLeft from "../assets/ArrowLeft.png";
import majors from "../assets/MajorData"; // Import majors data
import jobs from "../assets/JobData"; //Import jobs data
import {getPrograms} from "../api/program"
import '../styles/ProgramFilter.scss';

type answers = {
    0: string[];
    1: string[];
    2: string[];
    3: string[];
}

const countries = ["United Kingdom", "Australia", "New Zeland"];
const iconList = [majorIcon, careerIcon, abroadIcon, rankingIcon, locationIcon];
const teachingType = ['Full Time', 'Part time'];

interface ProgramFilterProps {
    setPrograms: React.Dispatch<React.SetStateAction<any[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Filter: React.FC<ProgramFilterProps> = ({setPrograms,setLoading}) => {
    const [clickIndex, setClickIndex] = useState<number | null>(null); // Track the index of the clicked question
    const [rankvalues, setRankvalues] = useState([1, 200]); // Range values for the slider
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]); // Track selected filters
    const [answers, setAnswers] = useState<answers>(
        {
            0: [], // major
            1: [], // career
            2: [], // type
            3: [] // region
        });

    const min = 1;
    const max = 1000;
    //const navigate = useNavigate();

    const handleClick = (idx: number): void => {
        setClickIndex((prevIndex) => (prevIndex === idx ? null : idx));
    };

    const handleDelete = (answer: string, event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent triggering the parent expand event
        const idx = Object.entries(answers).findIndex(([, choices]) => choices.includes(answer));
        setAnswers((prevAnswers: any) => {
            const updatedAnswers = prevAnswers[idx].filter((a: any) => a !== answer);
            return { ...prevAnswers, [idx]: updatedAnswers };
        });
        setSelectedFilters((prevFilters) => prevFilters.filter((f: any) => f !== answer));
        console.log(idx);
    };

    // Function for get changing value of slider
    const handleSliderChange = (value: number[]) => {
        setRankvalues(value);
    }
    const handleMouseDown = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const handleClearFilters = () => {
        setSelectedFilters([]);
        setAnswers({
            0: [],
            1: [],
            2: [],
            3: []
        })
    };

    const handleAnswer = (idx: number, answer: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setSelectedFilters((prevFilters) => {
            if (prevFilters.includes(answer)) {
                alert('This choice is already in the list.');
                return prevFilters; // Return the same state if duplicate
            }else if((idx===0 ||idx===1||idx===3) && answers[idx].length>0){
                alert(`You can only choose one ${idx===0?'major':idx===1?'career':'region'}.`);
                return prevFilters; // Return the same state if duplicate
            }
            return [...prevFilters, answer];
        });
        setAnswers((prevAnswers: any) => {
            // Check for duplicate answer
            if (prevAnswers[idx].includes(answer)) {
                //alert('This choice is already in the list.');
                return prevAnswers; // Return the same state if duplicate
            }else if((idx===0 ||idx===1 ||idx===3) && answers[idx].length>0){
                //alert('You can only choose one major. answer');
                return prevAnswers; // Return the same state if duplicate
            }
            // Add the new answer if not a duplicate
            return { ...prevAnswers, [idx]: [...prevAnswers[idx], answer] };
        });
    };



    async function handleSubmit() {
        try {
            setLoading(true);
            const result = await getPrograms(answers[0], answers[3], rankvalues, answers[1]);
            //console.log(result);
            if (result.code ===200 && Array.isArray(result.data) && result.data.length > 0) {
                setPrograms(result.data);
            }else if(result.code === 302){
                alert("Session expired, please login again.")
            } 
            else {
                setPrograms([]); // 确保传递给 setPrograms 的是数组
            }
        } catch (error:any) {
            console.error('Error in handleSubmit:', error);
            if(error.response.status === 302 || error.response.status === 405){
                alert("Session expired, please login again.")
            }
            setPrograms([]); // 错误情况下设置为空数组
        }finally{
            setLoading(false);
        }
    }

    return (
        <div className="program-filter-container">
            <div className="program-filter-header">
                Selected Filter
                {selectedFilters.length > 0 && (
                    <button className="program-clear-filters" onClick={handleClearFilters}>Clear Filter</button>)}
            </div>
            <div className="program-filter-selected">
                {selectedFilters.map((filter, index) => (
                    <ul className="program-filter-selected-item" key={index} onClick={(event) => handleDelete(filter, event)}>
                        <li>{filter}</li>
                    </ul>))}
            </div>
            {questions.map((question, index) => (
                <div
                    onClick={() => handleClick(index)}
                    key={index}
                    className={`program-question-element ${clickIndex === index ? 'expanded' : ''}`}
                >
                    <img className={`program-question-image ${clickIndex === index ? 'expanded' : ''}`} src={iconList[index]} alt="img" />
                    <h3 className="program-question-text">{question.text}</h3>
                    <img className={`program-custom-arrow ${clickIndex === index ? 'rotated' : ''}`} src={arrowLeft} alt="img" />
                    <div className='program-list-holder'>
                        {index === 0 && clickIndex === 0 && (
                            <ul className="program-target_major_main_list">
                                {Object.entries(majors).map(([key, major]) => (
                                    <li onClick={(event) => handleAnswer(0, major.key, event)} key={key}>
                                        {major.key}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {clickIndex === 1 && index === 1 && (
                            <ul className="program-target_major_main_list">
                                {Object.entries(jobs).map(([key, job]) => (
                                    <li onClick={(event) => handleAnswer(1, job.key, event)}
                                        key={key}>
                                        {job.key}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {/* {clickIndex === 2 && index === 2 && (
                            <ul className="program-target_major_main_list">
                                {teachingType.map((type, typeIndex) => (
                                    <li onClick={(event) => handleAnswer(2, type, event)} key={`teaching_type_${typeIndex}`}>
                                        {type}
                                    </li>
                                ))}
                            </ul>
                        )} */}
                        {clickIndex === 2 && index === 2 && (
                            <div className="program-range-wrapper" onClick={handleMouseDown}>
                                <div className="program-slider">
                                    <Range
                                        values={rankvalues}
                                        step={1}
                                        min={min}
                                        max={max}
                                        onChange={(values) => handleSliderChange(values)}
                                        renderTrack={({ props, children }) => (
                                            <div
                                                {...props}
                                                className="track"
                                            >
                                                {children}
                                            </div>
                                        )}
                                        renderThumb={({ index, props }: { index: number; props: React.HTMLAttributes<HTMLDivElement> }) => (
                                            <div {...props} className="program-thumb">
                                                <div className="program-thumb-label">{rankvalues[index]}</div>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="program-range-labels">
                                    <span>Top 1</span>
                                    <span>Top 1000</span>
                                </div>
                            </div>
                        )}
                        {clickIndex === 3 && index === 3 && (
                            <ul className="program-target_major_main_list">
                                {countries.map((country, index) => (
                                    <li onClick={(event) => handleAnswer(3, country as string, event)}
                                        key={`countries_${index}`}>
                                        {country}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            ))}
            <button className='program-apply_filter_btn' disabled={answers[0].length===0 || answers[1].length===0 || answers[3].length===0} onClick={handleSubmit}>Apply Filter</button>
        </div>
    );
};


const questions = [
    {
        text: 'Major (only one please)',
    },
    {
        text: 'Career (only one please)',
    },
    // {
    //     text: 'Full Time/Part Time',
    // },
    {
        text: 'University Ranking',
    },
    {
        text:'Region (only one please)',
    }
];

export default Filter;