import React, { useEffect } from 'react';
import btnClose from '../assets/popup-close.png';
import '../styles/QuitPopup.scss';

interface QuitPopupProps {
    quit: () => void;
    setIsQuitOpen: (value: boolean) => void;
    isQuitOpen: boolean;
    isTutorOpen: boolean;
}

const QuitPopup: React.FC<QuitPopupProps> = ({ quit, isQuitOpen, setIsQuitOpen, isTutorOpen }) => {
    // useEffect(() => {
    //     if (isTutorOpen) {
    //         setIsQuitOpen(false);
    //     }
    // }, [isTutorOpen]);

    const openPopup = () => {
        if (!isTutorOpen) {
            setIsQuitOpen(true);
            console.log('QuitPopup openPopup');
        }
    };

    const closePopup = () => {
        setIsQuitOpen(false);
    };

    const handleSubmit = () => {
        closePopup();
        quit();
    };

    return (
        <div className="quit_popup">
            {/* <button className="quit_popup_btn" onClick={openPopup} style={isTutorOpen ? { visibility: 'hidden' } : {}}>
                Quit
            </button> */}
            {!isTutorOpen && isQuitOpen && (
                <div className="quit_popup_container">
                    <div className="quit_popup_content">
                        <div className="quit_popup_content_title">Notice</div>
                        <div className="quit_popup_content_info">
                            Do you want to quit? We would NOT save your answer.
                        </div>
                        <div className="quit_popup_content_btns">
                            <button onClick={handleSubmit} className="back-button">Quit</button>
                            <button onClick={closePopup} className="submit-button">Continue</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuitPopup;
