import { Button, Stack, Image, Row, Col } from "react-bootstrap";

//字体样式
const titleStyle = {
    fontSize: 20,
    lineHeight: "27px",
    fontWeight: 700,
    color: "rgba(17, 17, 17, 1)",
    marginTop: 20,
    marginBottom: 0
}
//按钮样式
const stepStyle = {

    color: "rgba(17, 17, 17, 1)",

    fontSize: "32px",
    fontWeight: 700,


}
//可选参数
const defaultProp = {
    btnText: "Get Your Report",
    titleText: "",
    pText:
        <ul className="disc">
            <li style={{ marginBottom: 20 }}><span className="font6Custom">Top Picks : </span>Discover your top 10 tailored master's programs.</li>
            <li style={{ marginBottom: 20 }}><span className="font6Custom">Detailed Insights : </span>Comprehensive report detailing strengths, weaknesses, and overall score.</li>
            <li style={{ marginBottom: 20 }}><span className="font6Custom">Key Dimensions : </span>Evaluates academics, skills, discipline relevance, and career alignment.</li>
            <li style={{ marginBottom: 20 }}><span className="font6Custom">Informed Decisions : </span>Empowers you with insights to perfectly match your educational and career goals.</li>
        </ul>,
    imgUrl: require("./img/Website/Pictures/Group 2590-1.webp"),
    imgUrl2: require("./img/Website/Pictures/Group 2590.webp"),
    imgUrl3: require("./img/Website/Pictures/Group 2663.webp"),
    imgUrl4: require("./img/Website/Pictures/Group 2664.webp"),



    iconUrl: require("./icon/ApplyU_ceshi_Icon_step report.png"),
    reverseTag: false,
    imgWidth: 780,
    imgHeight: 480,
    marginTopAdjust: 100,
    //以下参数为修正图片大小不一致带来的版式不一致问题
    textBoxWidth: 480,
    imgMarginTopAdjust: 0,
    imgLayWidth: 0
}



export default function ScrollCard({ marginTopAdjust = 0, btnText = defaultProp.btnText, titleText = defaultProp.titleText, pText = defaultProp.pText, imgUrl = defaultProp.imgUrl, iconUrl = defaultProp.iconUrl, reverseTag = defaultProp.reverseTag, imgWidth = defaultProp.imgWidth, imgHeight = defaultProp.imgHeight, textBoxWidth = defaultProp.textBoxWidth, imgMarginTopAdjust = 0, imgLayWidth = 0, defaultprop = defaultProp }) {





    const imgOrderIndex = reverseTag ? "order-1" : "order-3";
    const iwidth = imgLayWidth + imgWidth
    const textBoxWidthAdjust = textBoxWidth + 50
    const MAIN_WIDTH = 1400;

    const marginTopCal = marginTopAdjust


    return (
        <div className="all-center" style={{ maxWidth: MAIN_WIDTH, marginTop: marginTopCal, marginBottom: 200,padding:"0px" }}>
            <Row className=" gap-5 d-flex justify-content-between mobile-container" style={{width:"100%"}}>
                <Col lg="4" md="10">
                    {
                        //图文按钮部分
                    }
                    <div className="d-flex align-items-center mobile-item" style={{ marginTop: 20, marginLeft: 0, marginRight: 0, maxWidth: textBoxWidthAdjust }}>

                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex align-items-center gap-3" style={stepStyle}><Image src={iconUrl} style={{ width: 64, height: 64 }}></Image>{btnText}</div>
                            <div className="align-items-center" style={titleStyle}>{titleText}</div>
                            <div className="font4Custom" style={{ maxWidth: textBoxWidth, height: "auto" }}>{pText}</div>

                        </div>



                    </div>
                </Col>
                <Col lg="7" md="12" style={{padding:"0px!important"}}>
                    {//图片部分

                    }
                    <div className=" d-flex  flex-row justify-content-start align-items-center mobile-item" style={{   marginTop: imgMarginTopAdjust, marginLeft: 0, overflow: "hidden" }}>
                        <div className="d-flex scrollcard">
                            <Image src={defaultprop.imgUrl} style={{ width: "100%" }}></Image>
                            <Image src={defaultprop.imgUrl2} style={{ width: "100%" }}></Image>
                            <Image src={defaultprop.imgUrl3} style={{ width: "100%" }}></Image>
                            <Image src={defaultprop.imgUrl4} style={{ width: "100%" }}></Image>
                            <Image src={defaultprop.imgUrl} style={{ width: "100%" }}></Image>


                        </div>

                    </div>
                </Col>
            </Row>



        </div>



    )

}