import React, { useState } from 'react';
import '../styles/Payment.scss';
import { Link } from 'react-router-dom';
import { PlanDetails } from '../components/PlanDetails';
import { PaymentOptions } from '../components/PaymentOptions';
import { PaymentQuitPopup } from '../components/PaymentQuitPopup';
import { PaymentResultPopup } from '../components/PaymentResultPopup';
import { check_user_order_status, poll_transaction_status } from '../api/payment';
import { CheckOrderPopup } from '../components/CheckOrderPopup';




interface ExitModalProps {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ExitModal: React.FC<ExitModalProps> = ({ show, onClose, onConfirm }) => {
    if (!show) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Are you sure you want to exit?</h2>
                <button onClick={onConfirm}>Yes</button>
                <button onClick={onClose}>No</button>
            </div>
        </div>
    );
};


const PaymentPage: React.FC = () => {
    const [showExitModal, setShowExitModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('Prime');
    const [price, setPrice] = useState(149);
    const [orderStatus, setOrderStatus] = useState<boolean>(false);
    const [showCheckOrderStatus, setShowCheckOrderStatus] = useState<boolean>(false);
    const [paymentUrl, setPaymentUrl] = useState<string | null>(null);
    const [showPaymentResultPopup, setShowPaymentResultPopup] = useState<boolean>(false);
    const [paymentStatus, setPaymentStatus] = useState<boolean | null>(null);
    const [lastConfirmedPlan, setLastConfirmedPlan] = useState<string | null>(null); // 保存上一次确认的计划

    const handleSelectPlan = (plan: 'Pilot' | 'Normal' | 'Prime', price: number) => {
        setSelectedPlan(plan);
        setPrice(price);
    };

    const handlePayment = () => {
        console.log(`Paid ${price} CNY for ${selectedPlan}`);
    };

    const handleExit = () => {
        setShowExitModal(true);
    };

    const handleCloseModal = () => {
        setShowExitModal(false);
    };
    const handlePlanConfirm = async () => {

        if (selectedPlan === lastConfirmedPlan) {
            console.log('Plan has not changed, not calling API');
            return;
        }

        setLastConfirmedPlan(selectedPlan);

        try {
            const result = await check_user_order_status(selectedPlan);

            if (result.success) {
                setPaymentUrl(result.payment_url);
                const statusResult = await poll_transaction_status(result.internal_transaction_id);

                if (statusResult.transaction_status === 'success') {
                    console.log('Payment success');
                    setPaymentStatus(true);
                } else if (statusResult.transaction_status == 'failed' || statusResult.transaction_status == 'timeout') {
                    console.log('Payment failed');
                    setPaymentStatus(false);
                }

                setShowPaymentResultPopup(true);
            } else {
                setOrderStatus(false);
                if (selectedPlan === 'Pilot') {
                    setShowCheckOrderStatus(true);
                }
            }
        } catch (error) {
            setOrderStatus(false);
            // if (selectedPlan === 'Pilot') {
            //     setShowCheckOrderStatus(true);
            // }
        }
    };

    const handleTimeout = () => {
        setPaymentStatus(false);
        setShowPaymentResultPopup(true);
    };

    return (
        <div style={{ overflow: "hidden" }}>
            <div className="payment-page">
                <button onClick={handleExit} className="payment-page_exit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.9708 1.20887C15.5566 1.79466 15.5566 2.7444 14.9708 3.33019L4.12132 14.1797L14.9708 25.0292C15.5566 25.615 15.5566 26.5647 14.9708 27.1505C14.385 27.7363 13.4353 27.7363 12.8495 27.1505L0.93934 15.2403C0.353554 14.6546 0.353553 13.7048 0.93934 13.119L12.8495 1.20887C13.4353 0.623082 14.385 0.623082 14.9708 1.20887Z" fill="black" />
                    </svg>
                </button>
                <div className="payment-page_header">
                    <a className="navbar-brand" href="/">
                        <img src="/static/media/ApplyU-logo.a9d0fbf1e2a8d7ef40ae.png" width="140" height="32" />
                    </a>
                </div>
                <div className="payment-page_content">
                    <div className="payment-page_left">
                        <div className="payment-page_title">
                            Plan Details
                        </div>
                        <PlanDetails onSelectPlan={handleSelectPlan} handlePlanConfirm={handlePlanConfirm} />
                    </div>
                    <div className="payment-page_right">
                        <div className="payment-page_title">
                            Payment
                        </div>
                        <PaymentOptions paymentUrl={paymentUrl} onPayment={handlePayment} onTimeout={handleTimeout} />
                    </div>
                </div>
            </div>

            {showExitModal && (<PaymentQuitPopup exit={handleCloseModal} />)}
            {showCheckOrderStatus && <CheckOrderPopup close={() => setShowCheckOrderStatus(false)} />}
            {showPaymentResultPopup && (paymentStatus != null) && <PaymentResultPopup paymentStatus={paymentStatus} />}
            {/* <PaymentResultPopup paymentStatus={true} /> */}
        </div>
    );
};

export default PaymentPage;
