import { Accordion, Image } from "react-bootstrap"
import "./styles/layout.css"
import {Link} from "react-router-dom"

const itemStyle = {






}
const headerStyle = {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "27px",
    backgroundColor: "rgba(244, 247, 248, 1)"

}

export default function FAQAccordion() {

    const maxWidth = 980
    return (
        <div className="all-center row gap-5" style={{ maxWidth: maxWidth, marginTop: 100, marginBottom: 200 }}>
            <div className="font1Custom" style={{ textAlign: "center" }}>Frequently Asked Questions</div>

            <Accordion style={{ zIndex: 100 }}>
                <Accordion.Item eventKey="0" style={itemStyle}>
                    <Accordion.Header style={headerStyle}>Does this product support the Chinese version? （该产品是否支持中文浏览？）</Accordion.Header>
                    <Accordion.Body>
                    Yes, our product supports multiple language options, including Chinese. You can easily switch to and use the Chinese version of the product by visiting this link. 是的，我们的产品支持包括中文在内的多种语言选项。您可以通过访问此链接的步骤来方便地切换并使用产品的中文版。
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" style={itemStyle}>
                    <Accordion.Header style={headerStyle}>How can I choose the right graduate program for myself?</Accordion.Header>
                    <Accordion.Body>
                        ApplyU offers an AI-driven <Link style={{fontWeight: 700, textDecoration: "underline"}} to="/program">personalized program</Link> selection and strength analysis service that helps you match your career goals and academic interests with the ideal graduate programs. We also provide a detailed report analyzing your strengths and challenges to optimize your application strategy.
                    </Accordion.Body>
                    <Accordion.Body>
                        Additionally, ApplyU offers an extensive database of international graduate <Link style={{fontWeight: 700, textDecoration: "underline"}} to="/program">programs</Link>, which includes the latest and most authoritative information on study abroad programs. You can freely browse these programs to gain comprehensive insights, giving you more options and flexibility for your study abroad choices.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" style={itemStyle}>
                    <Accordion.Header style={headerStyle}>What does ApplyU's comprehensive application guidance include?</Accordion.Header>
                    <Accordion.Body>
                        Our comprehensive application guidance covers every step from strategy development to final submission. This includes, but is not limited to, writing documents, preparing application materials, interview preparation, and final application review. We ensure meticulous attention to detail in every phase of your application.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" style={itemStyle}>
                    <Accordion.Header style={headerStyle}>Can using ApplyU's services increase my chances of admission?</Accordion.Header>
                    <Accordion.Body>
                        ApplyU's services are designed to significantly enhance the quality and competitiveness of your applications through precise analysis and expert guidance. Our aim is to help you maximize your potential, thereby increasing your chances of being accepted by your target schools.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" style={itemStyle}>
                    <Accordion.Header style={headerStyle}>Can I access one-on-one expert consulting services?</Accordion.Header>
                    <Accordion.Body>
                        Yes, we offer one-on-one expert consulting services. You can interact directly with our expert team by simply clicking on the "contact us" button on the right corner of this page, who will arrange for you a matching tutor to support your application.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" style={itemStyle}>
                    <Accordion.Header style={headerStyle}>What does the Free subscription include?</Accordion.Header>
                    <Accordion.Body>
                        Our Free subscription provides access to an extensive database of over 10,000 master's programs across more than 20 majors. <br/>Additionally, you'll have 24/7 access to personal tutor assistance at no cost.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" style={itemStyle}>
                    <Accordion.Header style={headerStyle}>What do I get with the Pilot/Normal/Primer Plans?</Accordion.Header>
                    <Accordion.Body>
                        <div style={{marginBottom:13}}><span className="font8Custom">Pilot Plan (¥19.9) : </span>This special introductory plan allows first-time users to experience our services with one personalized program guide report, including 10 personalized program recommendations.</div>
                        <div style={{marginBottom:13}}><span className="font8Custom">Normal Plan (¥39) : </span>This plan includes one report with 10 personalized program recommendations tailored to your profile and preferences, providing detailed insights to enhance your decision-making during the application process.</div>
                        <div style={{marginBottom:13}}><span className="font8Custom">Primer Plan (¥149) : </span>This plan offers five separate reports, each containing 10 customized recommendations. It allows for adjustments in personalization criteria such as targeted major, career plan, and experiences, facilitating a comprehensive exploration of suitable programs and maximizing your opportunities for success.</div>

                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>
            <div style={{ position: "relative", zIndex: 2 }}>
                <div style={{ position: "absolute", top: -400, left: -80 }}>
                    <Image src={require("./img/ApplyU_ceshi_Deco_small.png")} style={{ width: 450, height: 340 }} ></Image>
                </div>


            </div>
        </div>
    )
}