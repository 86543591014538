import { useState } from "react"
import { Container, Stack, Image, Button, Form, CloseButton } from "react-bootstrap"



const FloatBoxStyle = {
    width: 460,
    height: 370,

    backgroundColor: "white",
    position: "relative",
    right: 400,
    bottom: 350,
    borderRadius: 20,
    boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",

}

//开启展示内容：-1:关闭，0：初始导航，1：内容一，2：内容二

function FloatIcon() {
    const [UIstate, setUIstate] = useState(-1)
    

    function clickHandle(id) {
        setUIstate(id)


    }

    function changeUI(state) {
        var UIcomponent = null

        switch (state) {
            case -1: {
                UIcomponent = null
                break
            }
            case 0: {
                UIcomponent = <FloatFormContent ></FloatFormContent>
                break
            }
            case 1: {
                UIcomponent = null
                break
            }
            case 2: {
                UIcomponent = <FloatFormContent ></FloatFormContent>
                break
            }
        }
        return UIcomponent
    }
    function FloatBox() {
        return (
            <div className="all-center" style={FloatBoxStyle}>
                <div style={{ position: "absolute", top: 5, right: 8 }}>
                    <Button onClick={() => clickHandle(-1)} className="transparentButton" ><i className="bi bi-x-lg" style={{ border: 2, color: "rgba(204, 204, 204, 1)" }}></i></Button>
                </div>
                <div className="all-center" style={{ width: "90%", height: "80%" }}>
                    {changeUI(UIstate)}
                </div>

            </div>
        )
    }
    function FloatNavContent() {
        const btnStyle = {
            width: 215,
            height: 64,
            backgroundColor: "rgba(250, 250, 250, 1)",
            borderRadius: 20,
            borderColor: " rgba(234, 234, 234, 1)",
            color: "rgba(0, 0, 0, 1)",
            fontSize: 16,
            fontWeight: 400

        }

        



        return (
            <div style={{ width: "100%", height: "100%", marginLeft: 20 }}>
                <div className="font9Custom" id="title" style={{ marginBottom: 30 }}>
                    Welcome! How can I assist you today?
                </div>
                <div className="row gap-3" id="buttonList" style={{ marginLeft: 10 }}>
                    {/* <Button onClick={() => clickHandle(-1)} style={btnStyle}>Check with our tutors</Button> */}
                    <Button onClick={() => clickHandle(2)} style={btnStyle}>Send our request</Button>
                </div>
                <div id="footerBtn" style={{ position: "absolute", bottom: 0, right: 20, width: 80, height: 60 }}>
                    <Button onClick={() => clickHandle(2)} className="transparentButton" ><span style={{ fontSize: 16, fontWeight: 700, color: "rgba(40, 126, 255, 1)" }}>Go to</span></Button>
                </div>

            </div>
        )
    }
    function FloatFormContent() {
        const [FirstName, setFirstName] = useState("")
        const [LastName, setLastName] = useState("")
        const [Email, setEmail] = useState("")
        const [Phone, setPhone] = useState("")
        const [message,setMessage] = useState("")

        const inputBoxStyle = {
            width: 160,
            height: 33,
            borderColor: "rgba(170, 170, 170, 1)",
            borderRadius: 8

        }
        const labelStyle = {
            marginBottom: -5
        }

        const API_URL = process.env.REACT_APP_API_URL

        function handleSubmit() {
            try {
                fetch(`${API_URL}/api/send-info-floating/`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        "first_name": FirstName,
                        "last_name": LastName,
                        "email": Email,
                        "phone": Phone,
                        "message": message
                    })
                })
                    .then(
                    (re) => {
                        if(re.ok){
                            alert("Request Send Successfully!")
                            setUIstate(-1)
                        }
                    }
                )
            } catch (error) {
                alert('Request send failed! Please contact support for help')
                console.log(error)
            }
        }
        return (
            <div className="d-flex row justify-content-center" style={{ width: "100%", height: "100%" }}>
                <div className="font9Custom" id="title" style={{ marginBottom: 40, textAlign: "left" }}>
                    Please fill out the form below to send your request:
                </div>
                <div style={{ width: "95%", marginTop: -20 }}>
                    <Form className="d-flex row" style={{ gap: 10 }} onSubmit={handleSubmit}>
                        <div className='d-flex col justify-content-between'>
                            <Form.Group controlId="FirstName" >
                                <Form.Label className="font7Custom" style={labelStyle} >First Name *</Form.Label>
                                <Form.Control type="text" style={inputBoxStyle} onChange={(e) => setFirstName(e.target.value)} required/>
                            </Form.Group>
                            <Form.Group controlId="LastName">
                                <Form.Label className="font7Custom" style={labelStyle} >Last Name *</Form.Label>
                                <Form.Control type="text" style={inputBoxStyle} onChange={(e) => setLastName(e.target.value)} required/>
                            </Form.Group>
                        </div>

                        <div className='d-flex col justify-content-between'>
                            <Form.Group controlId="Email">
                                <Form.Label className="font7Custom" style={labelStyle} >Email *</Form.Label>
                                <Form.Control type="text" style={inputBoxStyle} onChange={(e) => setEmail(e.target.value)} required/>
                            </Form.Group>
                            <Form.Group controlId="Phone">
                                <Form.Label className="font7Custom" style={labelStyle}>Phone</Form.Label>
                                <Form.Control type="text" style={inputBoxStyle} onChange={(e) => setPhone(e.target.value)} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group controlId="Message">
                                <Form.Label className="font7Custom" style={labelStyle}>Message *</Form.Label>
                                <Form.Control type="text" style={{ ...inputBoxStyle, width: "100%", height: 75 }} onChange={(e) => setMessage(e.target.value)} required />
                            </Form.Group>
                        </div>
                        <div className="font10Custom">Fields marketed with * are required</div>


                    </Form>
                </div>
                <div>
                    <div id="footerBtn" style={{ position: "absolute", bottom: 0, right: 20, width: 80, height: 60 }}>

                        <Button className="transparentButton" ><span style={{ fontSize: 16, fontWeight: 700, color: "rgba(40, 126, 255, 1)" }} onClick={()=>handleSubmit()}>Send</span></Button>
                    </div>
                </div>

            </div>

        )
    }


    return (
        <div style={{ width: 64, height: 64, position: "fixed", bottom: 60, right: 40, zIndex: 100, }}>
            <div className="all-center"><Image onClick={() => clickHandle(0)} src={require("../../src/componentsNew/icon/Talk - with background.png")} width={44} height={44} style={{}}></Image>
                <div style={{ position: "absolute", height: 39, width: 39, boxShadow: "0px 0px 8px 1px rgba(0, 0, 0, 0.2)", borderRadius: 20, zIndex: -10 }}></div></div>
            {UIstate == -1 ? <></> : <FloatBox></FloatBox>}

        </div>
    )
}

export default function FloatingWindow() {

    return (

        <FloatIcon></FloatIcon>


    )
}