import { useState } from "react";





const TextToHtml = text => ({ __html: text })
//默认文本
const defaultText={
dateText:"Updated: May 19, 2024",
titleText:"Privacy Policy",
pText:"<p>At Apply U, we are committed to protecting your privacy. This privacy policy (“Privacy Policy”) outlines how we (“Apply U”) collect, use, and disclose your persona information when you (“you”) use our website and services.</p><p>By using our product, you consent to the collection and use of your information in accordance with this policy. We strive to be transparent about our data practices and ensure that you have control over your personal information. </p>"

}




export default function StatementParagragh({DateText=defaultText.dateText,TitleText=defaultText.titleText,pText=defaultText.pText}) {
    const maxWidth = window.MAIN_WIDTH
    

    //替换("")内字符为加粗
    function ReplaceText(oldText) {
        //文本输入
        let str = oldText;
        //匹配查找
        let pattern1 = /\(\“(.+?)\”\)/g;

        //待替换定义
        let toReplace1 = "<span style=font-weight:700>";
        let toReplace2 = "</span>";

        //执行替换
        var newText = str.replace(pattern1, function (x) {
            return toReplace1 + x + toReplace2
        })
        //返回
       
        return newText
    }
    
    const TextToHtml = text =>({__html:text})

   
    return (
        <div className="d-flex flex-column " style={{ maxWidth: maxWidth, gap: 60,marginBottom:100 }}>
            {
                //标题部分
            }
            <div className="row" style={{ gap: 10 }}>
                <div className="font5Custom" id="date">{DateText}</div>
                <div className="font1Custom" id="title">{TitleText}</div>
            </div>


            {
                //段落部分
            }
            <div className="row fontBlackBold" style={{ gap: 25,maxWidth:1000 }}>
                <div className="font4Custom" id="p" >
                    <div dangerouslySetInnerHTML={TextToHtml(ReplaceText(pText))}></div>
                </div>
            </div>
        </div>
    )
}