import { createBrowserRouter, redirect, useNavigate, useLocation } from "react-router-dom";
import HomePage from './componentsNew/_HomePage';
import Login from "./pages/Login";
import Signup from "./pages/SignUp";
import Reset from './pages/Reset';
import Tutors from "./pages/viewTutors";
import ViewPrograms from "./pages/ViewPrograms";
import PricingPage from "./componentsNew/_PricingPage";
import PaymentPage from "./pages/Payment";
import PrivacyPolicy from "./componentsNew/_PrivacyPolicy";
import TermsOfService from "./componentsNew/_TermsOfService";
import Write from "./pages/Write";
import Program from "./pages/Program";
import DashboardLayout from "./layouts/DashboardLayout";
import { NetworkApi, checkLogin } from "./componentsNew/Link/Network";
import { useEffect, useState } from "react";
import { GetToServer } from "./componentsNew/Link/Network";
import Loading from "./componentsNew/Loading";
import FormTest from "./componentsNew/FormTest";
import CVForm from "./componentsNew/_FormPage";
import WaitListPage from "./componentsNew/elsePage/_WaitListPage";

const RedictToLogUrl = "/login"
export const _router = createBrowserRouter(
    [
        // {
        //     path: "/test",
        //     element: <FormTest />
        // }, 
        { path: "/programtest", element: <DashboardLayout><Program /></DashboardLayout> },

        {
            path: "/waitlist",
            element: <Auth><WaitListPage /></Auth>
        },
        { path: "/", element: <HomePage /> },
        { path: "/login", element: <Login /> },
        { path: "/signup", element: <Signup /> },
        // { path: "/reset", element: <Reset /> },
        { path: "/tutor", element: <Tutors /> },
        { path: "/viewprograms", element: <Auth><ViewPrograms /></Auth> },
        { path: "/pricing", element: <PricingPage /> },
        {
            path: "/payment", element: <Auth><PaymentPage /></Auth>
        },
        { path: "/privacypolicy", element: <PrivacyPolicy /> },
        { path: "/termsofservice", element: <TermsOfService /> },
        // { path: "/write", element: <Auth><DashboardLayout><Write /></DashboardLayout></Auth> },
        { path: "/program", element: <Auth><DashboardLayout><Program /></DashboardLayout></Auth> },
        { path: "/reset", element: <Reset /> },
    ]
)

function Auth({ children }) {

    const [flag, setFlag] = useState(null)
    const navigate = useNavigate()
    const location = useLocation();


    useEffect(() => {
        const checkAPI = NetworkApi.checkLoginStatus
        var isLogIn = false

        GetToServer(checkAPI, (re) => {
            console.log(re)

            isLogIn = (re.code == 200)
            setFlag(isLogIn)

        })

    }, [])
    if (flag == null)
        //loading

        return (
            <div className="all-center flex-column" style={{ height: "800px" }}>
                <div>Check Login</div>
                <div><Loading></Loading></div>
            </div>
        )
    else if (flag)
        //islogin
        return (
            <>
                {children}
            </>

        )
    else //not login
    {
        console.log("notlogin")
        navigate(RedictToLogUrl, { state: { from: location.pathname } });
        return;
    }

}