import { Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function Loading() {

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "100%" }}>
           
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
        </div>
    )
}