import { AES, enc, mode, pad, PBKDF2, algo } from 'crypto-js';

export default function decryptEmail(encryptedEmailBase64, encryptedKeyBase64, password) {
    try {
        //console.log('Starting decryption process...');

        // Step 1: Parse and decode the encrypted key components
        const [key_iv_b64, key_ct_b64, key_salt_b64] = encryptedKeyBase64.split(':');
        if (!key_iv_b64 || !key_ct_b64 || !key_salt_b64) {
            throw new Error('Invalid encrypted key format');
        }

        const key_iv = enc.Base64.parse(key_iv_b64);
        const key_ct = enc.Base64.parse(key_ct_b64);
        const key_salt = enc.Base64.parse(key_salt_b64);

        // console.log('Key IV (Base64):', key_iv.toString(enc.Base64));
        // console.log('Key CT (Base64):', key_ct.toString(enc.Base64));
        // console.log('Key Salt (Base64):', key_salt.toString(enc.Base64));

        // Step 2: Derive the key using PBKDF2
        const derivedKey = PBKDF2(password, key_salt, { keySize: 256 / 32, iterations: 1000, hasher: algo.SHA1 });
        //console.log('Derived Key (Base64):', derivedKey.toString(enc.Base64));

        // Step 3: Decrypt the encrypted key to get the original encryption key
        const decryptedKeyBytes = AES.decrypt(
            { ciphertext: key_ct },
            derivedKey,
            {
                iv: key_iv,
                mode: mode.CBC,
                padding: pad.Pkcs7
            }
        );

        //console.log('Decrypted Original Key (Hex):', decryptedKeyBytes.toString(enc.Hex));

        // Step 4: Parse the IV and ciphertext from the encrypted email
   
        const newEmail = encryptedEmailBase64.replace(/\s+/g,"+")
        const email_iv_b64 = newEmail.slice(0, 24);
        const email_ct_b64 = newEmail.slice(24);
        const email_iv = enc.Base64.parse(email_iv_b64);
        const email_ct = enc.Base64.parse(email_ct_b64);

        // console.log('Email IV (Base64):', email_iv.toString(enc.Base64));
        // console.log('Email CT (Base64):', email_ct.toString(enc.Base64));

        // Step 5: Decrypt the email ciphertext
        const email_decrypted = AES.decrypt(
            { ciphertext: email_ct },
            decryptedKeyBytes,
            { iv: email_iv, mode: mode.CBC, padding: pad.Pkcs7 }
        );

        const decryptedEmail = email_decrypted.toString(enc.Utf8);

        //console.log('Decrypted Email:', decryptedEmail);

        return decryptedEmail;
    } catch (error) {
        console.log('Decryption failed:', error.message);
        return null;
    }
}
