import StatementParagragh from "./StatementParagragh";
import FootNav from "./FootNav";
import HeadNav from "./HeadNav";
import PageTitle from "./PageTitle";
import ButtonList from "./ButtonList";
import TextList from "./TextList";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function PrivacyPolicy() {
    return (
        <>
            <div className='d-flex row justify-content-center'>

                <HeadNav />

                <div style={{ maxWidth:1200 }} className="d-flex row justify-content-center" >
                    {//content
                    }
                    <PageTitle TitleText={"Privacy Policy"}></PageTitle>
                    <StatementParagragh></StatementParagragh>
                    <ButtonList></ButtonList>
                    <TextList></TextList>
                </div>


                <FootNav />
            </div>
        </>
    )
}

