import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Wishlist from '../components/Wishlist';
import Questions from '../components/Personalisation';
import Results from '../components/Results';
import TutorPopup from '../components/TutorPopup'
import '../styles/Program.scss'
import { RootState } from '../store/store';
import programLogo from '../assets/program-title-logo.png';
import btnLeft from '../assets/btns-left.png';
import btnRight from '../assets/btns-right.png';
import { getReports } from '../api/reports';
import { getWishlist } from '../api/wishlist';
import { getResults } from '../api/results';
import { getReportLimit } from '../api/reports';
import { ProgramItem, setWishlist } from '../store/wishlistSlice';
import { ProjectAnalysis } from "../store/analysisSlice";
import Reports from '../components/Reports';
import RightWishlist from '../components/RightWishlist';
import QuitPopup from '../components/QuitPopup';
import SelectedWishlist from '../components/SelectedWishlist';
import CVForm from '../componentsNew/_FormPage';
import { submitData } from '../api/submitGuide';
import SubmitPopup from '../components/SubmitPopup';
import _, { set } from 'lodash';
import { StartPopup } from '../components/StartPopup';
import { StartSubmitPopup } from '../components/StartSubmitPopup';

const Program: React.FC = () => {
    const [step, setStep] = useState(1);
    const [reports, setReports] = useState<any[]>([]);
    //const [wishlist, setWishlist] = useState<any[]>([]);
    const [results, setResults] = useState<ProjectAnalysis[]>([]);
    const [isQuitOpen, setIsQuitOpen] = useState(false);
    const [isTutorOpen, setIsTutorOpen] = useState(false);
    const [rankvalues, setRankvalues] = useState<number[]>([1, 200]);
    const [answers, setAnswers] = useState<any>({ 0: [], 1: [], 2: [], 4: [] });
    const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
    const [selectedItems, setSelectedItems] = useState<ProgramItem[]>([]);
    const [cvData, setCvData] = useState<string[]>(localStorage.getItem('cvData') ? JSON.parse(localStorage.getItem('cvData')!) : []);
    const [isCVValid, setIsCVValid] = useState(true);
    const [isWishlistValid, setIsWishlistValid] = useState(false);
    const [isIndicatorsValid, setIsIndicatorsValid] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isStartPopupOpen, setIsStartPopupOpen] = useState(false);
    const [isStartSubmitPopupOpen, setIsStartSubmitPopupOpen] = useState(false);
    const [isLocked, setIsLocked] = useState(true);
    const [reportLoading, setReportloading] = useState(false);

    const API_URL = process.env.REACT_APP_API_URL;

    // const membership = "premium";
    const [membership, setMembership] = useState("free");
    const [restReports, setRestReports] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Moving between pages
    const handleNextStep = () => {
        // if (step === 2 && !isCVValid) {
        //     alert('Please fill out the CV information.');
        //     return;
        // }
        if (step === 3 && !isWishlistValid) {
            alert('Please select at least one wishlist item.');
            return;
        }
        if (step === 4 && !isIndicatorsValid) {
            alert('Please complete all indicators.');//make sure all indicators are populated
            return;
        }
        if (step === 2) {
            handleCVFormSubmit();
            // setStep((prevStep) => prevStep + 1);
            return
        }
        else { setStep((prevStep) => prevStep + 1); return }

        // setStep((prevStep) => prevStep + 1);
    };


    const OpenStartPopup = () => {
        setIsStartPopupOpen(true);
    }

    const handlePrevStep = () => setStep((prevStep) => prevStep - 1);

    //提交form函数
    function CVFormOnSubmit(e: Event) {
        e.preventDefault();
       
        var form = document.getElementById("myCVForm") as HTMLFormElement;

        function getName(ID: string) {
            switch (ID) {
                default: return "default"
                case "0": return "education_section"
                case "1": return "module_section"
                case "2": return "work_section"
                case "3": return "project_section"
                case "4": return "skill_section"
                case "5": return "certification_section"
                case "6": return "award_section"
                case "7": return "publication_section"
                case "8": return "volunteer_section"
            }
        }

        //form数据封装
        if (form) {

            var jsonData: any = {}

            var formData = new FormData(form);

            for (var pair of formData.entries()) {
                var typeID = pair[0].charAt(0)
                var Index = pair[0].charAt(1)
                var name = pair[0].substring(2)

                var prop = "[" + getName(typeID) + "][" + Index + "][" + name + "]"

                //存储数据
                _.setWith(jsonData, prop, pair[1], Object)
                //jsonData[typeID][Index][name] = pair[1]
            }
            var data = { "cv_object": jsonData }
            var url = `${API_URL}/api/post-resume/`
            //form数据发送
            fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
                credentials: 'include'
            })
                .then((re) => {
                    if (re.ok) {
                        alert("cv post success")
                        setStep(3)
                    }
                })

            //跳转
        }
        console.log("3")


        // var url = "http://127.0.0.1:8000/api/post-resume/"
        // //form数据发送
        // fetch(url, {
        //     method: "POST",
        //     body: JSON.stringify(jsonData)
        // })
        //     .then((re) => {
        //         if (!re.ok) setStep(3)

        //     })

        // //跳转

    }
    //处理form提交
    function handleCVFormSubmit() {
        var form = document.getElementById("myCVForm") as HTMLFormElement;

        if (form) { form.requestSubmit(); }
    }

    //indicator页面操作
    const handleItemClick = (item: ProgramItem) => {
        if (selectedItems.length < 10 && !selectedItems.includes(item)) {
            setSelectedItems([...selectedItems, item]);
        }
    };
    const handleRemoveItem = (item: ProgramItem) => {
        setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    };
    const handleAnswer = (idx: number, answer: string) => {
        setAnswers((prevAnswers: any) => {
            if (idx === 2) {
                if (answers[2].length > 0 && answer !== answers[2][0]) {
                    alert("You can only choose one answer for this question.");
                    return prevAnswers;
                }
            }
            if (prevAnswers[idx].includes(answer)) {
                alert('This answer is already in the list.');
                return prevAnswers;
            }

            return { ...prevAnswers, [idx]: [...prevAnswers[idx], answer] };
        });
    };
    const handleDelete = (idx: number, answer: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setAnswers((prevAnswers: any) => {
            const updatedAnswers = prevAnswers[idx].filter((a: any) => a !== answer);
            return { ...prevAnswers, [idx]: updatedAnswers };
        });
    };

    const handleSliderChange = (value: number[]) => {
        setRankvalues(value);
    };

    //handle wishlist
    const handleAddWishlistClick = () => {
        window.open('/viewprograms', '_blank');
    };

    //fetch report details
    const handleReportClick = async (reportId: string) => {
        try {
            //const userId = 'your-user-id';
            const resultData = await getResults(reportId);
            console.log(resultData);
            const report = resultData.guide_details; //  获取报告详情
            // if (resultData.guide_is_locked === 0) {
            //     setIsLocked(false);
            // } else {
            //     setIsLocked(true);
            // }
            const formatedData: ProjectAnalysis[] = report.map((item: any, index: number) => ({
                id: index + 1,
                school: item.uni_name,
                imgUrl: item.img_url,
                programId: item.course_code,
                program: item.course_name,
                overallMatching: item["Total score"],
                matching: item.Matching,
                academic: item["Academic achievements"],
                discipline: item["Discipline Relevance"],
                skills: item["Skills"],
                project: item["Work"],
                work: item["Projects"],
                career: item["Career preferences"],
            }));
            setResults(formatedData);
            setSelectedReportId(reportId);
            setStep(5);
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    };

    const handleStartSubmit = () => {
        if (!isIndicatorsValid) {
            alert('Please complete all indicators.');
            return;
        }
        setIsStartSubmitPopupOpen(true);
    }


    const handleSubmit = async () => {
        setIsStartSubmitPopupOpen(false);
        try {
            const newLocation = answers[4].map((answer: string) => {
                if (answer === "United Kingdom") {
                    answer = "UK"
                } else if (answer === "Australia") {
                    answer = "AUS"
                } else if (answer === "New Zeland") {
                    answer = "NZ"
                }
                return answer;
            })
            const newPurpose = answers[2].map((answer: string) => {
                if (answer === "Apply for a PhD") {
                    answer = "find_a_phd"
                } else if (answer === "Find a Job") {
                    answer = "find_a_job"
                } else if (answer === "Improvement") {
                    answer = "improvement"
                }
                return answer;
            })
            const indicators = {
                major: answers[0],
                career: answers[1],
                purpose: newPurpose,
                location: newLocation,
                uni_ranking: rankvalues,
            };
            // if (!isIndicatorsValid) {
            //     alert('Please complete all indicators.');
            //     return;
            // }

            const wishlistItems = selectedItems.reduce((acc, item, index) => {
                acc[index + 1] = item.wishlistId;
                return acc;
            }, {} as Record<number, string>);

            const payload = {
                wishlist_items: wishlistItems,
                indicators: indicators,
            };

            await submitData(payload);
            // alert('Data submitted successfully!');
            // setStep(1);
            setIsSubmited(true);
            setAnswers({ 0: [], 1: [], 2: [], 4: [] });
            setSelectedItems([]);
        } catch (error) {
            console.error('Error submitting data:', error);
            alert('Error submitting data.');
        }
    };

    const handleRefreshingWishlist = async () =>{
        try {
            const result = await getWishlist();
            dispatch(setWishlist(result as any[]));
            setSelectedItems([]);
        } catch (error) {
            console.error('Error fetching wishlist', error);
        }

    }


    const openPopup = () => {
        if (!isTutorOpen && !isQuitOpen) {
            setIsQuitOpen(true);
            console.log('QuitPopup openPopup');
        }
    };

    // useEffect(() => {
    //     const userId = 'your-user-id';
    //     const fetchReports = async () => {
    //         const result = await getReports();
    //         setReports(result as any[]);
    //     };

    //     const fetchWishlist = async () => {
    //         const result = await getWishlist();
    //         setWishlist(result as any[]);
    //     };

    //     //fetchReports();
    //     fetchWishlist();
    // }, [dispatch]);

    useEffect(() => {
        // setIsCVValid(cvData.length > 0);
        setIsCVValid(true);
    }, [cvData]);

    useEffect(() => {
        // console.log(selectedItems);
        setIsWishlistValid(true);
    }, [selectedItems]);

    useEffect(() => {
        // console.log(answers);
        // console.log(rankvalues);
        const areAnswersValid = Object.values(answers).every((answerArray: any) => answerArray.length > 0);
        const areRankValuesValid = rankvalues.length === 2 && rankvalues.every(val => typeof val === 'number');
        setIsIndicatorsValid(areAnswersValid && areRankValuesValid);
    }, [answers, rankvalues]);


    useEffect(() => {
        if (isTutorOpen) {
            setIsQuitOpen(false);
        }
    }, [isTutorOpen, step]);

    useEffect(() => {
        if (isQuitOpen) {
            setIsTutorOpen(false);
        }
    }, [isQuitOpen, step]);

    //进入页面后，获取剩余的reports数量和每天的剩余的reports的数量
    useEffect(() => {
        const remainingReports = async () => {
            try {
                setReportloading(true);
                const response = await getReportLimit();
                console.log(response)
                if (response.code === 200) {
                    setRestReports(response.data.report_total_limit);
                    if (response.data.report_total_limit > 0) {
                        setMembership('premium')
                    }
                } else if (response.includes('You need to enable JavaScript to run this app')) {
                    alert('Session expired, please login again')
                    navigate('/login')
                }
            } catch (error: any) {
                if (error.response.status === 405) {
                    alert('session expired, please login again')
                    navigate('/login')
                }
                return;
            } finally {
                setReportloading(false);
            }
        }

        remainingReports();
    }, [isStartSubmitPopupOpen, isSubmited, isStartPopupOpen])

    const handleUploadCV = (data: string[]) => {
        localStorage.setItem('cvData', JSON.stringify(data));
        setCvData(data);
        setStep(2);
    };

    const handleQuit = () => {
        setStep(1);
        setIsQuitOpen(false);
    };

    return (
        <div className="flex flex-col w-full h-full">

            <div className="title flex gap-[10px] w-full h-[96px] border-b-[2px] border-[#EAEAEA] pl-[44px] pt-[34px]">
                {step === 1 && (
                    <>
                        <img src={programLogo} className="w-[34px] h-[40px]" alt="program-logo" />
                        <span className="text-[32px] font-bold">Program Guide</span>
                    </>
                )}
                {step === 5 && (
                    <>
                        <div className="pageBtns_quit" onClick={handleQuit}>
                            Back to main page
                        </div>
                    </>
                )}
                {step !== 1 && step !== 5 && (
                    <button className="pageBtns_quit" onClick={openPopup} style={{ position: "absolute", top: 37, left: 180 }}>
                        Quit
                    </button>
                )}
            </div>
            <div className="content-container w-full h-full">
                {step === 1 && (
                    <div className="page1">
                        <div className="page1_left flex flex-col">
                            <div className="title">
                                My Reports
                            </div>
                            <Reports onReportClick={handleReportClick} />
                        </div>
                        <div className="page1_right">
                            <div className="title pl-[50px] pr-[50px] flex flex-row justify-between">
                                My WishList
                                <button onClick={handleAddWishlistClick} className="page1_right_btn_addWishlist">Add New</button>
                            </div>
                            <div className="page1_right_wishlist">
                                <RightWishlist userId="your-user-id" />
                            </div>
                        </div>

                        <div className="pageBtns">
                            <div onClick={handleNextStep} className="pageBtns_start">
                                Start
                            </div>
                        </div>
                        <div className='absolute right-[290px] top-[38px] text-[16px] text-[#AAAAAA] font-bold'>{`Remaining Report: ${reportLoading ? " Loading..." : restReports}`}</div>
                    </div>
                )}
                {step === 2 && (
                    <div className="page2">
                        <CVForm onSubmit={(e: Event) => CVFormOnSubmit(e)}></CVForm>
                        <div className="pageBtns">
                            <button onClick={handlePrevStep} className="pageBtns_left" style={{ visibility: 'hidden' }}>
                                <img src={btnLeft} alt="button for previous page" />
                            </button>
                            <span>1/3</span>
                            <button onClick={handleNextStep} className="pageBtns_left">
                                <img src={btnRight} alt="button for next page" />
                            </button>
                        </div>

                    </div>
                )}
                {step === 3 && (
                    <div className="page3">
                        <div className="page3_left">
                            <div className="title">
                                My Wish List (Optional)
                            </div>
                            <SelectedWishlist selectedItems={selectedItems} handleRemoveItem={handleRemoveItem} />
                        </div>
                        <div className="page3_right">
                            <div className="page3_right_wishlist">
                                <div className="title flex flex-row justify-between">
                                    Selected Wishlist 
                                    <button onClick={handleAddWishlistClick} className="page1_right_btn_addWishlist">Add New</button>
                                    <button className='text-[#AAAAAA] text-[16px] font-700' onClick={handleRefreshingWishlist}>Refreshing</button>
                                </div>
                                <Wishlist userId="your-user-id" onItemClick={handleItemClick} selectedItems={selectedItems} />
                            </div>
                        </div>
                        <div className="pageBtns">
                            <button onClick={handlePrevStep} className="pageBtns_left">
                                <img src={btnLeft} alt="button for previous page" />
                            </button>
                            <span>2/3</span>
                            <button onClick={handleNextStep} className="pageBtns_left">
                                <img src={btnRight} alt="button for next page" />
                            </button>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div className="page4">
                        <Questions answers={answers} handleAnswer={handleAnswer} handleDelete={handleDelete} rankvalues={rankvalues} handleSliderChange={handleSliderChange} />
                        <div className="pageBtns" style={{ width: '230px' }}>
                            <button onClick={handlePrevStep} className="pageBtns_left">
                                <img src={btnLeft} alt="button for previous page" />
                            </button>
                            <span>3/3</span>
                            {restReports > 0 && <div onClick={handleStartSubmit} className="pageBtns_start">
                                Submit
                            </div>}
                            {restReports === 0 && (<div onClick={OpenStartPopup} className="pageBtns_startDisabled">Submit</div>)}
                        </div>
                    </div>
                )}
                {step === 5 && (
                    <div className="page5">
                        <div className="title">
                            My Report
                        </div>
                        <Results data={results} isLocked={isLocked} setIsLocked={setIsLocked} selectedReportId={selectedReportId} membership={membership} />
                        <div className="pageBtns">
                        </div>
                    </div>
                )}
            </div>
            <div>
                <TutorPopup isQuitOpen={isQuitOpen} setIsQuitOpen={setIsQuitOpen} isTutorOpen={isTutorOpen} setIsTutorOpen={setIsTutorOpen} />
                {step !== 1 && step !== 5 && (
                    <QuitPopup
                        quit={handleQuit}
                        isQuitOpen={isQuitOpen}
                        setIsQuitOpen={setIsQuitOpen}
                        isTutorOpen={isTutorOpen}
                    />
                )}
                {isSubmited && <SubmitPopup setStep={setStep} setIsSubmited={setIsSubmited} />}
                {isStartPopupOpen && <StartPopup startHandler={handleNextStep} restReports={restReports} setIsStartPopupOpen={setIsStartPopupOpen} />}
                {isStartSubmitPopupOpen && <StartSubmitPopup handleSubmit={handleSubmit} restReports={restReports} membership={membership} setIsStartSubmitPopupOpen={setIsStartSubmitPopupOpen} />}
            </div>
        </div>
    );
}

export default Program;
