
// src/api.ts
import axios from 'axios';
import { ProjectAnalysis } from "../store/analysisSlice";

let API_URL = process.env.REACT_APP_API_URL;

const AUTH_HEADERS = {
    //'Authorization': 'Bearer your-auth-key', // 替换为你的真实认证密钥
    'Content-Type': 'application/json',
};

const USE_MOCK = false;

export const getResults = async (reportId: string) => {
    console.log("getting results:", reportId);
    if (USE_MOCK) {
        return new Promise((resolve) => {
            setTimeout(() => {
                console.log("getting , mock data");
                resolve(
                    mockData
                );
            }, 500);
        });
    }

    try {
        const payload = {
            guide_id: reportId,
        };

        const response = await axios.post(`${API_URL}/api/fetch-guide-reports-details/`, payload,{
            headers: AUTH_HEADERS,
            withCredentials:true
        });
        if (response.status === 200) {
            const data = response.data.data;
            return data
        } else if (response.status === 401) {
            throw new Error('Invalid or expired token');
        } else if (response.status === 500) {
            throw new Error('Internal Server Error');
        } else {
            throw new Error('An unknown error occurred');
        }
    } catch (error) {
        console.error('Failed to fetch previous report:', error);
        throw error;

    }
};

export const unlockReport = async (reportId:any) => {
    try{
        const payload = {
            guide_id: reportId,
        };
        const response:any = axios.post(`${API_URL}/api/unlock-report/`, payload,{
            headers: AUTH_HEADERS,
            withCredentials:true
        })
        if(response.status === 200){
            const data = response.data;
            return data
        }
    }catch(error){
        console.error('Failed to unlock report:', error);
        throw error;
    }
};


// Mock data
export const mockData: ProjectAnalysis[] = [
    {
        id: 1,
        school: 'University College London',
        imgUrl: 'https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg',
        programId: 'Uni-23523',
        program: 'Business Analytics MSc',
        overallMatching: 82,
        matching: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
                '2': ['Research experience'],
                '3': ['Project experience'],
                '4': ['Work experience'],
                '5': ['Career prospects'],
            }, 
            score: 82
        },
        academic: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
            }, score: 78
        },
        discipline: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 76
        },
        skills: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 70
        },
        project: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 87
        },
        work: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 66
        },
        career: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 65
        },
    },
    {
        id: 2,
        school: 'University of Cambridge',
        imgUrl: 'https://www.cam.ac.uk/sites/www.cam.ac.uk/files/inner-images/logo.jpg',
        programId: 'Uni-23524',
        program: 'Computer Science MPhil',
        overallMatching: 78,
        matching: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
                '2': ['Research experience'],
                '3': ['Project experience'],
                '4': ['Work experience'],
                '5': ['Career prospects'],
            }, score: 78
        },
        academic: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
            }, score: 78
        },
        discipline: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 76
        },
        skills: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 70
        },
        project: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 87
        },
        work: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 66
        },
        career: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 65
        },
    },
    {
        id: 3,
        school: 'University College London',
        imgUrl: 'https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg',
        programId: 'Uni-23523',
        program: 'Business Analytics MSc',
        overallMatching: 82,
        matching: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
                '2': ['Research experience'],
                '3': ['Project experience'],
                '4': ['Work experience'],
                '5': ['Career prospects'],
            }, score: 82
        },
        academic: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
            }, score: 78
        },
        discipline: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 76
        },
        skills: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 70
        },
        project: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 87
        },
        work: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 66
        },
        career: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 65
        },
    },
    {
        id: 4,
        school: 'University College London',
        imgUrl: 'https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg',
        programId: 'Uni-23523',
        program: 'Business Analytics MSc',
        overallMatching: 82,
        matching: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
                '2': ['Research experience'],
                '3': ['Project experience'],
                '4': ['Work experience'],
                '5': ['Career prospects'],
            }, score: 82
        },
        academic: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
            }, score: 78
        },
        discipline: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 76
        },
        skills: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 70
        },
        project: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 87
        },
        work: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 66
        },
        career: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 65
        },
    },
    {
        id: 5,
        school: 'University College London',
        imgUrl: 'https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg',
        programId: 'Uni-23523',
        program: 'Business Analytics MSc',
        overallMatching: 82,
        matching: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
                '2': ['Research experience'],
                '3': ['Project experience'],
                '4': ['Work experience'],
                '5': ['Career prospects'],
            }, score: 82
        },
        academic: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
            }, score: 78
        },
        discipline: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 76
        },
        skills: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 70
        },
        project: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 87
        },
        work: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 66
        },
        career: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 65
        },
    },
    {
        id: 6,
        school: 'University College London',
        imgUrl: 'https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg',
        programId: 'Uni-23523',
        program: 'Business Analytics MSc',
        overallMatching: 82,
        matching: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
                '2': ['Research experience'],
                '3': ['Project experience'],
                '4': ['Work experience'],
                '5': ['Career prospects'],
            }, score: 82
        },
        academic: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
            }, score: 78
        },
        discipline: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 76
        },
        skills: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 70
        },
        project: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 87
        },
        work: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 66
        },
        career: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 65
        },
    },
    {
        id: 7,
        school: 'University College London',
        imgUrl: 'https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg',
        programId: 'Uni-23523',
        program: 'Business Analytics MSc',
        overallMatching: 82,
        matching: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
                '2': ['Research experience'],
                '3': ['Project experience'],
                '4': ['Work experience'],
                '5': ['Career prospects'],
            }, score: 82
        },
        academic: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
            }, score: 78
        },
        discipline: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 76
        },
        skills: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 70
        },
        project: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 87
        },
        work: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 66
        },
        career: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 65
        },
    },
    {
        id: 8,
        school: 'University College London',
        imgUrl: 'https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg',
        programId: 'Uni-23523',
        program: 'Business Analytics MSc',
        overallMatching: 82,
        matching: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
                '2': ['Research experience'],
                '3': ['Project experience'],
                '4': ['Work experience'],
                '5': ['Career prospects'],
            }, score: 82
        },
        academic: {
            sub_fields: {
                '0': ['Upper second-class degree'],
                '1': ['Relevant'],
            }, score: 78
        },
        discipline: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 76
        },
        skills: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 70
        },
        project: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 87
        },
        work: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 66
        },
        career: {
            sub_fields: {
                "0": ["list of content"],
                "1": ["list of content"],
            }, score: 65
        },
    },


];




