
//let API_URL = "http://127.0.0.1:8000";
let API_URL = process.env.REACT_APP_API_URL;

/**
 * 查用户是否第一次购买，且返回支付链接
 *
 * @async
 * @param {string} package_type
 * @returns {unknown}
 */
export const check_user_order_status = async (package_type: string) => {
    try {
        // Step 1: Create payment request and get payment URL and internal_transaction_id
        const paymentResponse = await fetch(`${API_URL}/api-xxpaymemt/invoke-payment/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "package_type": package_type }),
            credentials: "include"
        });
        const paymentData = await paymentResponse.json();

        if (paymentResponse.status === 200) {
            const { internal_transaction_id, payment_url } = paymentData;
            // console.log(`Payment URL: ${payment_url}`);
            return { success: true, payment_url, internal_transaction_id };
        } else if (paymentResponse.status === 403) {
            return { success: false, message: paymentData.msg };
        } else {
            throw new Error(`Error: ${paymentData.msg}`);
        }

    } catch (error) {
        console.error('Error checking user order status', error);
        throw error;
    }
}


/**
 * 轮询查订单状态
 *
 * @async
 * @param {string} internal_transaction_id
 * @returns {unknown}
 */
export const poll_transaction_status = async (internal_transaction_id: string) => {
    try {
        const queryTransactionStatus = async (transactionId: string) => {
            const response = await fetch(`${API_URL}/api-xxpaymemt/query-internal-transaction/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "internal_transaction_id": transactionId }),
                credentials: "include"
            });

            if (!response.ok) {
                throw new Error('Error: Not able to query transaction status');
            }
            return response.json();
        };

        const initialRetryInterval = 500; // Initial interval: 1 seconds
        const maxRetryInterval = 60000; // Maximum interval: 60 seconds
        const timeout = 10 * 60 * 1000; // 5 minutes
        const endTime = Date.now() + timeout;
        let currentRetryInterval = initialRetryInterval;

        while (Date.now() < endTime) {
            const statusResponse = await queryTransactionStatus(internal_transaction_id);

            const { code, payment_status } = statusResponse;
            // console.log('Response:', statusResponse);
            // console.log(`Transaction status: ${payment_status}`);

            if (statusResponse.code === 200) {
                if (payment_status === 'Success') {
                    console.log('Transaction success');
                    return { transaction_status: 'success' };
                } else if (payment_status === 'Failed') {
                    console.log('Transaction failed');
                    return { transaction_status: 'failed' };
                } else if (payment_status === 'Pending') {
                    console.log('Transaction is pending');
                    console.log('Retry after', currentRetryInterval, 'ms');
                    // console.log('Remaining time:', endTime - Date.now(), 'ms');
                    // return { transaction_status: 'failed' };
                }
            } else {
                return { transaction_status: 'failed' };
            }

            await new Promise(resolve => setTimeout(resolve, currentRetryInterval));
            currentRetryInterval = Math.min(currentRetryInterval * 2, maxRetryInterval); // 指数回退
        }

        return { transaction_status: 'timeout' };

    } catch (error) {
        console.error('Error querying transaction status', error);
        throw error;
    }
};





/**
 * Websocket 查订单状态
 *
 * @async
 * @param {string} internal_transaction_id
 * @returns {unknown}
 */
// export const poll_transaction_status = async (internal_transaction_id: string) => {
//     const websocketUrl = `${API_URL.replace('http', 'ws')}/api-xxpaymemt/query-internal-transaction/`;

//     try {
//         return new Promise((resolve, reject) => {
//             const socket = new WebSocket(websocketUrl);

//             socket.onopen = () => {
//                 console.log('WebSocket connection established');
//                 socket.send(JSON.stringify({ internal_transaction_id }));
//             };

//             socket.onmessage = (event) => {
//                 const statusResponse = JSON.parse(event.data);
//                 const { code, payment_status } = statusResponse;
//                 console.log('Response:', statusResponse);
//                 console.log(`Transaction status: ${payment_status}`);

//                 if (code === 200) {
//                     if (payment_status === 'Success') {
//                         console.log('Transaction success');
//                         socket.close();
//                         resolve({ transaction_status: 'success' });
//                     } else if (payment_status === 'Failed') {
//                         console.log('Transaction failed');
//                         socket.close();
//                         resolve({ transaction_status: 'failed' });
//                     } else if (payment_status === 'Pending') {
//                         console.log('Transaction is pending');
//                         // Keep the socket open to continue receiving updates
//                     }
//                 } else {
//                     socket.close();
//                     resolve({ transaction_status: 'failed' });
//                 }
//             };

//             socket.onerror = (error) => {
//                 console.error('WebSocket error:', error);
//                 socket.close();
//                 reject(new Error('WebSocket error'));
//             };

//             socket.onclose = (event) => {
//                 if (!event.wasClean) {
//                     console.error('WebSocket closed unexpectedly');
//                     reject(new Error('WebSocket closed unexpectedly'));
//                 } else {
//                     console.log('WebSocket connection closed cleanly');
//                 }
//             };
//         });
//     } catch (error) {
//         console.error('Error setting up WebSocket', error);
//         throw error;
//     }
// };
