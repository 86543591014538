// src/components/Wishlist.tsx
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { getWishlist } from '../api/wishlist';
import { setWishlist } from '../store/wishlistSlice';
import '../styles/Wishlist.scss';
import { ProgramItem } from '../store/wishlistSlice';

interface WishlistProps {
    userId: string;
    onItemClick: (item: ProgramItem) => void;
    selectedItems: ProgramItem[];
}
const Wishlist: React.FC<WishlistProps> = ({ userId, onItemClick, selectedItems }) => {
    const dispatch = useDispatch<AppDispatch>();
    const wishlist = useSelector((state: RootState) => state.wishlist.items);

    useEffect(() => {
        const fetchWishlist = async () => {
            try {
                const result = await getWishlist();
                dispatch(setWishlist(result as any[]));
            } catch (error) {
                console.error('Error fetching wishlist', error);
            }
        };
        fetchWishlist();
    }, [userId, dispatch]);

    return (
        <div className="wishlist" >
            <div className="wishlist_items" style={{ maxHeight: 'calc(100vh - 96px - 125px)', overflow: 'scroll' }}>
                {wishlist.length!==0 && wishlist.map((item: ProgramItem, index: number) => (
                    <div
                        key={index}
                        className={`wishlist_item ${selectedItems.includes(item) ? 'selected' : ''}`}
                        onClick={() => onItemClick(item)}
                    >
                        <div className="wishlist_item_left">
                            <div className="wishlist_item_title">
                                <img src={item.uni_logo_path} alt={item.school} />
                                <div style={{fontSize:"14px", fontWeight:"500", justifyContent:"center"}}>{item.school}</div>
                            </div>
                            <div className="wishlist_item_program">
                                {item.program}
                            </div>
                            <div className="wishlist_item_locations">
                                <span>{item.location}</span>
                                <span>{item.country}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Wishlist;