import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import '../styles/PaymentOptions.scss';
import PaymentWechat from '../assets/payment-wechat.png';

interface PaymentOptionsProps {
    paymentUrl: string | null;
    onPayment: () => void;
    onTimeout: () => void; // New prop for handling timeout
}

export const PaymentOptions: React.FC<PaymentOptionsProps> = ({ paymentUrl, onPayment, onTimeout }) => {
    const [paymentMethod, setPaymentMethod] = useState<'Credit Card' | 'WeChat Pay'>('WeChat Pay');
    const [countdown, setCountdown] = useState<number | null>(null);

    const handlePaymentMethodChange = (method: 'Credit Card' | 'WeChat Pay') => {
        setPaymentMethod(method);
    };

    useEffect(() => {
        if (paymentUrl) {
            setCountdown(5 * 60); // 5 minutes
        }
    }, [paymentUrl]);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (countdown !== null && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => (prevCountdown !== null ? prevCountdown - 1 : null));
            }, 1000);
        } else if (countdown === 0) {
            onTimeout();
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [countdown, onTimeout]);

    const formatCountdown = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className="payment-options">
            <div className="payment-options_card" onClick={() => handlePaymentMethodChange('WeChat Pay')}>
                <div className="payment-options_card_header"
                    style={{ border: paymentMethod === "WeChat Pay" ? '2px solid #287EFF' : '2px solid #AAA' }}>
                    <div className="payment-options_card_header_title">WeChat Pay</div>
                    <div className="payment-options_card_header_img">
                        {countdown !== null && <div className="payment-options_countdown">Time left: {formatCountdown(countdown)}</div>}
                        <img src={PaymentWechat} alt="WeChat Pay" />
                    </div>
                </div>
            </div>

            {paymentMethod === "WeChat Pay" && (
                <div className="payment-options_qrcode" style={{ borderRadius: "0 0 12px 12px", height: 350, padding: "80px 10px" }}>
                    {paymentUrl && <QRCode value={paymentUrl} />}
                    <div className="payment-options_qrcode_info">{paymentUrl ? "Please scan the QR code in WeChat" : " Please confirm your plan"}</div>
                </div>
            )}

            {paymentMethod === 'Credit Card' && (
                <button className="payment-options_btnPay" onClick={onPayment}>
                    Pay Now
                </button>
            )}
        </div>
    );
};
