import React, { useEffect, useState } from 'react';
import btnClose from '../assets/popup-close.png';
import { isEmail } from 'validator';
import '../styles/ContactTutorPopup.scss';
import { sendInfo } from '../api/tutor';

interface TutorPopupProps {
    isTutorOpen: boolean;
    setIsTutorOpen: (isOpen: boolean) => void;
    selectedTutor: string;
    
}

const ConatactTutorPopup: React.FC<TutorPopupProps> = ({isTutorOpen, setIsTutorOpen,selectedTutor }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [inputValue, setInputValue] = useState('');

    
    const validateEmail = (email: string): boolean => {
        if (!isEmail(email)) {
            setEmailError('Please enter a valid email address');
            return false;
        }
        setEmailError('');
        return true;
    };

    const openPopup = () => {
        setIsOpen(true);
        console.log('TutorPopup openPopup');
    };

    const closePopup = () => {
        setIsOpen(false);
        setIsTutorOpen(false);
    };

    const handleSubmit = async () => {
        if (inputValue) {
            try {
                const response = await sendInfo(selectedTutor,inputValue,'tutor');
                console.log('response');
                closePopup();
            } catch (error:any) {
                if(error.response.status === 302){
                    alert('Please login and retry!')
                }else{
                    alert('Something went wrong, please contact for help!')
                }
                console.error('Failed to send info:', error);
            }
            setInputValue('');
        }
        closePopup();
    };

    return (
        <div className="popup">
            {isTutorOpen && (
                <div className="popup_container">
                    <div className="popup_content">
                        <button className="close-button" onClick={closePopup}>
                            <img src={btnClose} alt="close popup" />
                        </button>
                        <div className="popup_content_title">Contact with Personal Tutor</div>
                        <div className="popup_content_input1">
                            <span>We will send your message to our tutor, and he/she will connect with you by email. Please leave your email address here:</span>
                        </div>
                        <div className="popup_content_input2">
                            <input value={inputValue}
                                placeholder="My email address is..."
                                onChange={(e) => setInputValue(e.target.value)} 
                                onBlur={() => validateEmail(inputValue)} />
                            {emailError && <p className="text-red-500 text-sm my-1">{emailError}</p>}
                        </div>
                        <div className="popup_content_btns">
                            <button onClick={closePopup} className="back-button">cancel</button>
                            <button onClick={handleSubmit} className="submit-button" disabled={emailError!=="" || !inputValue}>Submit</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConatactTutorPopup;
