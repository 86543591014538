// src/components/SelectedWishlist.tsx
import React from 'react';
import { ProgramItem } from '../store/wishlistSlice';
import '../styles/SelectedWishlist.scss';

interface SelectedWishlistProps {
    selectedItems: ProgramItem[];
    handleRemoveItem: (item: ProgramItem) => void;
}

const SelectedWishlist: React.FC<SelectedWishlistProps> = ({ selectedItems, handleRemoveItem }) => {
    return (
        <>
            {/* <div className="page3_left"> */}
            <div className="page3_left_count title">
                ({selectedItems.length}/10)
            </div>
            <div className="page3_left_wishlist" style={{ display: 'flex', flexDirection: 'column', gap: '16px', overflow:'scroll', maxHeight:'calc(100vh - 96px - 25px - 30px)', paddingBottom:"50px"}}>
                {selectedItems.map((item, index) => (
                    <div key={index} className="wishlist_item selected">
                        <div className="wishlist_item_left">
                            <div className="wishlist_item_title">
                                <img src={item.uni_logo_path} alt={item.school} />
                                <span>{item.school}</span>
                                <button className="remove_button" onClick={() => handleRemoveItem(item)} style={{ transform: 'translateY(-25px)' }}>×</button>
                            </div>
                            <div className="wishlist_item_program">
                                {item.program}
                            </div>
                            <div className="wishlist_item_locations">
                                <span>{item.location}</span>
                                <span>{item.country}</span>
                            </div>
                        </div>
                        
                    </div>
                ))}
            </div>
            {/*  </div> */}
        </>
    );
};

export default SelectedWishlist;
