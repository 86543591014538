import { Stack, Image, Button } from "react-bootstrap";



const card1Prop={
    imgUrl:require("./img/Website/Icons/ApplyU_ceshi_Icon_write.png"),
    titleT:"AI CV Generator",
    pText:"Tailored to highlight your unique achievements and skills, our customized resumes set the stage for your academic success. Stand out in the competitive admissions landscape with a CV that speaks volumes. "
}

const card2Prop={
    imgUrl:require("./img/Website/Icons/ApplyU_ceshi_Icon_ps.png"),
    titleT:"AI Personal Statement",
    pText:"Create a compelling personal statement using our AI-driven tool that helps you tell the best story of your unique experiences and aspirations."
}
const card3Prop={
    imgUrl:require("./img/Website/Icons/ApplyU_ceshi_Icon_letter.png"),
    titleT:"AI Recommendation Letter",
    pText:"Quickly generate customized recommendation letters with our AI tool, ensuring you have strong endorsements for program applications. "
}
const card4Prop={
    imgUrl:require("./img/Website/Icons/ApplyU_ceshi_Icon_interview.png"),
    titleT:"Mock Interview",
    pText:"Integrate 10000+ interview questions from 70+ schools, offering realistic practice sessions and personalized feedback."
}
const card5Prop={
    imgUrl:require("./img/Website/Icons/ApplyU_ceshi_Icon_email.png"),
    titleT:"Email Assistant",
    pText:"Improve your email communications with our English Email Assistant. Build your confidence to communicate in proper and formal English writing."
}








function SmallCard({imgUrl=card1Prop.imgUrl,titleT=card1Prop.titleT,pText=card1Prop.pText}) {
    return (
        <div className="all-center row gap-3" style={{width:400}}>
            <Image src={imgUrl} style={{ width: "20%", height: "20%",margin:0 }}></Image>
            <div className="font6Custom" style={{ textAlign: "center" }}>{titleT}</div>
            <div className="font4Custom" style={{ textAlign: "start", width: 300 }}>{pText}</div>

        </div>
    )
}
export default function DisplayCard() {
    const maxWidth = 1200
    return (
        <div className="all-center row gap-5" style={{ maxWidth: maxWidth,marginTop:100,marginBottom:100 }}>
            <div className="all-center font1Custom" style={{textAlign:"center"}}>What we are going to offer</div>
            <div className="all-center flex-wrap row-gap-5" style={{width:1200}}>
                <SmallCard {...card1Prop}></SmallCard>
                <SmallCard {...card2Prop}></SmallCard>
                <SmallCard {...card3Prop}></SmallCard>
                <SmallCard {...card4Prop}></SmallCard>
                <SmallCard {...card5Prop}></SmallCard>
            </div>
            <div style={{position:"relative", zIndex: 2 }}>
                <div style={{ position: "absolute",top:-700,left:700}}>
                    <Image src={require("./img/ApplyU_ceshi_Deco_small.png")} style={{width:450,height:340}} ></Image>
                </div>

                
            </div>
        </div>
    )
}