import CardBig from "./CardBig";
import CommentArea from "./CommentArea";
import DisplayCard from "./DisplayCard";
import FAQAccordion from "./FAQAccordion";
import FloatingWindow from "./FloatingWindow";
import FootNav from "./FootNav";
import HeadNav from "./HeadNav";
import ScrollCard from "./ScrollCard";
import ServiceCard from "./ServiceCard";
import TextDivider from "./TextDivider";
import 'bootstrap/dist/css/bootstrap.min.css';


//
const ServiceCard1 = {
    btnText: "STEP 1",
    titleText: "Create Personal Profile",
    pText: "Our tools help you organize and polish your experience summary to showcase your achievements in the best light. Start strong by presenting a compelling application narrative. ",
    imgUrl: require("./img/Website/Pictures/Group 2591-1.webp"),
    iconUrl: require("./icon/ApplyU_ceshi_Icon_step1.png"),
    reverseTag: false,
    imgWidth: 640,
    imgHeight: 400,
    //以下参数为修正图片大小不一致带来的版式不一致问题
    textBoxWidth: 440,
    imgMarginTopAdjust: 0,
    imgLayWidth: 0

}
const ServiceCard2 = {
    btnText: "STEP 2",
    titleText: "Choose your dream schools",
    pText: "Freely explore and select from your dream schools. Check your compatibility with our matching score feature to see how well you fit with each program’s requirements. Make informed decisions about where to apply.",
    imgUrl: require("./img/Website/Pictures/Group 2593.webp"),
    iconUrl: require("./icon/ApplyU_ceshi_Icon_step2.png"),
    reverseTag: false,
    imgWidth: 640,
    imgHeight: 400,
    //以下参数为修正图片大小不一致带来的版式不一致问题
    textBoxWidth: 440,
    imgMarginTopAdjust: 0,
    imgLayWidth: 0

}
const ServiceCard3 = {
    btnText: "STEP 3",
    titleText: "Personalize your future path",
    pText: "Tell us about your preferences—be it desired major, university ranking, region, or career goals. Our AI model takes your personal aspirations into account to deliver precise program recommendations tailored just for you.",
    imgUrl: require("./img/Website/Pictures/Group 2592-1.webp"),
    iconUrl: require("./icon/ApplyU_ceshi_Icon_step3.png"),
    reverseTag: false,
    imgWidth: 640,
    imgHeight: 400,
    //以下参数为修正图片大小不一致带来的版式不一致问题
    textBoxWidth: 440,
    imgMarginTopAdjust: 0,
    imgLayWidth: 0

}

// const ServiceCard4 = {
//     btnText: "Get Your Report",
//     titleText: "",
//     pText: "Discover the top 10 master's programs tailored just for you. Our comprehensive report not only ranks your best matches with an overall score but also delves deep into your strengths and weaknesses across six key dimensions, including academics, skills, discipline relevance, career goal, etc. Make informed decisions with insights that align perfectly with your career and educational goals.",
//     imgUrl: require("./img/ServiceCard/ApplyU_ceshi_Service_4.png"),
//     iconUrl: require("./icon/ApplyU_ceshi_Icon_step report.png"),
//     reverseTag: false,
//     imgWidth: 780,
//     imgHeight: 480,
//     marginTopAdjust: 100,
//     //以下参数为修正图片大小不一致带来的版式不一致问题
//     textBoxWidth: 360,
//     imgMarginTopAdjust: 0,
//     imgLayWidth: 0

// }

export default function HomePage() {

    return (
        <>

            <div className='d-flex flex-column justify-content-center align-items-center' style={{ backgroundColor: "rgba(250, 250, 250, 1)",width:"100%" }}>


                <HeadNav />
                <div style={{ maxWidth: 1440, overflowX: "hidden",width:"100%" }} className="d-flex row justify-content-center" >
                    {//content
                    }
                    <CardBig></CardBig>
                    <TextDivider></TextDivider>
                    <ServiceCard {...ServiceCard1}></ServiceCard>
                    <ServiceCard {...ServiceCard2}></ServiceCard>
                    <ServiceCard {...ServiceCard3}></ServiceCard>
                    <ScrollCard ></ScrollCard>
                    <CommentArea></CommentArea>
                    <FAQAccordion></FAQAccordion>
                    <DisplayCard></DisplayCard>
                </div>


                <FootNav />
                <FloatingWindow></FloatingWindow>
            </div>
        </>
    )
}